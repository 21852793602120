import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Editor } from '@monaco-editor/react';
import {
  Button,
  Drawer,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Tabs,
  message,
} from 'antd';
import { StakeholderModel } from 'digicust_types';
import { Key, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useKeyboardShortcut from 'use-keyboard-shortcut';
import DCSpinner from '../../../common/FullPageLoader';
import AddressSelect from '../../../Components/Inputs/AddressSelect';
import DefermentAccounts from '../../../Components/Inputs/DefermentAccounts';
import PermitNumbers from '../../../Components/Inputs/PermitNumbers';
import { stakeholderSelectedKey } from '../../../Components/reduxToolkit/masterStakeholderSlice';
import { RootState } from '../../../Components/reduxToolkit/store';
import { StakeholderRepository } from '../../../utils/repositories/stakeholder.repository';

const StakeholderPage = ({
  open,
  setClose,
  editAll = false,
  filters = [],
  selectedRows = [],
  totalCount,
  loadMainData = () => null,
}: {
  editAll?: boolean;
  filters?: any[];
  selectedRows?: Key[];
  open: boolean;
  setClose: () => void;
  totalCount?: number;
  loadMainData?: () => void;
}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<StakeholderModel>({} as StakeholderModel);
  const { customerId, projectId } = useParams();

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const stakeholderData = useSelector((state: RootState) => state.stakeholder);

  const handleStakeholderDelete = async (id?: string) => {
    if (!id) return;
    Modal.confirm({
      title: t('Do you want to delete this stakeholder?'),
      icon: <ExclamationCircleOutlined />,
      content: t('This step is irreversible.'),
      onOk: async () => {
        await StakeholderRepository.delete(customerId, projectId, {
          idArray: [id],
        });
        loadMainData();
        goToNext();
        setLoading(false);
      },
      onCancel: () => null,
    });
  };

  const loadData = async () => {
    setLoading(true);

    const reState = stakeholderData.data?.find(
      (data) => data.id === selectedRows[0],
    );
    if (selectedRows?.length === 1 && reState) {
      setData(reState);
    } else if (selectedRows?.length === 1 && selectedRows[0] !== 'new') {
      const response = await StakeholderRepository.get(
        customerId,
        projectId,
        selectedRows[0] as string,
      );
      setData(response as StakeholderModel);
    } else {
      setData({});
    }
    setLoading(false);
  };

  useMemo(() => {
    loadData();
  }, [selectedRows]);

  const types = [
    { label: 'Shipper', value: 'shipper' },
    { label: 'Recipient', value: 'recipient' },
    { label: 'Consignee', value: 'consignee' },
    { label: 'Importer', value: 'importer' },
    { label: 'Declarant', value: 'declarant' },
    { label: 'Broker', value: 'broker' },
    { label: 'Buyer', value: 'buyer' },
    { label: 'Carrier', value: 'carrier' },
    { label: 'Warehouse', value: 'warehouse' },
    { label: 'Contractual Partner', value: 'contractualPartner' },
  ];

  const goToNext = () => {
    const index = stakeholderData.data.findIndex(
      (data) => data.id === selectedRows[0],
    );
    dispatch(stakeholderSelectedKey(stakeholderData?.data[index + 1]?.id));
  };

  const hendleSaveNext = async () => {
    if (!data.name?.value) {
      message.error('Stakeholder name is required');
      return;
    }
    setLoading(true);

    const stakeholderdata = {
      defermentAccounts: data?.defermentAccounts,
      customsOffices: data?.customsOffices,
      ...data,
    };
    const res = await StakeholderRepository.save(
      customerId,
      projectId,
      stakeholderdata,
    );

    if (!res) {
      message.error('Error updating stakeholder data');
    } else {
      setData(res);
      loadMainData();
      goToNext();
    }
    setLoading(false);
  };

  const hendleSaveOnly = async () => {
    if (!data.name?.value) {
      message.error('Stakeholder name is required');
      return;
    }
    setLoading(true);

    if (selectedRows?.length > 1) {
      const replaceData: any = {
        ...(data?.address
          ? {
              address: {
                formattedAddress: data?.address?.formattedAddress,
                streetNumber: data?.address?.streetNumber,
                streetName: data?.address?.streetName,
                postalCode: data?.address?.postalCode,
                locality: data?.address?.locality,
                province: data?.address?.province,
                provinceCode: data?.address?.provinceCode,
                country: data?.address?.country,
                DE_S0347: data?.address?.DE_S0347,
                DE_S0326: data?.address?.DE_S0326,
                additionalIdentifier: data?.address?.additionalIdentifier,
              },
            }
          : {}),
        name: data?.name?.value,
        contactPerson: data?.contactPerson?.value,
        phoneNr: data?.phoneNr?.value,
        email: data?.email?.value,
        EORI: data?.EORI?.value,
        VAT: data?.VAT?.value,
        clientIdentifier: data?.clientIdentifier?.value,
        externalIdentifier: data?.externalIdentifier?.value,
        inputTaxDeduction: data?.inputTaxDeduction?.value,
        new: 'editMultiple',
        roles: data?.roles,
      };

      const multipledata: any = {
        ids: selectedRows,
        replaceData,
        editAll,
        filters,
      };

      const res = await StakeholderRepository.bulkUpdateStakeholderData(
        multipledata,
        customerId,
        projectId,
      );

      if (res) {
        message.info('Bulk edit in progress. It might take a while.');
        setData({});
      }
    } else {
      const stakeholderdata: StakeholderModel = {
        defermentAccounts: data?.defermentAccounts,
        customsOffices: data?.customsOffices,
        ...data,
      };

      const res = await StakeholderRepository.save(
        customerId,
        projectId,
        stakeholderdata,
      );

      if (!res) {
        message.error('Error updating stakeholder');
      } else {
        message.success('Stakeholder Updated');

        loadMainData();
        setData(selectedRows[0] === 'new' ? {} : res);
      }
    }

    setLoading(false);
  };

  useKeyboardShortcut(
    ['Shift', 'Enter'],
    () => {
      if (!data.name?.value) {
        return;
      }
      hendleSaveOnly();
    },
    {
      overrideSystem: false,
      ignoreInputFields: true,
      repeatOnHold: true,
    },
  );

  useKeyboardShortcut(
    ['Control', 'Enter'],
    () => {
      if (!data.name?.value) {
        return;
      }
      hendleSaveNext();
    },
    {
      overrideSystem: false,
      ignoreInputFields: true,
      repeatOnHold: true,
    },
  );

  const updateData = (value: any, key: string) => {
    const newData: any = { ...data };
    const keyObj: Record<string, any> = newData[key];

    setData({ ...data, [key]: { ...keyObj, value } });
  };

  const getTitle = () => {
    if (loading) return <DCSpinner />;

    if (selectedRows?.length > 1) return t('Bulk Edit Stakeholder Data');
    if (selectedRows[0] === 'new') return t('New Stakeholder Data');
    return data?.name?.value || t('Unnamed');
  };

  const getLabel = () => {
    if (filters.length > 0)
      return `${t('Update')} ${totalCount} ${t(
        'filtered stakeholder entries',
      )}`;
    if (editAll)
      return `${t('Update all stakeholder data')} (${totalCount} ${t(
        'entries',
      )})`;
    if (selectedRows?.length > 1)
      return `${t('Update')} ${selectedRows?.length} ${t('selected entries')}`;
    return null;
  };

  const bulkEditing = selectedRows?.length > 1 || editAll;
  // If a single data is being edited
  const singleDataEdit = !bulkEditing && selectedRows[0] !== 'new';

  return (
    <Drawer
      key="Stakeholder-Data"
      open={open}
      onClose={setClose}
      maskClosable={false}
      mask={false}
      width={760}
      destroyOnClose
      title={
        <div>
          <h4 style={{ margin: 0 }}>{getTitle()}</h4>
          {bulkEditing && (
            <p style={{ margin: 0, fontWeight: 400, fontSize: '14px' }}>
              {getLabel()}
            </p>
          )}
        </div>
      }
      styles={{ body: { padding: '0 30px' } }}
      extra={
        <Space>
          {singleDataEdit && (
            <Button onClick={async () => handleStakeholderDelete(data?.id)}>
              {t('Delete')}
            </Button>
          )}

          <Button onClick={hendleSaveOnly} type="primary" title="Shift + Enter">
            {bulkEditing
              ? t(`Bulk update`)
              : selectedRows[0] !== 'new'
                ? t('Update')
                : t('Add new')}
          </Button>

          {singleDataEdit && (
            <Button
              type="primary"
              onClick={hendleSaveNext}
              title="Control + Enter"
            >
              {t('Save & Next')}
            </Button>
          )}

          {singleDataEdit && <Button onClick={goToNext}>Next</Button>}
        </Space>
      }
    >
      <Tabs
        defaultActiveKey="1"
        items={[
          {
            label: t('General'),
            key: '1',
            children: (
              <Form layout="vertical">
                <Form.Item label={t('Company name')} style={{ flex: 1 }}>
                  <Input
                    value={data?.name?.value}
                    placeholder={t('Company name')}
                    onChange={(e) => updateData(e.target.value, 'name')}
                  />
                </Form.Item>

                <Form.Item label={t('Address')} style={{ flex: 1 }}>
                  <AddressSelect
                    expanded
                    value={data?.address}
                    onValueChange={(address) => {
                      setData({ ...data, address });
                    }}
                  />
                </Form.Item>

                <Row style={{ gap: '10px' }}>
                  <Form.Item
                    label={t('External identifier')}
                    style={{ flex: 1 }}
                  >
                    <Input
                      value={data?.externalIdentifier?.value}
                      placeholder={t('External identifier')}
                      onChange={(e) =>
                        updateData(e.target.value, 'externalIdentifier')
                      }
                    />
                  </Form.Item>

                  <Form.Item label={t('Client identifier')} style={{ flex: 1 }}>
                    <Input
                      value={data?.clientIdentifier?.value}
                      placeholder={t('Client identifier')}
                      onChange={(e) =>
                        updateData(e.target.value, 'clientIdentifier')
                      }
                    />
                  </Form.Item>
                </Row>

                <Row style={{ gap: '10px' }}>
                  <Form.Item label={t('Contact person')} style={{ flex: 1 }}>
                    <Input
                      value={data?.contactPerson?.value}
                      placeholder={t('Contact person')}
                      onChange={(e) =>
                        updateData(e.target.value, 'contactPerson')
                      }
                    />
                  </Form.Item>

                  <Form.Item label={t('Email')} style={{ flex: 1 }}>
                    <Input
                      value={data?.email?.value}
                      placeholder={t('Email')}
                      onChange={(e) => updateData(e.target.value, 'email')}
                    />
                  </Form.Item>
                </Row>

                <Row style={{ gap: '10px' }}>
                  <Form.Item label={t('Phone Number')} style={{ flex: 1 }}>
                    <Input
                      value={data?.phoneNr?.value}
                      placeholder={t('Phone Number')}
                      onChange={(e) => updateData(e.target.value, 'phoneNr')}
                    />
                  </Form.Item>

                  <Form.Item label="VAT" style={{ flex: 1 }}>
                    <Input
                      value={data?.VAT?.value}
                      placeholder="VAT"
                      onChange={(e) => updateData(e.target.value, 'VAT')}
                    />
                  </Form.Item>
                </Row>

                <Row style={{ gap: '10px' }}>
                  <Form.Item label="EORI" style={{ flex: 1 }}>
                    <Input
                      value={data?.EORI?.value}
                      placeholder="EORI"
                      onChange={(e) => updateData(e.target.value, 'EORI')}
                    />
                  </Form.Item>

                  <Form.Item label="Type" style={{ flex: 1 }}>
                    <Select
                      mode="multiple"
                      allowClear
                      style={{ width: '100%' }}
                      placeholder={t('Stakeholder Type')}
                      value={data?.roles}
                      options={types}
                      onChange={(roles) => setData({ ...data, roles })}
                    />
                  </Form.Item>
                </Row>
              </Form>
            ),
          },
          {
            label: t('Customs'),
            key: '3',
            disabled: selectedRows?.length > 1 ? true : false,
            children: (
              <Space direction="vertical" style={{ width: '100%' }}>
                <PermitNumbers
                  value={data?.permitNumbers || []}
                  onValueChange={(e) =>
                    setData({
                      ...(data || {}),
                      permitNumbers: e,
                    })
                  }
                />
                <DefermentAccounts
                  value={data?.defermentAccounts || []}
                  onValueChange={(e) => {
                    setData({
                      ...(data || {}),
                      defermentAccounts: e,
                    });
                  }}
                />
              </Space>
            ),
          },
          {
            label: 'JSON',
            key: 'json',
            children: [
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  maxHeight: 'calc(100vh - 200px)',
                  overflowY: 'scroll',
                  gap: '5px',
                  height: '100vh',
                  flexBasis: '100%',
                }}
              >
                <Editor
                  path={data?.id}
                  value={JSON.stringify(data, null, 4)}
                  onChange={(value) => {
                    try {
                      setData(JSON.parse(value || ''));
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                  language="json"
                />
              </div>,
            ],
          },
        ]}
      />
    </Drawer>
  );
};

export default StakeholderPage;
