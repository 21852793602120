import React, { useState, useCallback } from 'react';
import {
  Button,
  Col,
  Empty,
  Flex,
  Row,
  Space,
  theme,
  Typography,
  Tooltip,
  Card,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import EditableTemplateCard from './EditableTemplateCard';
import { useTranslation } from 'react-i18next';
import { UserInputTemplate } from 'digicust_types';
import { SearchInput } from '../../../../Components/Inputs/SearchInput';
import CaseDetailsSearchable from '../../../CustomsCaseDetails/case-details-searchable-mapper';

interface ExecutionStrategy {
  userInputTemplates?: UserInputTemplate[];
  [key: string]: any;
}

interface AdvancedProps {
  value: ExecutionStrategy;
  onValueChange: (value: ExecutionStrategy) => void;
  minimalMode?: boolean;
}

const generateId = (): string => {
  return `${Date.now()}-${Math.random().toString(36).substring(2, 8)}`;
};

const Advanced: React.FC<AdvancedProps> = ({
  value,
  onValueChange,
  minimalMode,
}) => {
  const { token } = theme.useToken();
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>('');
  const [selectedId, setSelectedId] = useState<string | undefined>(
    value.userInputTemplates?.[0]?.id,
  );

  const selectedTemplate = value.userInputTemplates?.find(
    (template) => template.id === selectedId,
  );

  const handleTemplateChange = useCallback(
    (updatedTemplate: UserInputTemplate) => {
      if (!value.userInputTemplates) return;

      const updatedTemplates = value.userInputTemplates.map((template) =>
        template.id === selectedTemplate?.id ? updatedTemplate : template,
      );

      onValueChange({ ...value, userInputTemplates: updatedTemplates });
    },
    [value, selectedTemplate, onValueChange],
  );

  const handleAddTemplate = useCallback(() => {
    const newTemplate: UserInputTemplate = {
      id: generateId(),
      name: t('New Template'),
      description: '',
      userInput: {},
    };

    const newTemplates = [...(value.userInputTemplates || []), newTemplate];
    onValueChange({ ...value, userInputTemplates: newTemplates });
    setSelectedId(newTemplate.id);
  }, [value, onValueChange, t]);

  const handleTemplateNameChange = useCallback(
    (index: number, newName: string) => {
      if (!value.userInputTemplates) return;

      const updatedTemplates = [...value.userInputTemplates];
      updatedTemplates[index] = { ...updatedTemplates[index], name: newName };
      onValueChange({ ...value, userInputTemplates: updatedTemplates });
    },
    [value, onValueChange],
  );

  const handleTemplateDescriptionChange = useCallback(
    (index: number, newDescription: string) => {
      if (!value.userInputTemplates) return;

      const updatedTemplates = [...value.userInputTemplates];
      updatedTemplates[index] = {
        ...updatedTemplates[index],
        description: newDescription,
      };
      onValueChange({ ...value, userInputTemplates: updatedTemplates });
    },
    [value, onValueChange],
  );

  const handleDuplicateTemplate = useCallback(
    (template: UserInputTemplate, index: number) => {
      const duplicatedTemplate: UserInputTemplate = {
        ...template,
        id: generateId(),
        name: t('Copy of {{name}}', { name: template.name }),
      };

      const newTemplates = [...(value.userInputTemplates || [])];
      newTemplates.splice(index + 1, 0, duplicatedTemplate);
      onValueChange({ ...value, userInputTemplates: newTemplates });
    },
    [value, onValueChange, t],
  );

  const handleDeleteTemplate = useCallback(
    (index: number) => {
      const newTemplates = [...(value.userInputTemplates || [])];
      newTemplates.splice(index, 1);
      onValueChange({ ...value, userInputTemplates: newTemplates });

      if (newTemplates.length > 0) {
        setSelectedId(
          newTemplates[Math.min(index, newTemplates.length - 1)].id,
        );
      } else {
        setSelectedId(undefined);
      }
    },
    [value, onValueChange],
  );

  const handleDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination || !value.userInputTemplates) return;

      const reorderedTemplates = Array.from(value.userInputTemplates);
      const [removed] = reorderedTemplates.splice(result.source.index, 1);
      reorderedTemplates.splice(result.destination.index, 0, removed);

      onValueChange({ ...value, userInputTemplates: reorderedTemplates });
    },
    [value, onValueChange],
  );

  const filteredTemplates = value.userInputTemplates?.filter(
    (template) =>
      !search ||
      template.name.toLowerCase().includes(search.toLowerCase()) ||
      template.description?.toLowerCase().includes(search.toLowerCase()),
  );

  return (
    <Space
      direction="vertical"
      style={
        minimalMode
          ? {}
          : {
              width: '100%',
              maxHeight: `calc(100vh - ${token.controlHeightLG * 2}px)`,
              overflowY: 'auto',
              padding: `0 ${token.padding}px`,
            }
      }
    >
      <Row style={{ flexWrap: 'nowrap' }}>
        <Col style={{ width: 400, padding: `0 ${token.padding}px` }}>
          <Typography.Text>{t('Templates')}</Typography.Text>

          <SearchInput
            style={{
              width: '100%',
              marginBottom: token.marginSM,
              marginTop: token.marginSM,
            }}
            onType={setSearch}
            suffix={
              <Tooltip title={t('New Template')}>
                <Button
                  size="small"
                  type="text"
                  icon={<PlusOutlined />}
                  onClick={handleAddTemplate}
                />
              </Tooltip>
            }
          />

          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="templates-list">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{
                    maxHeight: '70vh',
                    overflowY: 'auto',
                    background: snapshot.isDraggingOver
                      ? token.colorBgLayout
                      : 'transparent',
                    transition: token.motionDurationMid,
                  }}
                >
                  {filteredTemplates && filteredTemplates.length > 0 ? (
                    filteredTemplates.map((template, index) => (
                      <Draggable
                        key={template.id}
                        draggableId={template.id}
                        index={index}
                      >
                        {(provided) => (
                          <EditableTemplateCard
                            template={template}
                            isSelected={selectedId === template.id}
                            index={index}
                            provided={provided}
                            onNameChange={handleTemplateNameChange}
                            onDescriptionChange={
                              handleTemplateDescriptionChange
                            }
                            onDuplicate={handleDuplicateTemplate}
                            onDelete={handleDeleteTemplate}
                            onClick={() => setSelectedId(template.id)}
                            bgColor={token.controlItemBgActive}
                          />
                        )}
                      </Draggable>
                    ))
                  ) : (
                    <Empty description={t('No templates yet')} />
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Col>

        <Col flex={1}>
          {selectedTemplate && (
            <Card>
              <Space direction="vertical" style={{ width: '100%' }}>
                <Typography.Text
                  type="secondary"
                  style={{ marginBottom: token.marginMD }}
                >
                  {t('Template Configuration')}
                </Typography.Text>

                <CaseDetailsSearchable
                  tabConfig={{
                    caseDetails: { show: true },
                    lineItems: { show: false },
                    executionStrategy: { show: false },
                    warnings: { show: false },
                  }}
                  userInput={selectedTemplate.userInput || {}}
                  onChange={(userInput) =>
                    handleTemplateChange({
                      ...selectedTemplate,
                      userInput: {
                        ...(selectedTemplate.userInput || {}),
                        ...userInput,
                      },
                    })
                  }
                />
              </Space>
            </Card>
          )}
        </Col>
      </Row>
    </Space>
  );
};

export default Advanced;
