import { CheckCard } from '@ant-design/pro-components';
import { Flex, Image } from 'antd';
import { SubmissionVendor } from 'digicust_types';
import { getCustomsSoftwareLogoFromString } from '../../../../utils/helpers/getCustomsSoftwareLogoFromString';

export default function CustomsSystemSelection({
  value,
  onChange,
}: {
  value?: SubmissionVendor;
  onChange: (value: SubmissionVendor) => void;
}) {
  const customsSystem = [
    { value: SubmissionVendor.dakosy, label: 'Dakosy' },
    { value: SubmissionVendor.dbh, label: 'DBH' },
    { value: SubmissionVendor.aeb, label: 'AEB' },
    { value: SubmissionVendor.ldv, label: 'LDV' },
    { value: SubmissionVendor.dhf, label: 'DHF Zolaris' },
    { value: SubmissionVendor.format, label: 'FORMAT' },
    { value: SubmissionVendor.asycuda, label: 'Asycuda' },
    { value: SubmissionVendor.beo, label: 'BEO' },
    { value: SubmissionVendor.cargosoft, label: 'CargoSoft' },
    { value: SubmissionVendor.mercurio, label: 'Mercurio' },
    { value: SubmissionVendor.scope, label: 'Scope' },
  ].sort((a, b) => a.label.localeCompare(b.label));

  return (
    <CheckCard.Group
      onChange={onChange as any}
      value={value}
      size="small"
      defaultValue="A"
    >
      {customsSystem.map(({ value, label }) => (
        <CheckCard
          avatar={
            <Flex
              justify="center"
              align="center"
              style={{
                width: 45,
                height: 45,
                borderRadius: 6,
                overflow: 'clip',
                background: 'white',
              }}
            >
              <Image
                src={getCustomsSoftwareLogoFromString(value)}
                style={{
                  maxWidth: 45,
                  maxHeight: 45,
                  width: '100%',
                }}
              />
            </Flex>
          }
          title={label}
          value={value}
        />
      ))}
    </CheckCard.Group>
  );
}
