import { MemberRoles, Permissions } from 'digicust_types';

export default function usePermissions() {
  const rules = {
    [MemberRoles.orgAdmin]: {
      [Permissions.ManageUsers]: true,
      [Permissions.CreateProject]: true,
      [Permissions.EditOrgAdmin]: true,
      [Permissions.RemoveAccess]: true,
      [Permissions.EditOrgDetails]: true,
      [Permissions.EditProjectDetails]: true,
    },
    [MemberRoles.projectAdmin]: {
      [Permissions.ManageUsers]: true,
      [Permissions.EditProjectDetails]: true,
      [Permissions.RemoveAccess]: true,
    },
    [MemberRoles.user]: {},
  };

  return { rules };
}
