import {
  Button,
  Modal,
  Space,
  Tabs,
  Typography,
  message,
  Radio,
  Flex,
} from 'antd';
import {
  ExecutionStrategy,
  LineItemModel,
  Rule,
  ValidationError,
} from 'digicust_types';
import { useTranslation } from 'react-i18next';
import CustomValidationMessagesComponent from '../../Components/Inputs/CustomValidationMessagesComponent ';
import CustomsOffices from './general-tab/CustomsOffices';
import GeneralDetailsInput from './general-tab/GeneralDetailsInput';
import LineItemsTable from './line-items-tab';
import ContainersInput from './shipment-tab/ContainersInput';
import FreightDocumentInput from './shipment-tab/FreightDocumentInput';
import MeansOfTransportation from './shipment-tab/MeansOfTransportationInput';
import PackagesInput from './shipment-tab/PackagesInput';
import StakeholderDocumentInput from './stakeholder-tab/StakeholderDocumentInput';

import ProcessingTab from '../Settings/ExecutionStrategies/Processing';
import SubmissionsTab from '../Settings/ExecutionStrategies/Submissions';
import Events from '../Settings/ExecutionStrategies/Events';
import styles from './EditInformationModal.module.css';

/**
 * @deprecated use field mapper
 */
const EditInformationModal = ({
  onChange,
  value,
  openStatus,
  onCancel,
}: {
  value: Rule | null;
  onChange: (e: Rule) => void;
  openStatus: boolean;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();

  const handleSubTabsValueChange = (executionStrategy: ExecutionStrategy) => {
    onChange({
      ...(value || {}),
      userInput: {
        ...(value?.userInput || {}),
        annotatedExecutionStrategy: executionStrategy,
      },
    });
  };

  const annotatedExecutionStrategyValue =
    value?.userInput?.annotatedExecutionStrategy || ({} as ExecutionStrategy);

  return (
    <>
      <Modal
        centered
        open={openStatus}
        onCancel={onCancel}
        closable
        width={1000}
        styles={{ body: { height: '800px' } }}
        footer={null}
        destroyOnClose={true}
      >
        <Tabs
          rootClassName={styles.tabs}
          defaultActiveKey="1"
          items={[
            {
              label: t('General'),
              key: 'general',
              children: (
                <Space direction="vertical">
                  <GeneralDetailsInput
                    onLineItemsChange={() => {
                      console.log('Not implemented');
                    }}
                    caseData={value?.userInput?.annotatedAggregated}
                    onChange={(generalItem) => {
                      onChange({
                        ...(value || {}),
                        userInput: {
                          ...(value?.userInput || {}),
                          annotatedAggregated: {
                            ...(value?.userInput?.annotatedAggregated || {}),
                            ...(generalItem || {}),
                          },
                        },
                      });
                    }}
                  />

                  <CustomsOffices
                    value={
                      value?.userInput?.annotatedAggregated?.customsOffices ||
                      []
                    }
                    onValueChange={(offices) => {
                      onChange({
                        ...(value || {}),
                        userInput: {
                          ...(value?.userInput || {}),
                          annotatedAggregated: {
                            ...(value?.userInput?.annotatedAggregated || {}),
                            customsOffices: offices || [],
                          },
                        },
                      });
                    }}
                  />
                </Space>
              ),
            },
            {
              label: t('Stakeholders'),
              key: 'stakeholder',
              children: (
                <Space direction="vertical">
                  <StakeholderDocumentInput
                    value={value?.userInput?.annotatedAggregated}
                    onChange={(stakeholderItem) => {
                      onChange({
                        ...(value || {}),
                        userInput: {
                          ...(value?.userInput || {}),
                          annotatedAggregated: {
                            ...(value?.userInput?.annotatedAggregated || {}),
                            ...(stakeholderItem || {}),
                          },
                        },
                      });
                    }}
                  />
                </Space>
              ),
            },
            {
              label: t('Shipment'),
              key: 'shipment',
              children: (
                <Space direction="vertical">
                  <FreightDocumentInput
                    caseData={value?.userInput?.annotatedAggregated || {}}
                    updateItem={(freightItem) => {
                      onChange({
                        ...(value || {}),
                        userInput: {
                          ...(value?.userInput || {}),
                          annotatedAggregated: {
                            ...(value?.userInput?.annotatedAggregated || {}),
                            ...(freightItem || {}),
                          },
                        },
                      });
                    }}
                  />
                  <PackagesInput
                    value={
                      value?.userInput?.annotatedAggregated?.packages || []
                    }
                    onValueChange={(packages) => {
                      onChange({
                        ...(value || {}),
                        userInput: {
                          ...(value?.userInput || {}),
                          annotatedAggregated: {
                            ...(value?.userInput?.annotatedAggregated || {}),
                            packages: packages || [],
                          },
                        },
                      });
                    }}
                  />
                  <MeansOfTransportation
                    value={
                      value?.userInput?.annotatedAggregated
                        ?.meansOfTransportation || []
                    }
                    onValueChange={(transportationItem) => {
                      onChange({
                        ...(value || {}),
                        userInput: {
                          ...(value?.userInput || {}),
                          annotatedAggregated: {
                            ...(value?.userInput?.annotatedAggregated || {}),
                            meansOfTransportation: transportationItem || [],
                          },
                        },
                      });
                    }}
                  />
                  <ContainersInput
                    value={
                      value?.userInput?.annotatedAggregated?.containers || []
                    }
                    onValueChange={(transportationItem) => {
                      onChange({
                        ...(value || {}),
                        userInput: {
                          ...(value?.userInput || {}),
                          annotatedAggregated: {
                            ...(value?.userInput?.annotatedAggregated || {}),
                            containers: transportationItem || [],
                          },
                        },
                      });
                    }}
                  />
                </Space>
              ),
            },
            {
              label: t('Line Items'),
              key: 'lineItems',
              children: (
                <Space direction="vertical">
                  <Flex vertical gap={8}>
                    <Flex gap={8} align="center">
                      <Typography.Text>{t('Apply to')}</Typography.Text>
                      <Radio.Group
                        block
                        value={Number(
                          value?.userInput?.annotateOnlyFirstLineItem || 0,
                        )}
                        onChange={(e) => {
                          onChange({
                            ...(value || {}),
                            userInput: {
                              ...(value?.userInput || {}),
                              annotateOnlyFirstLineItem: Boolean(
                                e.target.value,
                              ),
                            },
                          });
                        }}
                        defaultValue={0}
                        optionType="button"
                        buttonStyle="solid"
                      >
                        <Radio.Button
                          value={0}
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          {t('All matching line items')}
                        </Radio.Button>
                        <Radio.Button
                          value={1}
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          {t('Only first matching line item')}
                        </Radio.Button>
                      </Radio.Group>
                    </Flex>
                    <Typography.Text
                      type="secondary"
                      style={{ fontSize: 12, maxWidth: 460 }}
                    >
                      {value?.userInput?.annotateOnlyFirstLineItem
                        ? t(
                            'Apply only to first line item (replace the marked text in the screenshot with the control), you can also dynamically switch the text if that´s a better solution.',
                          )
                        : t(
                            'These changes will be applied to all matching line items.',
                          )}
                    </Typography.Text>
                  </Flex>
                  <Typography.Text>
                    {Object.keys(value?.userInput?.annotatedLineItem || {})
                      .length > 0 && (
                      <Space direction="horizontal">
                        <span>{`${
                          Object.keys(value?.userInput?.annotatedLineItem || {})
                            .length
                        } ${t('changes')}.`}</span>
                        <Button
                          onClick={() => {
                            onChange({
                              ...(value || {}),
                              userInput: {
                                ...(value?.userInput || {}),
                                annotatedLineItem: {},
                              },
                            });
                            message.info(
                              t(
                                'Cleared all changes. To render correctly, re-open the tab.',
                              ),
                            );
                          }}
                        >
                          {t('Clear')}
                        </Button>
                      </Space>
                    )}
                  </Typography.Text>
                  <LineItemsTable
                    single
                    lineItems={
                      value?.userInput?.annotatedLineItem
                        ? [value?.userInput?.annotatedLineItem]
                        : [{}]
                    }
                    setLineItems={(lineItems: LineItemModel[]) => {
                      onChange({
                        ...(value || {}),
                        userInput: {
                          ...(value?.userInput || {}),
                          annotatedLineItem: {
                            ...(value?.userInput?.annotatedLineItem || {}),
                            ...lineItems[0],
                          },
                        },
                      });
                    }}
                  />
                </Space>
              ),
            },
            {
              label: t('Execution Strategy'),
              key: 'executionStrategy',

              children: (
                <Tabs
                  tabBarStyle={{ padding: '0 16px' }}
                  items={[
                    {
                      label: t('Processing'),
                      key: 'processing',
                      children: (
                        <ProcessingTab
                          minimalMode
                          value={annotatedExecutionStrategyValue}
                          onChange={handleSubTabsValueChange}
                        />
                      ),
                    },
                    {
                      label: t('Integration'),
                      key: 'submission',
                      children: (
                        <SubmissionsTab
                          data={annotatedExecutionStrategyValue}
                          onValueChange={handleSubTabsValueChange}
                        />
                      ),
                    },
                    {
                      label: t('Events'),
                      key: 'events',
                      children: (
                        <Events
                          minimalMode
                          value={annotatedExecutionStrategyValue}
                          onValueChange={handleSubTabsValueChange}
                        />
                      ),
                    },
                  ]}
                />
              ),
            },
            {
              label: t('Custom Validation Messages'),
              key: 'customValidationMessages',
              children: (
                <Space direction="vertical">
                  <CustomValidationMessagesComponent
                    value={value?.userInput?.customValidationMessages || []}
                    onValueChange={(
                      customValidationMessages: ValidationError[],
                    ) => {
                      onChange({
                        ...(value || {}),
                        userInput: {
                          ...(value?.userInput || {}),
                          customValidationMessages: customValidationMessages,
                        },
                      });
                    }}
                  />
                </Space>
              ),
            },
          ]}
        />
      </Modal>
    </>
  );
};

export default EditInformationModal;
