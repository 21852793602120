import { Tree, Typography } from 'antd';
import { DataNode } from 'antd/es/tree';
import { useEffect, useState } from 'react';
import DCSpinner from '../../common/FullPageLoader';
import {
  TaricTariffNumberTreeNode,
  useTaricTariffNumbersTree,
} from '../../utils/useCodes';

const TariffTree = () => {
  const tariffNumberTree = useTaricTariffNumbersTree();

  const [treeData, setTreeData] = useState<DataNode[]>([]);

  useEffect(() => {
    const formatDataForTree = (
      data: TaricTariffNumberTreeNode[],
    ): DataNode[] => {
      return data.map((item) => ({
        title: (
          <Typography.Text>
            <Typography.Text style={{ fontWeight: 'bold' }}>
              {item.code}
            </Typography.Text>{' '}
            {item.description}
          </Typography.Text>
        ),
        key: item.code,
        children: item.children ? formatDataForTree(item.children) : [],
      }));
    };

    setTreeData(formatDataForTree(tariffNumberTree));
  }, [tariffNumberTree]);

  if (!treeData.length) {
    return <DCSpinner center />;
  }

  return <Tree treeData={treeData} />;
};

export default TariffTree;
