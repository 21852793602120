import React from 'react';

export default function DCSpinner({
  marginTop = 0,
  center,
  fullPage,
  small,
}: {
  marginTop?: number;
  center?: boolean;
  fullPage?: boolean;
  small?: boolean;
}) {
  const size = small ? 24 : 44;
  return (
    <div
      style={{
        marginTop,
        ...(center
          ? { width: '100%', display: 'flex', justifyContent: 'center' }
          : {}),

        ...(fullPage
          ? {
              width: '100vw',
              height: '100vh',
              display: 'flex',
              justifyContent: 'center',
              marginTop: 300,
            }
          : {}),
      }}
    >
      <div style={{ width: size, height: size }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          style={{ width: '100%', height: '100%' }}
        >
          {/* Background pulse effect */}
          <circle
            cx="50"
            cy="50"
            r="35"
            fill="none"
            stroke="#1890ff"
            strokeWidth="2"
            opacity="0.3"
          >
            <animate
              attributeName="r"
              values="35;40;35"
              dur="2s"
              repeatCount="indefinite"
            />
            <animate
              attributeName="opacity"
              values="0.3;0.1;0.3"
              dur="2s"
              repeatCount="indefinite"
            />
          </circle>

          {/* Main rotating ring */}
          <circle
            cx="50"
            cy="50"
            r="30"
            fill="none"
            stroke="#1890ff"
            strokeWidth="4"
            strokeLinecap="round"
            strokeDasharray="160 40"
          >
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 50 50"
              to="360 50 50"
              dur="1.5s"
              repeatCount="indefinite"
            />
          </circle>

          {/* Inner rotating segments */}
          <circle
            cx="50"
            cy="50"
            r="20"
            fill="none"
            stroke="#1890ff"
            strokeWidth="3"
            strokeDasharray="40 20"
          >
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="360 50 50"
              to="0 50 50"
              dur="2s"
              repeatCount="indefinite"
            />
          </circle>

          {/* Center pulsing dot */}
          <circle cx="50" cy="50" r="4" fill="#1890ff">
            <animate
              attributeName="r"
              values="4;6;4"
              dur="1.5s"
              repeatCount="indefinite"
            />
            <animate
              attributeName="fill-opacity"
              values="1;0.6;1"
              dur="1.5s"
              repeatCount="indefinite"
            />
          </circle>
        </svg>
      </div>
    </div>
  );
}
