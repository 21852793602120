import {
  Button,
  Card,
  Flex,
  Popover,
  Space,
  TreeSelect,
  Typography,
} from 'antd';
import {
  AggregatedCaseDataModel,
  CompanyModel,
  TariffSystem,
} from 'digicust_types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDataContext } from '../AppDataProvider';
import CustomsOffices from './general-tab/CustomsOffices';
import { useGeneralDetailsFields } from './general-tab/GeneralDetailsInput';
import PreliminaryDocuments from './general-tab/PreliminaryDocuments';
import ContainersInput from './shipment-tab/ContainersInput';
import { useFreightDocumentMapper } from './shipment-tab/FreightDocumentInput';
import MeansOfTransportation from './shipment-tab/MeansOfTransportationInput';
import PackagesInput from './shipment-tab/PackagesInput';
import SealsInput from './shipment-tab/SealsInput';
import CompanyDetailsInput from './stakeholder-tab/CompanyDetailsInput';
import { useStakeholderFields } from './stakeholder-tab/StakeholderDocumentInput';
import { CaseFieldMapper } from './types';
import { CloseOutlined } from '@ant-design/icons';

type TreeNode = {
  title: string;
  value: string;
  children?: TreeNode[];
} & Partial<CaseFieldMapper>;

export function useCaseFieldMapper({
  caseData,
  updateItem,
}: {
  caseData: AggregatedCaseDataModel;
  updateItem: (caseData: AggregatedCaseDataModel) => void;
}) {
  const { t } = useTranslation();
  const { projectDetails } = useAppDataContext();

  const { fields: generalFields } = useGeneralDetailsFields({
    caseData,
    updateItem,
    tariffSystem: projectDetails?.tariffNumberTreeSystem || TariffSystem.DE,
  });

  const { fields: fdFields } = useFreightDocumentMapper({
    caseData,
    updateItem,
  });

  const { companies } = useStakeholderFields();

  const caseFieldsTree: TreeNode[] = [
    {
      title: 'General',
      value: 'general',
      children: generalFields.map((cur) => ({ ...cur, value: cur.title })),
    },
    {
      title: 'Freight Documents',
      value: 'Freight Documents',
      children: fdFields.map((cur) => ({ ...cur, value: cur.title })),
    },
    {
      title: 'Stakeholder',
      value: 'Stakeholder',
      children: companies.map((com) => ({
        title: com.title,
        value: com.title,
        getValue: () => (caseData as any)?.[com.property],
        updateProperty: (company: CompanyModel) => {
          updateItem({ ...(caseData || {}), [com.property]: company });
        },
        clearProperty: () => {
          updateItem({ ...(caseData || {}), [com.property]: undefined });
        },
        renderComponent: (
          value: CompanyModel,
          updateProperty: (company: CompanyModel) => void,
        ) => (
          <CompanyDetailsInput
            value={value}
            keyValue={com.title}
            onChange={(comp) => {
              updateProperty(comp);
            }}
          />
        ),
      })),
    },
    {
      title: 'Others',
      value: 'Others',
      children: [
        {
          title: t('Custom Offices'),
          value: t('Custom Offices'),
          hideTitle: true,
          vertical: true,
          getValue: () => caseData?.customsOffices,
          updateProperty: (customsOffices) => {
            updateItem({ ...(caseData || {}), customsOffices });
          },
          clearProperty: () => {
            updateItem({ ...(caseData || {}), customsOffices: [] });
          },
          renderComponent: (value, updateProperty) => (
            <div style={{ width: '100%' }}>
              <CustomsOffices
                value={value}
                onValueChange={(offices) => {
                  updateProperty(offices);
                }}
              />
            </div>
          ),
        },
        {
          title: t('Preliminary Documents'),
          value: t('Preliminary Documents'),
          hideTitle: true,
          vertical: true,
          getValue: () => caseData?.preliminaryDocuments,
          updateProperty: (preliminaryDocuments) => {
            updateItem({ ...(caseData || {}), preliminaryDocuments });
          },
          clearProperty: () => {
            updateItem({ ...(caseData || {}), preliminaryDocuments: [] });
          },
          renderComponent: (value, updateProperty) => (
            <PreliminaryDocuments
              value={value}
              onValueChange={(documents) => {
                updateProperty(documents);
              }}
            />
          ),
        },
        {
          title: t('Means Of Transportation'),
          value: t('Means Of Transportation'),
          hideTitle: true,
          vertical: true,
          getValue: () => caseData?.meansOfTransportation,
          updateProperty: (meansOfTransportation) => {
            updateItem({ ...(caseData || {}), meansOfTransportation });
          },
          clearProperty: () => {
            updateItem({ ...(caseData || {}), meansOfTransportation: [] });
          },
          renderComponent: (value, updateProperty) => (
            <MeansOfTransportation
              value={value}
              onValueChange={(meansOfTransportation) => {
                updateProperty(meansOfTransportation);
              }}
            />
          ),
        },
        {
          title: t('Packages'),
          value: t('Packages'),
          hideTitle: true,
          vertical: true,
          getValue: () => caseData?.packages,
          updateProperty: (packages) => {
            updateItem({ ...(caseData || {}), packages });
          },
          clearProperty: () => {
            updateItem({ ...(caseData || {}), packages: [] });
          },
          renderComponent: (value, updateProperty) => (
            <PackagesInput
              value={value}
              onValueChange={(packageItem) => {
                updateProperty(packageItem);
              }}
            />
          ),
        },
        {
          title: t('Containers'),
          value: t('Containers'),
          hideTitle: true,
          vertical: true,
          getValue: () => caseData?.containers,
          updateProperty: (containers) => {
            updateItem({ ...(caseData || {}), containers });
          },
          clearProperty: () => {
            updateItem({ ...(caseData || {}), containers: [] });
          },
          renderComponent: (value, updateProperty) => (
            <ContainersInput
              value={value}
              onValueChange={(containerItem) => {
                updateProperty(containerItem);
              }}
            />
          ),
        },
        {
          title: t('Seals'),
          value: t('Seals'),
          hideTitle: true,
          vertical: true,
          getValue: () => caseData?.seals,
          updateProperty: (seals) => {
            updateItem({ ...(caseData || {}), seals });
          },
          clearProperty: () => {
            updateItem({ ...(caseData || {}), seals: [] });
          },
          renderComponent: (value, updateProperty) => (
            <SealsInput
              value={value}
              onValueChange={(seals) => {
                updateProperty(seals);
              }}
            />
          ),
        },
      ],
    },
  ];

  return {
    caseFieldsTree,
  };
}

export default function SearchableCaseFields({
  caseData,
  updateItem,
  hideSearch,
}: {
  caseData: AggregatedCaseDataModel;
  updateItem: (caseData: AggregatedCaseDataModel) => void;
  hideSearch?: boolean;
}) {
  const { t } = useTranslation();
  const { caseFieldsTree } = useCaseFieldMapper({
    caseData,
    updateItem,
  });

  const [search, setSearch] = useState<string>();

  return (
    <Space direction="vertical" style={{ width: '100%', marginBottom: 16 }}>
      <Space size={0} direction="vertical" style={{ width: '100%' }}>
        {!hideSearch && (
          <>
            <Typography.Text>{t('Search Case Details')}</Typography.Text>
            <TreeSelect
              placeholder={t('Select Case Details')}
              style={{ width: '100%' }}
              treeData={caseFieldsTree}
              showSearch
              treeDefaultExpandAll
              value={search}
              onChange={(val) => setSearch(val)}
              filterTreeNode={(inputValue, treeNode) =>
                JSON.stringify(treeNode.title)
                  ?.toLowerCase()
                  .includes(inputValue?.toLowerCase())
              }
            />
          </>
        )}

        {search && (
          <Button type="link" size="small" onClick={() => setSearch(undefined)}>
            Clear search
          </Button>
        )}
      </Space>

      <Space
        direction="vertical"
        style={{ maxHeight: '400px', width: '100%', overflow: 'auto' }}
      >
        {caseFieldsTree
          .flatMap((cur) =>
            cur.children?.flatMap((child) => child.children || child),
          )
          .filter((cur: any) =>
            search
              ? cur.title
                  ?.toLowerCase()
                  .includes((search || 'placeholder')?.toLowerCase())
              : cur.getValue() ||
                cur.title
                  ?.toLowerCase()
                  .includes((search || 'placeholder')?.toLowerCase()),
          )
          .map((field: any) => {
            return (
              <Card
                bordered={false}
                styles={{
                  body: {
                    padding: 4,
                  },
                }}
              >
                <Flex
                  vertical={field.vertical}
                  wrap
                  justify="space-between"
                  gap={5}
                >
                  {!field.hideTitle && (
                    <Space>
                      <Button
                        type="link"
                        size="small"
                        onClick={field.clearProperty}
                        icon={<CloseOutlined />}
                      />
                      <Popover placement="right" content={field.help}>
                        <Typography.Text style={{ fontWeight: 600 }}>
                          {field.title}
                        </Typography.Text>
                      </Popover>
                    </Space>
                  )}

                  <Flex
                    gap={10}
                    align="center"
                    style={{ width: '100%', marginBottom: 10 }}
                  >
                    {!field.getValue() && (
                      <Space.Compact>
                        {field.suggestions?.map((suggestion: any) => (
                          <Button
                            type="dashed"
                            size="small"
                            tabIndex={-1}
                            onClick={() => {
                              field.updateProperty(suggestion?.value);
                            }}
                          >
                            {suggestion?.title}
                          </Button>
                        ))}
                      </Space.Compact>
                    )}
                    <div style={{ flex: 1 }}>
                      {field.renderComponent(
                        field.getValue(),
                        field.updateProperty,
                      )}
                    </div>
                  </Flex>
                </Flex>
              </Card>
            );
          })}
      </Space>
    </Space>
  );
}
