import { AutoComplete, Space, Typography } from 'antd';
import type { BaseSelectRef } from 'rc-select';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useTariffNumbers } from '../../utils/useCodes';
import TariffNumberIcon from '../Display/TariffNumberIcon';
import { CTNSelectProps } from './types';
import { formatTariffNumber } from '../../utils/helpers/formatTariffNumber';

export default function TariffSelect({
  customsTariffNumber,
  onChange,
  itemId,
  onCopy = () => null,
  isTariffNumberCopied,
  dontShowStatus = false,
  style = {},
}: CTNSelectProps) {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [tariffNumber, setTariffNumber] = useState<string>();
  const selectRef = useRef<BaseSelectRef>(null);
  const tariffNumbers = useTariffNumbers();
  const selectedTariff = tariffNumbers?.find((t) => t.code === tariffNumber);

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      if (e.ctrlKey || e.metaKey) {
        onCopy('customsTariffNumber');
        selectRef.current?.blur();
      }
    },
    [onCopy],
  );

  const filteredTariffNumbers = useMemo(
    () =>
      tariffNumbers
        .filter((t) => !search || t.code.replace(/\s+/g, '').startsWith(search))
        .slice(0, 50),
    [search, tariffNumber],
  );

  useEffect(() => {
    setTariffNumber(customsTariffNumber?.value);
  }, [customsTariffNumber]);

  return (
    <AutoComplete
      variant="filled"
      ref={selectRef}
      id={`tariff-number-select-${itemId}`}
      status={
        !customsTariffNumber?.userEdited && !dontShowStatus ? 'warning' : ''
      }
      placeholder={t('Tariff Number')}
      popupMatchSelectWidth={435}
      style={{
        width: '50%',
        ...(style || {}),
        outline: isTariffNumberCopied ? '2px solid blue' : '',
        zIndex: 1,
        borderRadius: 6,
      }}
      title={(selectedTariff && selectedTariff.code) || ''}
      value={tariffNumber}
      onClick={handleClick}
      onChange={(val) => setTariffNumber(val)}
      onBlur={() => {
        const ctn = (tariffNumber || '').replace(/\s+/g, '');
        onChange?.(
          ctn
            ? {
                ...(customsTariffNumber || {}),
                hsCode: { value: ctn, input: ctn },
                value: ctn,
                input: ctn,
              }
            : null,
        );
      }}
      // virtual={false}
      onSearch={setSearch}
      options={filteredTariffNumbers.map((item, index) => ({
        key: `${item?.code}-${index}`,
        title: item.code,
        label: (
          <Space size="small" key={`${item?.code}-${index}`}>
            <TariffNumberIcon tariffNumber={item.code} />
            <Typography.Text>{formatTariffNumber(item.code)}</Typography.Text>
            <Typography.Text ellipsis type="secondary">
              {item.description}
            </Typography.Text>
          </Space>
        ),
        value: formatTariffNumber(item.code),
      }))}
      allowClear
    />
  );
}
