import {
  QuestionCircleOutlined,
  QuestionCircleTwoTone,
  SearchOutlined,
} from '@ant-design/icons';
import { Descriptions, Divider, Flex, Popover, Space, Typography } from 'antd';
import { AggregatedCaseDataModel } from 'digicust_types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import AddressSelect from '../../../Components/Inputs/AddressSelect';
import AmountOfMoneyInput from '../../../Components/Inputs/AmountOfMoneyInput';
import DebouncedInput from '../../../Components/Inputs/Common/DebouncedInput';
import CostIntersectionSelect from '../../../Components/Inputs/CostIntersectionSelect';
import CountrySelect from '../../../Components/Inputs/CountrySelect';
import IncotermSelect from '../../../Components/Inputs/IncotermSelect';
import PortSelect from '../../../Components/Inputs/PortSelect';
import { CountrySelection } from '../../../Components/Inputs/RouteSelect';
import WeightInput from '../../../Components/Inputs/WeightInput';
import { CaseError } from '../../../Components/reduxToolkit/caseSlice';
import { RootState } from '../../../Components/reduxToolkit/store';
import { CaseFieldMapper } from '../types';
const { Paragraph } = Typography;

export const useFreightDocumentMapper = ({
  caseData,
  updateItem,
  errors,
}: {
  caseData: AggregatedCaseDataModel;
  updateItem: (caseData: AggregatedCaseDataModel) => void;
  errors?: CaseError;
}) => {
  const { t } = useTranslation();

  const fields: CaseFieldMapper[] = [
    {
      title: t('Departure Address'),
      getValue: () => caseData.fromAddress,
      updateProperty: (fromAddress) => {
        updateItem({ ...caseData, fromAddress });
      },
      clearProperty: () => {
        updateItem({ ...caseData, fromAddress: undefined });
      },
      renderComponent: (value, updateProperty) => (
        <AddressSelect
          value={value}
          onValueChange={(fromAddress) => {
            updateProperty(fromAddress);
          }}
        />
      ),
    },
    {
      title: t('Route'),
      getValue: () => caseData.route,
      updateProperty: (route) => {
        updateItem({ ...caseData, route });
      },
      clearProperty: () => {
        updateItem({ ...caseData, route: undefined });
      },
      renderComponent: (value, updateProperty) => (
        <CountrySelection
          value={value?.value || []}
          onRouteSelected={(e) => {
            updateProperty({ input: JSON.stringify(e), value: e });
          }}
          fromCountry={value?.value?.[0] || ''}
          toCountry={value?.value?.[1] || ''}
        />
      ),
    },
    {
      title: t('Destination Address'),
      getValue: () => caseData.toAddress,
      updateProperty: (toAddress) => {
        updateItem({ ...caseData, toAddress });
      },
      clearProperty: () => {
        updateItem({ ...caseData, toAddress: undefined });
      },
      renderComponent: (value, updateProperty) => (
        <AddressSelect
          value={value}
          onValueChange={(toAddress) => {
            updateProperty(toAddress);
          }}
        />
      ),
    },
    {
      title: t('Point of Entry'),
      getValue: () => caseData.pointOfEntry,
      updateProperty: (pointOfEntry) => {
        updateItem({ ...caseData, pointOfEntry });
      },
      clearProperty: () => {
        updateItem({ ...caseData, pointOfEntry: undefined });
      },
      renderComponent: (value, updateProperty) => (
        <AddressSelect
          value={value}
          onValueChange={(pointOfEntry) => {
            updateProperty(pointOfEntry);
          }}
        />
      ),
    },
    {
      title: t('Point of Exit'),
      getValue: () => caseData.pointOfExit,
      updateProperty: (pointOfExit) => {
        updateItem({ ...caseData, pointOfExit });
      },
      clearProperty: () => {
        updateItem({ ...caseData, pointOfExit: undefined });
      },
      renderComponent: (value, updateProperty) => (
        <AddressSelect
          value={value}
          onValueChange={(pointOfExit) => {
            updateProperty(pointOfExit);
          }}
        />
      ),
    },
    {
      title: t('Place of Loading'),
      getValue: () => caseData.placeOfLoading,
      updateProperty: (placeOfLoading) => {
        updateItem({ ...caseData, placeOfLoading });
      },
      clearProperty: () => {
        updateItem({ ...caseData, placeOfLoading: undefined });
      },
      renderComponent: (value, updateProperty) => (
        <AddressSelect
          value={value}
          onValueChange={(placeOfLoading) => {
            updateProperty(placeOfLoading);
          }}
        />
      ),
    },
    {
      title: t('Place of Loading Contact'),
      getValue: () => caseData.placeOfLoadingContact,
      updateProperty: (placeOfLoadingContact) => {
        updateItem({ ...caseData, placeOfLoadingContact });
      },
      clearProperty: () => {
        updateItem({ ...caseData, placeOfLoadingContact: undefined });
      },
      renderComponent: (value, updateProperty) => (
        <Space direction="vertical">
          <Space.Compact>
            <DebouncedInput
              placeholder={t('First name')}
              value={
                value?.contactPerson?.firstName || value?.contactPerson?.value
              }
              onChange={(e) => {
                const newContactPerson = {
                  ...(value?.contactPerson || {}),
                  firstName: e,
                  input: `${e} ${value?.contactPerson?.lastName || ''}`,
                  value: `${e} ${value?.contactPerson?.lastName || ''}`,
                };
                updateProperty({
                  ...value,
                  contactPerson: newContactPerson,
                });
              }}
            />
            <DebouncedInput
              placeholder={t('Last name')}
              value={value?.contactPerson?.lastName}
              onChange={(e) => {
                const newContactPerson = {
                  ...(value?.contactPerson || {}),
                  lastName: e,
                  input: `${value?.contactPerson?.firstName || ''} ${e}`,
                  value: `${value?.contactPerson?.firstName || ''} ${e}`,
                };
                updateProperty({
                  ...value,
                  contactPerson: newContactPerson,
                });
              }}
            />
          </Space.Compact>
          <DebouncedInput
            placeholder={t('Email')}
            value={value?.email?.value}
            onChange={(e) => {
              updateProperty({
                ...value,
                email: { input: e, value: e },
              });
            }}
          />
          <DebouncedInput
            placeholder={t('Phone Number')}
            value={value?.phoneNr?.value}
            onChange={(e) => {
              updateProperty({
                ...value,
                phoneNr: { input: e, value: e },
              });
            }}
          />
        </Space>
      ),
    },
    {
      title: t('Incoterm'),
      getValue: () => caseData.incoterm,
      updateProperty: (incoterm) => {
        updateItem({ ...caseData, incoterm });
      },
      clearProperty: () => {
        updateItem({ ...caseData, incoterm: undefined });
      },
      renderComponent: (value, updateProperty) => (
        <IncotermSelect
          value={value}
          onChange={(incoterm) => {
            updateProperty(incoterm);
          }}
        />
      ),
    },
    {
      title: t('Incoterm Place'),
      getValue: () => caseData.placeIncoterm,
      updateProperty: (placeIncoterm) => {
        updateItem({ ...caseData, placeIncoterm });
      },
      clearProperty: () => {
        updateItem({ ...caseData, placeIncoterm: undefined });
      },
      renderComponent: (value, updateProperty) => (
        <Space.Compact style={{ width: '100%' }}>
          <span style={{ width: '50%' }}>
            <CountrySelect
              value={{ alpha2Code: value?.alpha2Code }}
              onChange={(country) => updateProperty({ ...value, ...country })}
            />
          </span>
          <span style={{ width: '50%' }}>
            <DebouncedInput
              placeholder={t('Locality')}
              aria-autocomplete="none"
              suffix={
                <a
                  tabIndex={-1}
                  target="popup"
                  rel="noreferrer"
                  href={`https://www.google.com/maps/search/?api=1&&query=${encodeURIComponent(
                    `${value?.alpha2Code || ''} ${value?.locality || ''}`,
                  )}`}
                >
                  <SearchOutlined />
                </a>
              }
              value={value?.locality}
              onChange={(locality) => updateProperty({ ...value, locality })}
            />
          </span>
        </Space.Compact>
      ),
    },
    {
      title: t('Cost Intersection'),
      getValue: () => caseData.costIntersection,
      updateProperty: (costIntersection) => {
        updateItem({ ...caseData, costIntersection });
      },
      clearProperty: () => {
        updateItem({ ...caseData, costIntersection: undefined });
      },
      renderComponent: (value, updateProperty) => (
        <CostIntersectionSelect
          procedure={caseData.procedure?.value}
          value={value}
          onChange={(costIntersection) => {
            updateProperty(costIntersection);
          }}
        />
      ),
    },
    {
      title: t('Port of Export'),
      getValue: () => caseData.portOfExport,
      updateProperty: (portOfExport) => {
        updateItem({ ...caseData, portOfExport });
      },
      clearProperty: () => {
        updateItem({ ...caseData, portOfExport: undefined });
      },
      renderComponent: (value, updateProperty) => (
        <PortSelect
          value={value}
          onChange={(portOfExport) => {
            updateProperty(portOfExport);
          }}
        />
      ),
    },
    {
      title: t('Port of Import'),
      getValue: () => caseData.portOfImport,
      updateProperty: (portOfImport) => {
        updateItem({ ...caseData, portOfImport });
      },
      clearProperty: () => {
        updateItem({ ...caseData, portOfImport: undefined });
      },
      renderComponent: (value, updateProperty) => (
        <PortSelect
          value={value}
          onChange={(portOfImport) => {
            updateProperty(portOfImport);
          }}
        />
      ),
    },
    {
      title: t('Total Freight Costs'),
      getValue: () => caseData.freightCosts,
      updateProperty: (freightCosts) => {
        updateItem({ ...caseData, freightCosts });
      },
      clearProperty: () => {
        updateItem({ ...caseData, freightCosts: undefined });
      },
      renderComponent: (value, updateProperty) => (
        <AmountOfMoneyInput
          keyValue={'freightCosts'}
          warningValue={errors?.actual}
          value={value}
          onChange={(freightCosts) => {
            updateProperty(freightCosts);
          }}
        />
      ),
    },
    {
      title: t('Foreign Freight Costs'),
      getValue: () => caseData.internationalFreightCosts,
      updateProperty: (internationalFreightCosts) => {
        updateItem({ ...caseData, internationalFreightCosts });
      },
      clearProperty: () => {
        updateItem({ ...caseData, internationalFreightCosts: undefined });
      },
      renderComponent: (value, updateProperty) => (
        <AmountOfMoneyInput
          keyValue={'internationalFreightCosts'}
          warningValue={errors?.actual}
          value={value}
          onChange={(internationalFreightCosts) => {
            updateProperty(internationalFreightCosts);
          }}
        />
      ),
    },
    {
      title: t('Domestic Freight Costs'),
      getValue: () => caseData.domesticFreightCosts,
      updateProperty: (domesticFreightCosts) => {
        updateItem({ ...caseData, domesticFreightCosts });
      },
      clearProperty: () => {
        updateItem({ ...caseData, domesticFreightCosts: undefined });
      },
      renderComponent: (value, updateProperty) => (
        <AmountOfMoneyInput
          keyValue={'domesticFreightCosts'}
          warningValue={errors?.actual}
          value={value}
          onChange={(domesticFreightCosts) => {
            updateProperty(domesticFreightCosts);
          }}
        />
      ),
      help: (
        <Paragraph style={{ maxWidth: '400px' }}>
          <Descriptions column={1} size="small" bordered={true}>
            <Descriptions.Item label={t('From')}>
              {caseData.domesticFreightCosts?.reason?.from}
            </Descriptions.Item>
            <Descriptions.Item label={t('To')}>
              {caseData.domesticFreightCosts?.reason?.to}
            </Descriptions.Item>
            <Descriptions.Item label={t('Point of Entry')}>
              {caseData.domesticFreightCosts?.reason?.pointOfEntry}
            </Descriptions.Item>
            <Descriptions.Item label={t('Total Distance')}>
              {Math.round(
                parseInt(
                  (
                    caseData.domesticFreightCosts?.reason
                      ?.distanceInKilometers || 0
                  )?.toString(),
                  10,
                ),
              )}{' '}
              km
            </Descriptions.Item>
            <Descriptions.Item label={t('Foreign Distance')}>
              {Math.round(
                parseInt(
                  (
                    caseData.domesticFreightCosts?.reason
                      ?.foreignDistanceInKilometers || 0
                  )?.toString(),
                  10,
                ),
              )}{' '}
              km
            </Descriptions.Item>
            <Descriptions.Item label={t('Domestic Distance')}>
              {Math.round(
                parseInt(
                  (
                    caseData.domesticFreightCosts?.reason
                      ?.domesticDistanceInKilometers || 0
                  )?.toString(),
                  10,
                ),
              )}{' '}
              km (
              {Math.round(
                (caseData.domesticFreightCosts?.reason
                  ?.domesticFreightCostProportion || 0) * 100,
              )}
              % )
            </Descriptions.Item>
          </Descriptions>
        </Paragraph>
      ),
    },
    {
      title: t('Foreign Insurance Costs'),
      getValue: () => caseData.insuranceCosts,
      updateProperty: (insuranceCosts) => {
        updateItem({ ...caseData, insuranceCosts });
      },
      clearProperty: () => {
        updateItem({ ...caseData, insuranceCosts: undefined });
      },
      renderComponent: (value, updateProperty) => (
        <AmountOfMoneyInput
          keyValue={'insuranceCosts'}
          warningValue={errors?.actual}
          value={value}
          onChange={(insuranceCosts) => {
            updateProperty(insuranceCosts);
          }}
        />
      ),
    },
    {
      title: t('Domestic Insurance Costs'),
      getValue: () => caseData.domesticInsuranceCosts,
      updateProperty: (domesticInsuranceCosts) => {
        updateItem({ ...caseData, domesticInsuranceCosts });
      },
      clearProperty: () => {
        updateItem({ ...caseData, domesticInsuranceCosts: undefined });
      },
      renderComponent: (value, updateProperty) => (
        <AmountOfMoneyInput
          keyValue={'domesticInsuranceCosts'}
          warningValue={errors?.actual}
          value={value}
          onChange={(domesticInsuranceCosts) => {
            updateProperty(domesticInsuranceCosts);
          }}
        />
      ),
    },
    {
      title: t('Gross Weight'),
      getValue: () => caseData.weight,
      updateProperty: (weight) => {
        updateItem({ ...caseData, weight });
      },
      clearProperty: () => {
        updateItem({ ...caseData, weight: undefined });
      },
      renderComponent: (value, updateProperty) => (
        <WeightInput
          keyValue={'weight'}
          warningValue={errors?.actual}
          weight={value}
          onChange={(weight) => {
            updateProperty(weight);
          }}
        />
      ),
    },
    {
      title: t('Net Weight'),
      getValue: () => caseData.netWeight,
      updateProperty: (netWeight) => {
        updateItem({ ...caseData, netWeight });
      },
      clearProperty: () => {
        updateItem({ ...caseData, netWeight: undefined });
      },
      renderComponent: (value, updateProperty) => (
        <WeightInput
          keyValue={'netWeight'}
          warningValue={errors?.actual}
          weight={value}
          onChange={(netWeight) => {
            updateProperty(netWeight);
          }}
        />
      ),
    },
  ];

  return { fields };
};

const FreightDocumentInput = ({
  caseData,
  updateItem,
}: {
  caseData: AggregatedCaseDataModel;
  updateItem: (caseData: AggregatedCaseDataModel) => void;
}) => {
  const { errors } = useSelector((state: RootState) => state?.caseSlices);

  const { fields } = useFreightDocumentMapper({ caseData, updateItem, errors });

  return (
    <Space direction="vertical" style={{ width: '98%' }}>
      {fields.map((field) => (
        <div style={{ width: '100%' }}>
          {field.title === 'Total Freight Costs' ? <Divider /> : null}
          {field.title === 'Incoterm' ? <Divider /> : null}
          {field.title === 'Port of Export' ? <Divider /> : null}
          {field.title === 'Gross Weight' ? <Divider /> : null}
          <Flex justify="space-between">
            <Paragraph>
              {field.title}
              <Popover
                placement="right"
                content={
                  <ul>
                    {Object.entries(field?.getValue?.() || {})
                      ?.filter(
                        ([key]) =>
                          ![
                            'errors',
                            'confidence',
                            'options',
                            'bbox',
                            'modifiedAt',
                          ].includes(key),
                      )
                      ?.map(([key, value]) => (
                        <li>
                          {key}: {value?.toString?.()}
                        </li>
                      ))}
                  </ul>
                }
              >
                <QuestionCircleOutlined style={{ margin: '4px' }} />
              </Popover>
              {field.help ? (
                <Popover
                  placement="right"
                  trigger="hover"
                  title={field.help ? field.title : null}
                  content={field.help ? field.help : null}
                >
                  <QuestionCircleTwoTone
                    style={{ marginLeft: '5px', cursor: 'pointer' }}
                  />
                </Popover>
              ) : null}
            </Paragraph>

            <div style={{ width: '50%' }}>
              {field.renderComponent(field.getValue(), field.updateProperty)}
            </div>
          </Flex>
        </div>
      ))}
    </Space>
  );
};

export default FreightDocumentInput;
