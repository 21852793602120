import { PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, Flex, Space } from 'antd';
import { EmailEvent, EventType, ExecutionStrategy } from 'digicust_types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchInput } from '../../../../Components/Inputs/SearchInput';
import EventComponent from './Event';
import { DCTooltip } from '../../../../common';

const Events = ({
  value,
  onValueChange,
  minimalMode,
}: {
  value: ExecutionStrategy;
  onValueChange: (value: ExecutionStrategy) => void;
  minimalMode?: boolean;
}) => {
  const { t } = useTranslation();

  const [search, setSearch] = useState('');

  return (
    <Space
      direction="vertical"
      style={
        minimalMode
          ? {}
          : {
              width: '100%',
              maxHeight: 'calc(100vh - 160px)',
              overflowY: 'auto',
              padding: '0 16px',
            }
      }
    >
      <Flex align="center" wrap gap={5} style={{ marginBottom: 10 }}>
        <SearchInput onChange={setSearch} style={{ maxWidth: 300 }} />
        <Checkbox
          checked={!value.doNotSendUploadNotification}
          onChange={(e) =>
            onValueChange({
              ...value,
              doNotSendUploadNotification: !e.target.checked,
            })
          }
        >
          {t('Upload Notification')}
          <DCTooltip
            text={t('Email notification to the person who uploaded via email.')}
          />
        </Checkbox>
        <Checkbox
          checked={!value.doNotSendProcessedNotification}
          onChange={(e) =>
            onValueChange({
              ...value,
              doNotSendProcessedNotification: !e.target.checked,
            })
          }
        >
          {t('Processed Notification')}
          <DCTooltip
            text={t('Email notification to the person who uploaded via email.')}
          />
        </Checkbox>
        <Button
          icon={<PlusOutlined />}
          onClick={() => {
            const newEvents = [
              ...(value.events || []),
              {
                checkpoint: 'processed',
                type: EventType.mail,
                suppressMultipleExecutions: true,
                emailAddress: 'validation@digicust.com',
                attachDocuments: {
                  active: true,
                },
              },
            ];
            onValueChange({ ...value, events: newEvents });
          }}
        >
          {t('Add Event')}
        </Button>
      </Flex>

      {value?.events
        ?.filter(
          (event) =>
            !search ||
            JSON.stringify(event).toLowerCase().includes(search?.toLowerCase()),
        )
        ?.map((event, index) => (
          <EventComponent
            value={event as EmailEvent}
            onDelete={() => {
              const newEvents = [...(value.events || [])];
              newEvents.splice(index, 1);
              onValueChange({ ...value, events: newEvents });
            }}
            onCopy={() => {
              const newEvents = [...(value.events || []), { ...(event || {}) }];
              onValueChange({ ...value, events: newEvents });
            }}
            onChange={(e) => {
              const newEvents = [...(value.events || [])];
              newEvents[index] = e;

              onValueChange({ ...value, events: newEvents });
            }}
          />
        ))}
    </Space>
  );
};

export default Events;
