import { Flex, Select } from 'antd';
import { ExecutionStrategy } from 'digicust_types';
import { useTranslation } from 'react-i18next';
import { DCTooltip } from '../../../../../common';
import { CaseFieldMapper } from '../../../../CustomsCaseDetails/types';

export const useTransitSettingsMapper = ({
  execStrategy,
  onValueChange,
}: {
  execStrategy: ExecutionStrategy;
  onValueChange: (val: ExecutionStrategy) => void;
}) => {
  const { t } = useTranslation();

  const fields: CaseFieldMapper[] = [
    {
      title: t('Guarantee execStrategy strategy'),
      help: t(
        'Specify how the guarantee execStrategy is calculated. If you choose "Header only; from Invoices Items", the guarantee execStrategy will be calculated based on the total execStrategy of the invoice items. If you choose "Header only; from Invoices Total Value", the guarantee execStrategy will be calculated based on the total execStrategy of the invoice. If you choose "Line Items", the guarantee execStrategy will be calculated based on the total execStrategy of the line items.',
      ),
      getValue: () => execStrategy.dataNormalization?.guaranteeValueStrategy,
      updateProperty: (value) => {
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            guaranteeValueStrategy: value,
          },
        });
      },
      clearProperty: () => {
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            guaranteeValueStrategy: undefined,
          },
        });
      },
      renderComponent: (value, updateProperty) => (
        <Select
          popupMatchSelectWidth={false}
          size="small"
          showSearch
          placeholder={t('Guarantee Value Strategy')}
          value={value}
          onChange={(value) => updateProperty(value)}
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        >
          <Select.Option value="headerFromInvoices">
            {t('Header only; from Invoices Items')}
          </Select.Option>
          <Select.Option value="headerFromInvoiceHeader">
            {t('Header only; from Invoices Total Value')}
          </Select.Option>
          <Select.Option value="lineItems">{t('Line items')}</Select.Option>
          <Select.Option value="fromGlobalHead">
            {t('From global head')}
          </Select.Option>
        </Select>
      ),
    },
    {
      title: t('Guarantee execStrategy calculation'),
      help: t(
        'Specify how the guarantee execStrategy is calculated. If you choose "Exclude VAT", the guarantee execStrategy will be calculated without VAT. If you choose "Include 19% VAT", the guarantee execStrategy will be calculated with 19% VAT.',
      ),
      getValue: () => execStrategy.dataNormalization?.guaranteeValueCalculation,
      updateProperty: (value) => {
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            guaranteeValueCalculation: value,
          },
        });
      },
      clearProperty: () => {
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            guaranteeValueCalculation: undefined,
          },
        });
      },
      renderComponent: (value, updateProperty) => (
        <Select
          popupMatchSelectWidth={false}
          size="small"
          showSearch
          placeholder={t('Guarantee execStrategy calculation')}
          value={value}
          onChange={(value) => updateProperty(value)}
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        >
          <Select.Option value="excludeVAT">{t('Exclude VAT')}</Select.Option>
          <Select.Option value="includeVAT19%">
            {t('Include 19% VAT')}
          </Select.Option>
        </Select>
      ),
    },
    {
      title: t('Guarantee execStrategy calculation strategy'),
      help: t(
        'Specify how the guarantee execStrategy is calculated. If you choose "Default", Digicust tries to minimize duty paid. Alternatively, you can choose specific strategies like assuming 25% or 26%.',
      ),
      getValue: () =>
        execStrategy.dataNormalization?.guaranteeValueCalculationStrategy,
      updateProperty: (value) => {
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            guaranteeValueCalculationStrategy: value,
          },
        });
      },
      clearProperty: () => {
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            guaranteeValueCalculationStrategy: undefined,
          },
        });
      },
      renderComponent: (value, updateProperty) => (
        <Select
          popupMatchSelectWidth={false}
          size="small"
          showSearch
          placeholder={t('Guarantee execStrategy calculation strategy')}
          value={value}
          onChange={(value) => updateProperty(value)}
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        >
          <Select.Option value="default">
            {t('Default (Digicust tries to minimize duty paid)')}
          </Select.Option>
          <Select.Option value="assume25%">
            {t('Always assume 25%')}
          </Select.Option>
          <Select.Option value="assume26%">
            {t('Always assume 26%')}
          </Select.Option>
        </Select>
      ),
    },
  ];

  return { fields };
};

export default function TransitSettings({
  execStrategy,
  onValueChange,
}: {
  execStrategy: ExecutionStrategy;
  onValueChange: (val: ExecutionStrategy) => void;
}) {
  const { fields } = useTransitSettingsMapper({ execStrategy, onValueChange });

  return (
    <Flex wrap gap={6}>
      {fields.map((field) => {
        if (field.isCheckbox) {
          return field.renderComponent(
            field.getValue(),
            field.updateProperty,
            field.title,
            field.help as string,
          );
        }

        return (
          <div className="hoverable" style={{ display: 'inline-block' }}>
            {field.title}
            <DCTooltip text={field.help as string} />
            {field.renderComponent(field.getValue(), field.updateProperty)}
          </div>
        );
      })}
    </Flex>
  );
}
