import { Route, Routes } from 'react-router-dom';

import { Button, Result } from 'antd';
import React, { Suspense } from 'react';
import Page from '../Components/Page';

import { MemberRoles } from 'digicust_types';
import { AbacProvider } from 'react-abac';
import DCSpinner from '../common/FullPageLoader';
import KBar from '../Components/KBar/KBar';
import usePermissions from '../permission';
import { useAppDataContext } from './AppDataProvider';
import CBAM from './CBAM/CBAM';
import { LeanCustomsCase } from './CustomsCases/CustomsCase';
import CustomsCaseProvider from './CustomsCases/hooks/customs-cases-context';
import TaricChatFloatingDrawer from './TariffClassification/TaricChatFloatingDrawer';

const Admin = React.lazy(() => import('./Admin/Admin'));
const ChatComponent = React.lazy(() => import('./Chat/ChatComponent'));
const CustomsCases = React.lazy(() => import('./CustomsCases'));
const CustomsCase = React.lazy(() => import('./CustomsCaseDetails'));
const MasterData = React.lazy(() => import('./MasterData/MasterData'));
const Settings = React.lazy(() => import('./Settings'));
const ExecutionStrategyPage = React.lazy(
  () => import('./Settings/ExecutionStrategies/EditExecutionStrategy'),
);
const SideMenu = React.lazy(() => import('./SideMenu/SideMenu'));
const Statistics = React.lazy(() => import('./Statistics/Statistics'));
const TariffClassification = React.lazy(
  () => import('./TariffClassification/TariffClassification'),
);

const CustomerProjectSpecificRoutes: React.FC = () => {
  const { isAdmin, customerId, projectId, user } = useAppDataContext();
  const { rules } = usePermissions();

  return (
    <AbacProvider
      user={user}
      roles={[user?.membershipRole || MemberRoles.user]}
      rules={rules}
      permissions={user?.membership[0].permissions}
    >
      <CustomsCaseProvider customerId={customerId!} projectId={projectId!}>
        <TaricChatFloatingDrawer />
        <KBar />

        <Routes>
          <Route path={'/*'} element={<SideMenu />} />
          <Route path={'/:module/*'} element={<SideMenu />} />
        </Routes>

        <Suspense fallback={<DCSpinner center marginTop={100} />}>
          <Routes>
            <Route
              index
              element={
                <Page title="Customs Cases">
                  <CustomsCases />
                </Page>
              }
            />
            <Route
              path="/cases/case/:id/*"
              element={
                <Page title="Customs Case">
                  <CustomsCase />
                </Page>
              }
            />
            <Route
              path="/cases/lean/:id/*"
              element={
                <Page title="Customs Case">
                  <LeanCustomsCase />
                </Page>
              }
            />
            <Route
              path="/cases/*"
              element={
                <Page title="Customs Cases">
                  <CustomsCases />
                </Page>
              }
            />
            <Route
              path="/cases/:menuItem/*"
              element={
                <Page title="Customs Cases">
                  <CustomsCases />
                </Page>
              }
            />
            {/* <Route
              path="/master-data/material/:id/*"
              element={
                <Page title="Master Data">
                  <MaterialPage />
                </Page>
              }
            />

            <Route
              path="/master-data/stakeholder/:id/*"
              element={
                <Page title="Master Data">
                  <StakeholderPage />
                </Page>
              }
            /> */}
            <Route
              path="/master-data/*"
              element={
                <Page title="Master Data">
                  <MasterData />
                </Page>
              }
            />
            <Route
              path="/tariff-classification/*"
              element={
                <Page title="Tariff Classification">
                  <TariffClassification />
                </Page>
              }
            />
            <Route
              path="/chat/*"
              element={
                <Page title="Chat">
                  <ChatComponent />
                </Page>
              }
            />
            <Route
              path="/settings/execution-strategies/:id"
              element={
                <Page title="Execution Strategy">
                  <ExecutionStrategyPage />
                </Page>
              }
            />
            <Route
              path="/statistics/*"
              element={
                <Page title="Statistics">
                  <Statistics />
                </Page>
              }
            />
            <Route
              path="/cbam"
              element={
                <Page title="CBAM">
                  <CBAM />
                </Page>
              }
            />
            <Route
              path="/settings/*"
              element={
                <Page title="Settings">
                  <Settings />
                </Page>
              }
            />
            <Route
              path="/admin/*"
              element={
                <Page title="Admin">
                  {isAdmin ? (
                    <Admin />
                  ) : (
                    <Result
                      status="403"
                      title="403"
                      subTitle="Sorry, you are not authorized to access this page."
                      extra={<Button type="primary">Back Home</Button>}
                    />
                  )}
                </Page>
              }
            />
          </Routes>
        </Suspense>
      </CustomsCaseProvider>
    </AbacProvider>
  );
};

export default CustomerProjectSpecificRoutes;
