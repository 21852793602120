import { Flex } from 'antd';
import { CaseFieldMapper } from '../../../../CustomsCaseDetails/types';
import { DCTooltip } from '../../../../../common';

function FromCaseFieldMapper({ fields }: { fields: CaseFieldMapper[] }) {
  return (
    <Flex wrap gap={6}>
      {fields.map((field) => {
        if (field.isCheckbox) {
          return field.renderComponent(
            field.getValue(),
            field.updateProperty,
            field.title,
            field.help as string,
          );
        }

        return (
          <div className="hoverable" style={{ display: 'inline-block' }}>
            {field.title}
            <DCTooltip text={field.help as string} />
            {field.renderComponent(field.getValue(), field.updateProperty)}
          </div>
        );
      })}
    </Flex>
  );
}

export default FromCaseFieldMapper;
