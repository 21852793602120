import React from 'react';
import { Card, Typography, Space, Button, Flex, theme } from 'antd';
import {
  CopyOutlined,
  DeleteOutlined,
  HolderOutlined,
} from '@ant-design/icons';
import { DraggableProvided } from 'react-beautiful-dnd';
import { UserInputTemplate } from 'digicust_types';

const { Text } = Typography;

interface EditableTemplateCardProps {
  template: UserInputTemplate;
  isSelected: boolean;
  index: number;
  provided: DraggableProvided;
  onNameChange: (index: number, name: string) => void;
  onDescriptionChange: (index: number, description: string) => void;
  onDuplicate: (template: UserInputTemplate, index: number) => void;
  onDelete: (index: number) => void;
  onClick: () => void;
  bgColor: string;
}

const EditableTemplateCard: React.FC<EditableTemplateCardProps> = ({
  template,
  isSelected,
  index,
  provided,
  onNameChange,
  onDescriptionChange,
  onDuplicate,
  onDelete,
  onClick,
  bgColor,
}) => {
  const { token } = theme.useToken();

  return (
    <Card
      size="small"
      ref={provided.innerRef}
      {...provided.draggableProps}
      style={{
        ...provided.draggableProps.style,
        marginBottom: token.marginXS,
        cursor: 'pointer',
        background: isSelected ? bgColor : token.colorBgContainer,
      }}
      onClick={onClick}
    >
      <Flex align="center" justify="space-between">
        <Space direction="vertical" style={{ flex: 1 }}>
          <Space style={{ width: '100%' }}>
            <div
              {...provided.dragHandleProps}
              style={{ color: token.colorTextDescription, cursor: 'grab' }}
            >
              <HolderOutlined />
            </div>
            <Text
              editable={{
                onChange: (name) => onNameChange(index, name),
                tooltip: 'Click to edit name',
              }}
              style={{ margin: 0 }}
            >
              {template.name}
            </Text>
          </Space>

          <Text
            type="secondary"
            editable={{
              onChange: (description) =>
                onDescriptionChange(index, description),
              tooltip: 'Click to edit description',
            }}
            style={{
              marginLeft: token.marginXL,
              fontSize: token.fontSizeSM,
            }}
          >
            {template.description || 'Add description...'}
          </Text>
        </Space>

        <Space>
          <Button
            size="small"
            type="text"
            onClick={(e) => {
              e.stopPropagation();
              onDuplicate(template, index);
            }}
            icon={<CopyOutlined />}
          />
          <Button
            size="small"
            type="text"
            onClick={(e) => {
              e.stopPropagation();
              onDelete(index);
            }}
            icon={<DeleteOutlined />}
          />
        </Space>
      </Flex>
    </Card>
  );
};

export default EditableTemplateCard;
