import { Checkbox, Flex, Select, Space, Typography } from 'antd';
import { ExecutionStrategy } from 'digicust_types';
import { useTranslation } from 'react-i18next';
import { DCTooltip } from '../../../../common';
import NumberInput from '../../../../Components/Inputs/Common/NumberInput';
import { CaseFieldMapper } from '../../../CustomsCaseDetails/types';
import CardWrapper from '../../Common/CardWrapper';
import StakeholderSettings from './data-integration/stakeholder-settings';
import MaterialSettings from './data-integration/material-settings';
import TariffNumberSettings from './data-integration/tariff-number-settings';
import MaterialIntegrationSettings from './data-integration/material-integration-settings';

export const useIntegrationServiceMapperES = ({
  execStrategy,
  onValueChange,
}: {
  execStrategy: ExecutionStrategy;
  onValueChange: (val: ExecutionStrategy) => void;
}) => {
  const { t } = useTranslation();

  const fields: CaseFieldMapper[] = [
    {
      title: t('Require user input'),
      help: t(
        'Defines whether to wait for user input before sending the customs case to the next system',
      ),
      getValue: () => execStrategy.dataIntegration?.requireUserInput,
      updateProperty: (requireUserInput) => {
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...(execStrategy.dataIntegration || {}),
            requireUserInput,
          },
        });
      },
      clearProperty: () => {
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...(execStrategy.dataIntegration || {}),
            requireUserInput: undefined,
          },
        });
      },
      renderComponent: (value, updateProperty) => {
        return (
          <Select
            popupMatchSelectWidth={false}
            size="small"
            placeholder={t('Require User Input')}
            optionFilterProp="children"
            value={value}
            onChange={(requireUserInput) => updateProperty(requireUserInput)}
          >
            <Select.Option value="false">{t('No Requirement')}</Select.Option>
            <Select.Option value="true">
              {t('Require User Input')}
            </Select.Option>
            <Select.Option value="masterDataOrUserInput">
              {t('Require Master Data or User Input')}
            </Select.Option>
          </Select>
        );
      },
    },
    {
      title: t('Create new case for every waybill number'),
      help: t('Will handle every waybill number as its own customs case.'),
      isCheckbox: true,
      getValue: () =>
        execStrategy.dataIntegration?.createNewCaseForEveryWaybillNumber
          ?.active,
      updateProperty: (checked) => {
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...(execStrategy.dataIntegration || {}),
            createNewCaseForEveryWaybillNumber: {
              ...(execStrategy?.dataIntegration
                ?.createNewCaseForEveryWaybillNumber || {}),
              active: checked,
            },
          },
        });
      },
      clearProperty: () => {
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...(execStrategy.dataIntegration || {}),
            createNewCaseForEveryWaybillNumber: undefined,
          },
        });
      },
      renderComponent: (value, updateProperty, title, help) => {
        return (
          <Checkbox
            className="hoverable"
            checked={value}
            onChange={(e) => updateProperty(e.target.checked)}
          >
            {title}
            {help && <DCTooltip text={help} />}
          </Checkbox>
        );
      },
    },
    {
      title: t('Create new case as detected in excel'),
      help: t(
        'If multiple shipments/declarations are detected in excel, Digicust will split the case accordingly. First per waybill number, then per invoice number and else one case per row.',
      ),
      isCheckbox: true,
      getValue: () =>
        execStrategy.dataIntegration?.createNewCustomsCasesAsDetectedInExcel
          ?.active,
      updateProperty: (checked) => {
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...(execStrategy.dataIntegration || {}),
            createNewCustomsCasesAsDetectedInExcel: {
              ...(execStrategy?.dataIntegration
                ?.createNewCustomsCasesAsDetectedInExcel || {}),
              active: checked,
            },
          },
        });
      },
      clearProperty: () => {
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...(execStrategy.dataIntegration || {}),
            createNewCustomsCasesAsDetectedInExcel: undefined,
          },
        });
      },
      renderComponent: (value, updateProperty, title, help) => {
        return (
          <Checkbox
            className="hoverable"
            checked={value}
            onChange={(e) => updateProperty(e.target.checked)}
          >
            {title}
            {help && <DCTooltip text={help} />}
          </Checkbox>
        );
      },
    },
    {
      title: t('Create new case for every invoice'),
      help: t('Will handle every invoice as its own customs case.'),
      isCheckbox: true,
      getValue: () =>
        execStrategy.dataIntegration?.createNewCustomsCaseForInvoice?.active,
      updateProperty: (checked) => {
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...(execStrategy.dataIntegration || {}),
            createNewCustomsCaseForInvoice: {
              ...(execStrategy?.dataIntegration
                ?.createNewCustomsCaseForInvoice || {}),
              active: checked,
            },
          },
        });
      },
      clearProperty: () => {
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...(execStrategy.dataIntegration || {}),
            createNewCustomsCaseForInvoice: undefined,
          },
        });
      },
      renderComponent: (value, updateProperty, title, help) => {
        return (
          <Checkbox
            className="hoverable"
            checked={value}
            onChange={(e) => updateProperty(e.target.checked)}
          >
            {title}
            {help && <DCTooltip text={help} />}
          </Checkbox>
        );
      },
    },
    {
      title: t('Create new case as mentioned in email'),
      help: t(
        'If multiple shipments/declarations are requested in email, Digicust will split the case accordingly.',
      ),
      isCheckbox: true,
      getValue: () =>
        execStrategy.dataIntegration?.createNewCustomsCasesAsMentionedInEmail
          ?.active,
      updateProperty: (checked) => {
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...(execStrategy.dataIntegration || {}),
            createNewCustomsCasesAsMentionedInEmail: {
              ...(execStrategy?.dataIntegration
                ?.createNewCustomsCasesAsMentionedInEmail || {}),
              active: checked,
            },
          },
        });
      },
      clearProperty: () => {
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...(execStrategy.dataIntegration || {}),
            createNewCustomsCasesAsMentionedInEmail: undefined,
          },
        });
      },
      renderComponent: (value, updateProperty, title, help) => {
        return (
          <Checkbox
            className="hoverable"
            checked={value}
            onChange={(e) => updateProperty(e.target.checked)}
          >
            {title}
            {help && <DCTooltip text={help} />}
          </Checkbox>
        );
      },
    },
    {
      title: t('Create new case as per invoice recipient'),
      help: t(
        'For every recipient found on invoices, Digicust creates a new customs case.',
      ),
      isCheckbox: true,
      getValue: () =>
        execStrategy.dataIntegration?.createNewCustomsCasesPerRecipient?.active,
      updateProperty: (checked) => {
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...(execStrategy.dataIntegration || {}),
            createNewCustomsCasesPerRecipient: {
              ...(execStrategy?.dataIntegration
                ?.createNewCustomsCasesPerRecipient || {}),
              active: checked,
            },
          },
        });
      },
      clearProperty: () => {
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...(execStrategy.dataIntegration || {}),
            createNewCustomsCasesPerRecipient: undefined,
          },
        });
      },
      renderComponent: (value, updateProperty, title, help) => {
        return (
          <Checkbox
            className="hoverable"
            checked={value}
            onChange={(e) => updateProperty(e.target.checked)}
          >
            {title}
            {help && <DCTooltip text={help} />}
          </Checkbox>
        );
      },
    },
    {
      title: t('Create new case per waybill container'),
      help: t(
        'For every container found on waybills, Digicust creates a new customs case.',
      ),
      isCheckbox: true,
      getValue: () =>
        execStrategy.dataIntegration?.createNewCustomsCasesPerWaybillContainer
          ?.active,
      updateProperty: (checked) => {
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...(execStrategy.dataIntegration || {}),
            createNewCustomsCasesPerWaybillContainer: {
              ...(execStrategy?.dataIntegration
                ?.createNewCustomsCasesPerWaybillContainer || {}),
              active: checked,
            },
          },
        });
      },
      clearProperty: () => {
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...(execStrategy.dataIntegration || {}),
            createNewCustomsCasesPerWaybillContainer: undefined,
          },
        });
      },
      renderComponent: (value, updateProperty, title, help) => {
        return (
          <Checkbox
            className="hoverable"
            checked={value}
            onChange={(e) => updateProperty(e.target.checked)}
          >
            {title}
            {help && <DCTooltip text={help} />}
          </Checkbox>
        );
      },
    },
    {
      title: t('Create new case for every X items"'),
      help: t(
        'If your customs software has a limit on the number of items you can have Digicust create and submit a new case for every X items.',
      ),
      isCheckbox: true,
      getValue: () =>
        execStrategy.dataIntegration?.createNewCustomsCaseForEveryXItems
          ?.active,
      updateProperty: (checked) => {
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...(execStrategy.dataIntegration || {}),
            createNewCustomsCaseForEveryXItems: {
              ...(execStrategy?.dataIntegration
                ?.createNewCustomsCaseForEveryXItems || {}),
              active: checked,
              threshold:
                execStrategy?.dataIntegration
                  ?.createNewCustomsCaseForEveryXItems?.threshold || 99,
            },
          },
        });
      },
      clearProperty: () => {
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...(execStrategy.dataIntegration || {}),
            createNewCustomsCaseForEveryXItems: undefined,
          },
        });
      },
      renderComponent: (value, updateProperty, title, help) => {
        return (
          <>
            <Checkbox
              className="hoverable"
              checked={value}
              onChange={(e) => updateProperty(e.target.checked)}
            >
              {title}
              {help && <DCTooltip text={help} />}
            </Checkbox>

            {execStrategy?.dataIntegration?.createNewCustomsCaseForEveryXItems
              ?.active && (
              <CardWrapper>
                <Space direction="vertical" size="small">
                  <div
                    className="hoverable"
                    style={{ display: 'inline-block' }}
                  >
                    {t('Threshold')}
                    <DCTooltip
                      text={t(
                        'Threshold at which the case is cut into two (or more).',
                      )}
                    />
                    <NumberInput
                      size="small"
                      value={
                        execStrategy?.dataIntegration
                          ?.createNewCustomsCaseForEveryXItems?.threshold
                      }
                      onChange={(e) =>
                        onValueChange({
                          ...execStrategy,
                          dataIntegration: {
                            ...(execStrategy.dataIntegration || {}),
                            createNewCustomsCaseForEveryXItems: {
                              ...(execStrategy?.dataIntegration
                                ?.createNewCustomsCaseForEveryXItems || {}),
                              threshold: e || undefined,
                            },
                          },
                        })
                      }
                    />
                  </div>
                  <Checkbox
                    className="hoverable"
                    checked={
                      execStrategy.dataIntegration
                        ?.createNewCustomsCaseForEveryXItems?.aggregated
                    }
                    onChange={(e) =>
                      onValueChange({
                        ...execStrategy,
                        dataIntegration: {
                          ...(execStrategy.dataIntegration || {}),
                          createNewCustomsCaseForEveryXItems: {
                            ...(execStrategy?.dataIntegration
                              ?.createNewCustomsCaseForEveryXItems || {}),
                            aggregated: e.target.checked,
                          },
                        },
                      })
                    }
                  >
                    {t('Split on aggregated items')}
                    <DCTooltip
                      text={t(
                        'Takes into account aggregation by tariff number and country of origin when splitting the case.',
                      )}
                    />
                  </Checkbox>
                </Space>
              </CardWrapper>
            )}
          </>
        );
      },
    },
  ];

  return { fields };
};

export const DataIntegrationCard = ({
  execStrategy,
  onValueChange,
}: {
  execStrategy: ExecutionStrategy;
  onValueChange: (val: ExecutionStrategy) => void;
}) => {
  const { t } = useTranslation();

  return (
    <CardWrapper
      alternative
      title={t('Data Integration')}
      description={t('Specify how Digicust uses the information.')}
      checked={execStrategy?.dataIntegration?.active}
      onChange={(e) =>
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...(execStrategy.dataIntegration || {}),
            active: e,
          },
        })
      }
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        <Typography.Title level={5}>
          {t('Stakeholder Settings')}
        </Typography.Title>
        <StakeholderSettings
          execStrategy={execStrategy}
          onValueChange={onValueChange}
        />

        <Typography.Title level={5}>{t('Material Settings')}</Typography.Title>
        <MaterialSettings
          execStrategy={execStrategy}
          onValueChange={onValueChange}
        />

        <Typography.Title level={5}>
          {t('Tariff Number Settings')}
        </Typography.Title>
        <TariffNumberSettings
          execStrategy={execStrategy}
          onValueChange={onValueChange}
        />

        <Typography.Title level={5}>
          {t('Material Integration Settings')}
        </Typography.Title>
        <MaterialIntegrationSettings
          execStrategy={execStrategy}
          onValueChange={onValueChange}
        />

        <Typography.Title level={5}>{t('Other options')}</Typography.Title>
        <OtherOptionsSettings
          execStrategy={execStrategy}
          onValueChange={onValueChange}
        />
      </Space>
    </CardWrapper>
  );
};

function OtherOptionsSettings({
  execStrategy,
  onValueChange,
}: {
  execStrategy: ExecutionStrategy;
  onValueChange: (val: ExecutionStrategy) => void;
}) {
  const { fields } = useIntegrationServiceMapperES({
    execStrategy,
    onValueChange,
  });

  return (
    <>
      {execStrategy.dataIntegration?.active && (
        <Flex wrap gap={6}>
          {fields.map((field) => {
            if (field.isCheckbox) {
              return field.renderComponent(
                field.getValue(),
                field.updateProperty,
                field.title,
                field.help as string,
              );
            }

            return (
              <div className="hoverable" style={{ display: 'inline-block' }}>
                {field.title}
                <DCTooltip text={field.help as string} />
                {field.renderComponent(field.getValue(), field.updateProperty)}
              </div>
            );
          })}
        </Flex>
      )}
    </>
  );
}
