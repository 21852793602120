import { CopyOutlined } from '@ant-design/icons';
import {
  Button,
  Flex,
  Space,
  TablePaginationConfig,
  Tag,
  Tooltip,
  Typography,
  message,
} from 'antd';
import { LineItemModel } from 'digicust_types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DGTable from '../../../common/DGTable';
import XKPreferenceSelect from '../../../Components/Country-Specific/XK/PreferenceSelect';
import XKProcedureSelect from '../../../Components/Country-Specific/XK/ProcedureSelect';
import XKSupplementaryUnit from '../../../Components/Country-Specific/XK/SupplementaryUnit';
import CustomsTariffNumberSelect from '../../../Components/custom-tariff-number-select';
import AmountOfMoneyInput from '../../../Components/Inputs/AmountOfMoneyInput';
import CountrySelect from '../../../Components/Inputs/CountrySelect';
import DocumentsTypeCode from '../../../Components/Inputs/document-type-code-input';
import FreightCostDistributionInput from '../../../Components/Inputs/FreightCostDistributionInput';
import GoodsDescriptionInput from '../../../Components/Inputs/GoodsDescriptionInput';
import ItemsNumber from '../../../Components/Inputs/ItemsNumber';
import PreferenceSelect from '../../../Components/Inputs/PreferenceSelect';
import ProcedureSelect from '../../../Components/Inputs/ProcedureSelect';
import QuantityInput from '../../../Components/Inputs/QuantityInput';
import SpecialMeasureUnit from '../../../Components/Inputs/SpecialMeasureInput';
import WeightInput from '../../../Components/Inputs/WeightInput';
import { TariffClassificationPopup } from '../../../Components/TariffClassification';
import { useAppDataContext } from '../../AppDataProvider';
import { aggregateLineItems } from './aggregateData';
import useCopyHook, { CopiedData } from './hooks/useCopyHook';
import useLineItemHook from './hooks/useLineItemHook';
import LineItemsSummary from './LineItemSummary';
import LineTableHeader from './LineTableHeader';
import { useCompactViewColumns } from './tableViews/useCompactView';
import { ReasonTooltip } from '../../../utils/helpers/normalise-reasons';

export default function LineItemsTable({
  lineItems,
  setLineItems,
  reloadLineItems = () => null,
  single,
}: {
  lineItems: LineItemModel[];
  setLineItems: (value: LineItemModel[]) => void;
  single?: boolean;
  reloadLineItems?: (lineItemIds: string[]) => void;
}) {
  const { t } = useTranslation();
  const { projectDetails } = useAppDataContext();

  const {
    dense,
    setDense,
    aggregate,
    setAggregate,
    selectedRowKeys,
    setSelectedRowKeys,
    allSelected,
    setAllSelected,
    selectedItems,
    setSelectedItems,
  } = useLineItemHook();

  const {
    handleCopy,
    handlePaste,
    copiedData,
    setCopiedData,
    copiedIndex,
    setCopiedIndex,
    clearCopiedData,
  } = useCopyHook({
    lineItems,
    onPaste: (newLineItems) => {
      setLineItems(newLineItems);
      setSelectedItems([]);
      setSelectedRowKeys([]);
      setAllSelected(false);
    },
  });

  const [paginated, setPaginated] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
  });

  useEffect(
    () => setPaginated({ ...paginated, pageSize: dense ? 20 : 10 }),
    [dense],
  );

  const checkAndUpdateLineItem = () => {
    const refetchLineItems = lineItems
      .filter((item) => item.customsTariffNumber?.taric?.toBeProcessed)
      .map((item) => item.id!);

    if (refetchLineItems.length) {
      reloadLineItems(refetchLineItems);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      checkAndUpdateLineItem();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [lineItems]);

  const updateItem = (item: LineItemModel, index: number) => {
    const i = (paginated.current! - 1) * paginated.pageSize! + index;

    const newValue = [...lineItems];
    newValue[single ? 0 : i] = item;
    setLineItems(newValue);
  };

  const getCopiableTitle = (title: string) => {
    return (
      <Space>
        <Typography.Text>{title}</Typography.Text>
        <Tooltip title={t('This column is copyable')}>
          <CopyOutlined />
        </Tooltip>
      </Space>
    );
  };

  const { compactViewColumns } = useCompactViewColumns();

  return (
    <div style={{ padding: '0 10px 20px 10px' }}>
      <DGTable<LineItemModel>
        headerTitle={`${(lineItems?.length || 0).toString()} items`}
        scroll={{ x: '100%' }}
        dataSource={
          dense && aggregate
            ? aggregateLineItems(JSON.parse(JSON.stringify(lineItems)))
            : lineItems
        }
        pagination={
          single
            ? false
            : {
                showSizeChanger: true,
                pageSize: paginated.pageSize,
                current: paginated.current,
              }
        }
        onChange={(pagination) => setPaginated(pagination)}
        rightToolbar={
          single ? null : (
            <LineTableHeader
              dense={dense}
              setDense={setDense}
              aggregate={aggregate}
              setAggregate={setAggregate}
              selectedRowKeys={selectedRowKeys}
              setSelectedRowKeys={setSelectedRowKeys}
              allSelected={allSelected}
              setAllSelected={setAllSelected}
              selectedItems={selectedItems}
              handlePaste={handlePaste}
              copiedData={copiedData}
              clearCopiedData={clearCopiedData}
              lineItems={lineItems}
              setLineItems={setLineItems}
              setPaginated={setPaginated}
            />
          )
        }
        rowKey="id"
        rowSelection={{
          selectedRowKeys: selectedRowKeys,
          onChange: (newSelectedRowKeys, selectedRows, info) => {
            setAllSelected(
              info.type === 'all' && newSelectedRowKeys.length > 0,
            );
            setSelectedRowKeys(newSelectedRowKeys);
          },
          type: 'checkbox',
          onSelect: (item) => {
            setSelectedItems((prevItems) => {
              if (
                !prevItems.some((existingItem) => existingItem.id === item.id)
              ) {
                return [...prevItems, item];
              } else {
                return prevItems;
              }
            });
          },
          onSelectAll: (selected, selectedRows) => {
            setSelectedItems(selected ? selectedRows : []);
          },
        }}
        summary={() =>
          single ? null : <LineItemsSummary lineItems={lineItems} />
        }
        columns={
          dense
            ? compactViewColumns
            : [
                {
                  title: '#',
                  key: 'index',
                  fixed: 'left',
                  width: 45,
                  align: 'center',
                  render: (_, item, index) => (
                    <Button
                      tabIndex={-1}
                      size="small"
                      type="text"
                      title={t('Copy Data')}
                      onClick={() => {
                        if (copiedIndex.key === item.id) {
                          clearCopiedData();
                          return;
                        }

                        const newItem: CopiedData = {
                          description: item.description,
                          customsTariffNumber: item.customsTariffNumber,
                          additionalCodes:
                            item.customsTariffNumber?.additionalCodes,
                          preferenceState: item.preferenceState,
                          documents: item.documents,
                          customsProcedure: item.customsProcedure,
                        };
                        setCopiedData(newItem);

                        setCopiedIndex({
                          key: item.id,
                          description: item.id,
                          customsTariffNumber: item.id,
                          additionalCodes: item.id,
                          preferenceState: item.id,
                          documents: item.id,
                          customsProcedure: item.id,
                        });

                        message.success(`${index + 1} ${t('row data copied')}`);
                      }}
                      style={{
                        outline:
                          copiedIndex.key === item.id ? '2px solid blue' : '',
                      }}
                    >
                      {(paginated.current! - 1) * paginated.pageSize! +
                        index +
                        1}
                    </Button>
                  ),
                },
                {
                  title: getCopiableTitle(t('Description')),
                  key: 'description',
                  // fixed: 'left',
                  width: '450px',
                  render: (_, item, index) => (
                    <Space direction="vertical" style={{ width: '100%' }}>
                      <ReasonTooltip reason={item.description?.reason}>
                        <Flex align="center" gap={5}>
                          <GoodsDescriptionInput
                            itemId={item?.id}
                            value={item?.description}
                            onChange={(description) => {
                              updateItem(
                                {
                                  ...(item || {}),
                                  description: {
                                    ...(description || {}),
                                    userEdited: true,
                                  },
                                },
                                index,
                              );
                            }}
                            onCopy={() =>
                              handleCopy(
                                { description: item?.description },
                                item.id,
                              )
                            }
                            isCopied={copiedIndex.description === item.id}
                          />

                          <TariffClassificationPopup
                            customTariffNumber={item.customsTariffNumber}
                            description={item.description?.value}
                            lineItemId={item.id!}
                            caseId={item.caseId!}
                            barCode={
                              item.code?.value || item.materialNumber?.value
                            }
                            onChange={(tariffNumber) => {
                              updateItem(
                                {
                                  ...(item || {}),
                                  customsTariffNumber: {
                                    ...(item?.customsTariffNumber || {}),
                                    ...(tariffNumber || {}),
                                    userEdited: true,
                                  },
                                },
                                index,
                              );
                            }}
                          />
                        </Flex>
                      </ReasonTooltip>
                      <ReasonTooltip reason={item.customsTariffNumber?.reason}>
                        <CustomsTariffNumberSelect
                          itemId={item?.id}
                          customsTariffNumber={item?.customsTariffNumber}
                          onChange={(tariffNumber) => {
                            updateItem(
                              {
                                ...(item || {}),
                                customsTariffNumber: {
                                  ...(item?.customsTariffNumber || {}),
                                  ...(tariffNumber || {}),
                                  userEdited: true,
                                },
                              },
                              index,
                            );
                          }}
                          onCopy={(label) => {
                            if (label === 'customsTariffNumber') {
                              handleCopy(
                                {
                                  customsTariffNumber: {
                                    ...item?.customsTariffNumber,
                                    additionalCodes: [],
                                  },
                                },
                                item.id,
                              );
                            } else {
                              handleCopy(
                                {
                                  additionalCodes:
                                    item?.customsTariffNumber?.additionalCodes,
                                },
                                item.id,
                              );
                            }
                          }}
                          isTariffNumberCopied={
                            copiedIndex.customsTariffNumber === item.id
                          }
                          isAdditionalCodeCopied={
                            copiedIndex.additionalCodes === item.id
                          }
                        />
                      </ReasonTooltip>
                      {(item?.description?.webResults?.length || 0) > 0 && (
                        <div
                          style={{
                            width: '100%',
                            overflowX: 'scroll',
                            height: '50px',
                          }}
                        >
                          {item?.description?.webResults?.map((result) => (
                            <a href={result?.link} target="_blank">
                              <img
                                style={{ maxHeight: '50px', maxWidth: '120px' }}
                                src={result?.imageUrl}
                              />
                            </a>
                          ))}
                        </div>
                      )}
                    </Space>
                  ),
                },
                {
                  title: getCopiableTitle(t('Documents Type Codes')),
                  key: 'documents',
                  width: '250px',
                  render: (_, item, index) => (
                    <DocumentsTypeCode
                      value={item?.documents}
                      onChange={(documents) => {
                        updateItem(
                          {
                            ...(item || {}),
                            documents: [...documents],
                          },
                          index,
                        );
                      }}
                      onCopy={() =>
                        handleCopy(
                          { documents: item?.documents || [] },
                          item.id,
                        )
                      }
                      isCopied={copiedIndex.documents === item.id}
                    />
                  ),
                },
                projectDetails?.tariffNumberTreeSystem === 'XK'
                  ? {
                      title: (
                        <>
                          {getCopiableTitle(t('Preference'))} <Tag>XK</Tag>
                        </>
                      ),
                      key: 'preference',
                      width: '200px',
                      render: (_, item, index) => (
                        <XKPreferenceSelect
                          key={index}
                          value={item?.countrySpecific?.XK?.Preference_code}
                          onChange={(e) => {
                            console.log(e);
                            updateItem(
                              {
                                ...(item || {}),
                                countrySpecific: {
                                  XK: {
                                    ...(item?.countrySpecific?.XK || {}),
                                    Preference_code: e || undefined,
                                  },
                                },
                              },
                              index,
                            );
                          }}
                          onCopy={() =>
                            handleCopy(
                              { preferenceState: item?.preferenceState },
                              item.id,
                            )
                          }
                          isCopied={copiedIndex.preferenceState === item.id}
                        />
                      ),
                    }
                  : {
                      title: getCopiableTitle(t('Preference')),
                      key: 'preference',
                      width: '200px',
                      render: (_, item, index) => (
                        <PreferenceSelect
                          key={index}
                          value={item.preferenceState}
                          onChange={(e) => {
                            console.log(e);
                            updateItem(
                              {
                                ...(item || {}),
                                preferenceState: {
                                  ...(e || {}),
                                  userEdited: true,
                                },
                              },
                              index,
                            );
                          }}
                          onCopy={() =>
                            handleCopy(
                              { preferenceState: item?.preferenceState },
                              item.id,
                            )
                          }
                          isCopied={copiedIndex.preferenceState === item.id}
                        />
                      ),
                    },
                projectDetails?.tariffNumberTreeSystem === 'XK'
                  ? {
                      title: (
                        <>
                          {getCopiableTitle(t('Procedure'))} <Tag>XK</Tag>
                        </>
                      ),
                      key: 'procedure',
                      width: '250px',
                      render: (_, item, index) => (
                        <XKProcedureSelect
                          key={index}
                          value={{
                            Extended_customs_procedure:
                              item.countrySpecific?.XK
                                ?.Extended_customs_procedure,
                            National_customs_procedure:
                              item.countrySpecific?.XK
                                ?.National_customs_procedure,
                          }}
                          onChange={(e) =>
                            updateItem(
                              {
                                ...(item || {}),
                                countrySpecific: {
                                  XK: {
                                    ...(item?.countrySpecific?.XK || {}),
                                    ...(e || {}),
                                  },
                                },
                              },
                              index,
                            )
                          }
                          onCopy={() =>
                            handleCopy(
                              { customsProcedure: item?.customsProcedure },
                              item.id,
                            )
                          }
                          isCopied={copiedIndex.customsProcedure === item.id}
                        />
                      ),
                    }
                  : {
                      title: getCopiableTitle(t('Procedure')),
                      key: 'procedure',
                      width: '250px',
                      render: (_, item, index) => (
                        <ReasonTooltip reason={item?.customsProcedure?.reason}>
                          <ProcedureSelect
                            key={index}
                            value={item.customsProcedure}
                            onChange={(e) =>
                              updateItem(
                                {
                                  ...(item || {}),
                                  customsProcedure: {
                                    ...(e || {}),
                                    userEdited: true,
                                  },
                                },
                                index,
                              )
                            }
                            onCopy={() =>
                              handleCopy(
                                { customsProcedure: item?.customsProcedure },
                                item.id,
                              )
                            }
                            isCopied={copiedIndex.customsProcedure === item.id}
                          />
                        </ReasonTooltip>
                      ),
                    },
                {
                  title: t('Total Net Weight'),
                  key: 'totalNetWeight',
                  width: '200px',
                  render: (_, item, index) => (
                    <ReasonTooltip reason={item?.totalNetWeight?.reason}>
                      <WeightInput
                        key={index}
                        weight={item?.totalNetWeight}
                        onChange={(e) =>
                          updateItem(
                            {
                              ...(item || {}),
                              totalNetWeight: {
                                ...(e || {}),
                                userEdited: true,
                              },
                            },
                            index,
                          )
                        }
                      />
                    </ReasonTooltip>
                  ),
                },
                {
                  title: t('Total Gross Weight'),
                  key: 'totalGrossWeight',
                  width: '200px',
                  render: (_, item, index) => (
                    <ReasonTooltip reason={item?.totalGrossWeight?.reason}>
                      <WeightInput
                        key={index}
                        weight={item?.totalGrossWeight}
                        onChange={(e) =>
                          updateItem(
                            {
                              ...(item || {}),
                              totalGrossWeight: {
                                ...(e || {}),
                                userEdited: true,
                              },
                            },
                            index,
                          )
                        }
                      />
                    </ReasonTooltip>
                  ),
                },
                {
                  title: t('Quantity'),
                  key: 'quantity',
                  width: '200px',
                  render: (_, item, index) => (
                    <ReasonTooltip reason={item?.quantity?.reason}>
                      <QuantityInput
                        key={index}
                        value={item.quantity}
                        onChange={(e) =>
                          updateItem(
                            {
                              ...(item || {}),
                              quantity: { ...(e || {}), userEdited: true },
                            },
                            index,
                          )
                        }
                      />
                    </ReasonTooltip>
                  ),
                },
                {
                  title: t('Origin'),
                  key: 'countryOfOrigin',
                  width: '250px',
                  render: (_, item, index) => (
                    <ReasonTooltip reason={item?.countryOfOrigin?.reason}>
                      <CountrySelect
                        key={index}
                        value={item.countryOfOrigin}
                        onChange={(e) =>
                          updateItem(
                            {
                              ...(item || {}),
                              countryOfOrigin: {
                                ...(e || {}),
                                userEdited: true,
                              },
                            },
                            index,
                          )
                        }
                      />
                    </ReasonTooltip>
                  ),
                },
                {
                  title: t('Total Value'),
                  key: 'totalValue',
                  width: '200px',
                  render: (_, item, index) => (
                    <ReasonTooltip reason={item?.totalValue?.reason}>
                      <AmountOfMoneyInput
                        key={index}
                        value={item?.totalValue}
                        onChange={(e) =>
                          updateItem(
                            {
                              ...(item || {}),
                              totalValue: { ...(e || {}), userEdited: true },
                            },
                            index,
                          )
                        }
                      />
                    </ReasonTooltip>
                  ),
                },
                {
                  title: t('Article Number'),
                  key: 'code',
                  width: '320px',
                  render: (_, item, index) => (
                    <ReasonTooltip reason={item?.code?.reason}>
                      <ItemsNumber
                        lineItem={item}
                        onChange={(lineItem) => {
                          updateItem({ ...(item || {}), ...lineItem }, index);
                        }}
                      />
                    </ReasonTooltip>
                  ),
                },
                {
                  title: t('Packages'),
                  key: 'packages',
                  width: '150px',
                  render: (_, item, index) => (
                    <Typography.Text ellipsis key={index}>
                      {item?.packages?.map((doc, index, array) => (
                        <ReasonTooltip
                          reason={doc?.reason}
                          key={index}
                          withIcon={false}
                        >
                          {`${doc?.amount?.value} ${doc?.type?.code}` +
                            (index < array.length - 1 ? ', ' : '')}
                        </ReasonTooltip>
                      ))}
                      {item?.includedWithPosition?.value ? (
                        <span>
                          Included with position{' '}
                          {
                            <ReasonTooltip
                              reason={item?.includedWithPosition?.reason}
                              withIcon={false}
                            >
                              {item?.includedWithPosition?.value}
                            </ReasonTooltip>
                          }
                        </span>
                      ) : (
                        ''
                      )}
                    </Typography.Text>
                  ),
                },
                projectDetails?.tariffNumberTreeSystem === 'XK'
                  ? {
                      title: (
                        <>
                          {t('Supplementary Unit')} <Tag>XK</Tag>
                        </>
                      ),
                      key: 'specialUnit',
                      width: '200px',
                      render: (_, item, index) => (
                        <XKSupplementaryUnit
                          value={item?.countrySpecific?.XK}
                          onChange={(e) =>
                            updateItem(
                              {
                                ...(item || {}),
                                countrySpecific: {
                                  ...(item?.countrySpecific || {}),
                                  XK: {
                                    ...(item?.countrySpecific?.XK || {}),
                                    ...e,
                                  },
                                },
                              },
                              index,
                            )
                          }
                        />
                      ),
                    }
                  : {
                      title: t('Special Unit'),
                      key: 'specialUnit',
                      width: '200px',
                      render: (_, item, index) => (
                        <SpecialMeasureUnit
                          itemSpecialUnitMeasure={item.itemSpecialUnitMeasure}
                          itemSpecialUnit={item.itemSpecialUnit}
                          onChange={({
                            itemSpecialUnit,
                            itemSpecialUnitMeasure,
                          }) =>
                            updateItem(
                              {
                                ...(item || {}),
                                ...(itemSpecialUnitMeasure
                                  ? { itemSpecialUnitMeasure }
                                  : {}),
                                ...(itemSpecialUnit ? { itemSpecialUnit } : {}),
                              },
                              index,
                            )
                          }
                        />
                      ),
                    },
                {
                  title: t('Customs Value'),
                  key: 'customsValue',
                  width: '200px',
                  render: (_, item, index) => (
                    <ReasonTooltip reason={item?.customsValue?.reason}>
                      <AmountOfMoneyInput
                        key={index}
                        value={item?.customsValue}
                        onChange={(e) =>
                          updateItem(
                            {
                              ...(item || {}),
                              customsValue: { ...(e || {}), userEdited: true },
                            },
                            index,
                          )
                        }
                      />
                    </ReasonTooltip>
                  ),
                },
                {
                  title: t('Customs Duty'),
                  key: 'customsDuty',
                  width: '200px',
                  render: (_, item, index) => (
                    <ReasonTooltip reason={item?.customsDuty?.reason}>
                      <AmountOfMoneyInput
                        key={index}
                        value={item?.customsDuty}
                        onChange={(e) =>
                          updateItem(
                            {
                              ...(item || {}),
                              customsDuty: { ...(e || {}), userEdited: true },
                            },
                            index,
                          )
                        }
                      />
                    </ReasonTooltip>
                  ),
                },
                {
                  title: t('Statistical Value'),
                  key: 'statisticalValue',
                  width: '200px',
                  render: (_, item, index) => (
                    <ReasonTooltip reason={item?.statisticalValue?.reason}>
                      <AmountOfMoneyInput
                        key={index}
                        value={item?.statisticalValue}
                        onChange={(e) =>
                          updateItem(
                            {
                              ...(item || {}),
                              statisticalValue: {
                                ...(e || {}),
                                userEdited: true,
                              },
                            },
                            index,
                          )
                        }
                      />
                    </ReasonTooltip>
                  ),
                },
                {
                  title: t('Import Sales Tax'),
                  key: 'importSalesTax',
                  width: '200px',
                  render: (_, item, index) => (
                    <ReasonTooltip reason={item?.importSalesTax?.reason}>
                      <AmountOfMoneyInput
                        key={index}
                        value={item?.importSalesTax}
                        onChange={(e) =>
                          updateItem(
                            {
                              ...(item || {}),
                              importSalesTax: {
                                ...(e || {}),
                                userEdited: true,
                              },
                            },
                            index,
                          )
                        }
                      />
                    </ReasonTooltip>
                  ),
                },
                {
                  title: t('Freight Cost Distribution'),
                  key: 'freightCostDistribution',
                  width: '300px',
                  render: (_, item, index) => (
                    <FreightCostDistributionInput
                      value={item?.customsValueAdditionDeduction?.[0]}
                      onChange={(deductions) =>
                        updateItem(
                          {
                            ...(item || {}),
                            customsValueAdditionDeduction: deductions
                              ? [deductions]
                              : [],
                          },
                          index,
                        )
                      }
                    />
                  ),
                },
              ]
        }
      />
    </div>
  );
}
