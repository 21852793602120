import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Flex, Select, Space, Typography } from 'antd';
import { Incoterm, MeanOfTransportationMode, Rule } from 'digicust_types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DebouncedInput from '../../../../../Components/Inputs/Common/DebouncedInput';
import NumberInput from '../../../../../Components/Inputs/Common/NumberInput';
import CountrySelect from '../../../../../Components/Inputs/CountrySelect';
import IncotermSelect from '../../../../../Components/Inputs/IncotermSelect';
import MeanOfTransportationModeSelect from '../../../../../Components/Inputs/MeanOfTransportationModeSelect';
import PackageTypeSelect from '../../../../../Components/Inputs/PackageTypeSelect';
import PortSelect from '../../../../../Components/Inputs/PortSelect';
import StakeholderSelect from '../../../../../Components/Inputs/StakeholderSelect';
import CustomsOfficeSelect from '../../../../CustomsCaseDetails/general-tab/CustomsOfficeSelect';
import useConditionBlockFields from './condition-block-fields';

export default function ConditionBlock({
  value,
  onChange,
}: {
  value: Rule;
  onChange: (value: Rule) => void;
}) {
  const { t } = useTranslation();

  const updateCondition = (condition: any, index: number) => {
    if (typeof index === 'number') {
      const newValue = [...(value.conditions || [])];
      newValue[index] = condition;
      onChange({ ...value, conditions: newValue });
    }
  };

  const [searchValue, setSearchValue] = useState<string>('');

  const { getConditionOptions, fieldList } = useConditionBlockFields();

  return (
    <Card styles={{ body: { padding: 14 } }}>
      <Flex gap="large" wrap>
        <Typography.Title level={5} style={{ margin: 0, whiteSpace: 'nowrap' }}>
          {t('Condition')}
        </Typography.Title>

        <Space direction="vertical">
          {value?.conditions?.map((condition, i) => {
            const conditionType =
              fieldList?.find((field) => field.value === condition.field)
                ?.type || '';
            return (
              <Flex align="center" wrap gap={10}>
                <Select
                  variant="filled"
                  value={condition.field}
                  popupMatchSelectWidth={400}
                  showSearch
                  onSearch={(e) => setSearchValue(e)}
                  style={{ width: '200px' }}
                  placeholder="Field"
                  optionFilterProp="children"
                  onChange={(e) =>
                    updateCondition(
                      {
                        ...condition,
                        field: e,
                        operator:
                          fieldList?.find((field) => field?.value === e)
                            ?.type === 'string'
                            ? 'contains'
                            : 'equals',
                      },
                      i,
                    )
                  }
                  filterOption={(input, option) =>
                    `${option?.search} ${option?.value}`
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  optionRender={(option) => (
                    <Flex vertical>
                      <Typography.Text>{option.label}</Typography.Text>
                      <Typography.Text ellipsis type="secondary">
                        {option.value}
                      </Typography.Text>
                    </Flex>
                  )}
                  options={[
                    ...((searchValue?.length || 0) > 0
                      ? [
                          {
                            search: searchValue,
                            value: searchValue,
                            label: searchValue,
                            type: 'string',
                          },
                        ]
                      : []),
                    ...(fieldList?.map((option) => ({
                      ...option,
                      search: option.label,
                    })) || []),
                  ]}
                />
                {['number', 'string', 'array', ''].includes(
                  conditionType?.toString(),
                ) ? (
                  <Select
                    popupMatchSelectWidth={false}
                    variant="filled"
                    value={condition.operator}
                    showSearch
                    onChange={(e) =>
                      updateCondition({ ...condition, operator: e }, i)
                    }
                    style={{ width: '120px' }}
                    placeholder="Operator"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? '')?.toString().includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '')
                        ?.toString()
                        .toLowerCase()
                        .localeCompare(
                          (optionB?.label ?? '')?.toString().toLowerCase(),
                        )
                    }
                    options={getConditionOptions(conditionType)}
                  />
                ) : (
                  <Select
                    variant="filled"
                    value={condition.operator}
                    showSearch
                    onChange={(e) =>
                      updateCondition({ ...condition, operator: e }, i)
                    }
                    style={{ width: '120px' }}
                    placeholder="Operator"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? '')?.toString().includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '')
                        ?.toString()
                        .toLowerCase()
                        .localeCompare(
                          (optionB?.label ?? '')?.toString().toLowerCase(),
                        )
                    }
                    options={getConditionOptions(conditionType)}
                  />
                )}

                {conditionType === 'number' ||
                (conditionType === 'array' &&
                  ['array_length_smaller', 'array_length_bigger'].includes(
                    condition?.operator || '',
                  )) ? (
                  <div style={{ width: '200px' }}>
                    <NumberInput
                      value={Number(condition.value)}
                      placeholder="Value"
                      onChange={(value) =>
                        updateCondition({ ...condition, value }, i)
                      }
                    />
                  </div>
                ) : conditionType === 'string' || conditionType === '' ? (
                  <div style={{ width: '200px' }}>
                    <DebouncedInput
                      value={condition.value}
                      placeholder="Value"
                      onChange={(value) =>
                        updateCondition({ ...condition, value }, i)
                      }
                    />
                  </div>
                ) : conditionType === 'array' ? (
                  <div style={{ width: '200px' }}>
                    <DebouncedInput
                      value={condition.value}
                      placeholder="Value"
                      onChange={(value) =>
                        updateCondition({ ...condition, value }, i)
                      }
                    />
                  </div>
                ) : conditionType === 'packageType' ? (
                  <PackageTypeSelect
                    value={{ code: condition.value }}
                    onChange={(e) =>
                      updateCondition({ ...condition, value: e?.code }, i)
                    }
                  />
                ) : conditionType === 'stakeholder' ? (
                  <div style={{ width: '200px' }}>
                    <StakeholderSelect
                      value={{ id: condition.value || '' }}
                      onChange={(e) =>
                        updateCondition({ ...condition, value: e?.id || '' }, i)
                      }
                    />
                  </div>
                ) : conditionType === 'customsOffice' ? (
                  <div style={{ width: '200px' }}>
                    <CustomsOfficeSelect
                      value={{
                        value: condition.value || '',
                        code: { value: condition.value || '' },
                      }}
                      onChange={(e) =>
                        updateCondition(
                          { ...condition, value: e?.value || '' },
                          i,
                        )
                      }
                    />
                  </div>
                ) : conditionType === 'incoterm' ? (
                  <IncotermSelect
                    value={{ value: condition.value as Incoterm }}
                    onChange={(e) =>
                      updateCondition(
                        { ...condition, value: e?.value || '' },
                        i,
                      )
                    }
                  />
                ) : conditionType === 'meanOfTransportationMode' ? (
                  <MeanOfTransportationModeSelect
                    value={{
                      value: condition.value as MeanOfTransportationMode,
                    }}
                    onChange={(e) =>
                      updateCondition(
                        { ...condition, value: e?.value || '' },
                        i,
                      )
                    }
                  />
                ) : conditionType === 'port' ? (
                  <PortSelect
                    value={{ value: condition.value }}
                    onChange={(e) =>
                      updateCondition(
                        { ...condition, value: e?.value || '' },
                        i,
                      )
                    }
                  />
                ) : conditionType === 'country' ? (
                  <CountrySelect
                    value={{ alpha2Code: condition?.value }}
                    onChange={(e) =>
                      updateCondition(
                        {
                          ...condition,
                          value: e?.alpha2Code || '',
                        },
                        i,
                      )
                    }
                  />
                ) : conditionType === 'boolean' ? (
                  <Select
                    variant="filled"
                    style={{ width: '200px' }}
                    value={condition?.value}
                    options={[
                      { value: true, label: 'Yes' },
                      { value: false, label: 'No' },
                    ]}
                    onChange={(e) =>
                      updateCondition(
                        {
                          ...condition,
                          value: e || false,
                        },
                        i,
                      )
                    }
                  />
                ) : null}

                <Button
                  type="link"
                  onClick={() => {
                    const updatedConditions =
                      value?.conditions?.filter(
                        (condition, index) => index !== i,
                      ) || [];
                    onChange({
                      ...value,
                      conditions: updatedConditions,
                    });
                  }}
                  icon={<DeleteOutlined />}
                />
              </Flex>
            );
          })}

          <Button
            type="link"
            icon={<PlusOutlined />}
            onClick={() =>
              onChange({
                ...(value || {}),
                conditions: [...(value?.conditions || []), {}],
              })
            }
          >
            {t('Add condition')}
          </Button>
        </Space>
      </Flex>
    </Card>
  );
}
