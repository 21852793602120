import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import App from './App';
import { store } from './Components/reduxToolkit/store';
import './index.css';

import {
  AuthenticationResult,
  EventMessage,
  EventType,
  PublicClientApplication,
} from '@azure/msal-browser';

import { BrowserRouter } from 'react-router-dom';
import { config } from './config';
import { ThemeProvider } from './utils/ThemeContext';

const root = createRoot(document.getElementById('root') as HTMLElement);

const msalInstance = new PublicClientApplication(config.msal);

async function initializeMSAL() {
  try {
    await msalInstance.initialize();
    await msalInstance.handleRedirectPromise();
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
    }
  } catch (error) {
    console.error('Error initializing MSAL:', error);
  }
}

initializeMSAL();

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    msalInstance.setActiveAccount(payload.account);
  }
});

root.render(
  <ThemeProvider>
    <Provider store={store}>
      <BrowserRouter>
        <App pca={msalInstance} />
      </BrowserRouter>
    </Provider>
  </ThemeProvider>,
);
