import {
  MinusCircleOutlined,
  MinusOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Flex,
  Modal,
  Popover,
  Row,
  Select,
  Space,
  TimePicker,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import {
  AggregatedCaseDataModel,
  BuyerSellerRelationship,
  BuyerSellerRelationshipMode,
  ConstellationOfParticipantsModel,
  DeclarationType,
  LineItemModel,
  Meta,
  Money,
  PersonModel,
  Procedure,
  ProcedureMode,
  RepresentativeConstellation,
  TariffSystem,
  TradePreference,
  TypeOfBusiness,
  TypeOfBusinessType,
  TypeOfExportDeclarationModel,
  XKSpecificLineItem,
} from 'digicust_types';
import { PaymentMethod } from 'digicust_types/lib/models/digicust/customs/payment-method.model';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import XKProcedureSelect from '../../../Components/Country-Specific/XK/ProcedureSelect';
import AmountOfMoneyInput from '../../../Components/Inputs/AmountOfMoneyInput';
import BuyerSellerRelationSelect from '../../../Components/Inputs/BuyerSellerRelationSelect';
import DebouncedInput from '../../../Components/Inputs/Common/DebouncedInput';
import ConstellationOfParticipantsSelect from '../../../Components/Inputs/ConstellationOfParticipantsSelect';
import DeclarationTypeSelect from '../../../Components/Inputs/DeclarationTypeSelect';
import PaymentMethodSelect from '../../../Components/Inputs/PaymentMethodSelect';
import ProcedureSelect from '../../../Components/Inputs/ProcedureSelect';
import TradePreferenceInput from '../../../Components/Inputs/TradePreferenceInput';
import TypeOfBusinessSelect from '../../../Components/Inputs/TypeOfBusinessSelect';
import TypeOfExportDeclarationSelect from '../../../Components/Inputs/TypeOfExportDeclarationSelect';
import { CaseError } from '../../../Components/reduxToolkit/caseSlice';
import { RootState } from '../../../Components/reduxToolkit/store';
import { useAppDataContext } from '../../AppDataProvider';
import { CaseFieldMapper } from '../types';

export const useGeneralDetailsFields = ({
  caseData,
  tariffSystem,
  updateItem,
  onLineItemsChange,
  errors,
}: {
  caseData?: AggregatedCaseDataModel;
  tariffSystem?: TariffSystem;
  updateItem: (item: AggregatedCaseDataModel) => void;
  onLineItemsChange?: (caseData: LineItemModel[]) => void;
  errors?: CaseError;
}) => {
  const { t } = useTranslation();

  const DeFields: CaseFieldMapper[] = [
    {
      title: `${t('Declaration Type')} DE`,
      getValue: () => caseData?.countrySpecific?.DE?.declarationType,
      updateProperty: (data: Meta<string>) => {
        updateItem({
          ...(caseData || {}),
          countrySpecific: {
            ...(caseData?.countrySpecific || {}),
            DE: {
              ...(caseData?.countrySpecific?.DE || {}),
              declarationType: data,
            },
          },
        });
      },
      clearProperty: () => {
        updateItem({
          ...(caseData || {}),
          countrySpecific: {
            ...(caseData?.countrySpecific || {}),
            DE: {
              ...(caseData?.countrySpecific?.DE || {}),
              declarationType: undefined,
            },
          },
        });
      },
      renderComponent: (
        value: Meta<string>,
        updateProperty: (data?: Meta<string>) => void,
      ) => (
        <DebouncedInput
          placeholder={t('EX, CO, T1, T2, ...')}
          value={value?.value}
          onChange={(val) => {
            updateProperty({ value: val });
          }}
        />
      ),
    },
    {
      title: `${t('Authorisation Number')} Export DE`,
      getValue: () => caseData?.countrySpecific?.DE?.export?.authorization,
      updateProperty: (
        data: {
          type?: Meta<string>;
          authorizationNumber?: Meta<string>;
        }[],
      ) => {
        updateItem({
          ...(caseData || {}),
          countrySpecific: {
            ...(caseData?.countrySpecific || {}),
            DE: {
              ...(caseData?.countrySpecific?.DE || {}),
              export: {
                ...(caseData?.countrySpecific?.DE?.export || {}),
                authorization: data,
              },
            },
          },
        });
      },
      clearProperty: () => {
        updateItem({
          ...(caseData || {}),
          countrySpecific: {
            ...(caseData?.countrySpecific || {}),
            DE: {
              ...(caseData?.countrySpecific?.DE || {}),
              export: {
                ...(caseData?.countrySpecific?.DE?.export || {}),
                authorization: [],
              },
            },
          },
        });
      },
      renderComponent: (
        value: {
          type?: Meta<string>;
          authorizationNumber?: Meta<string>;
        }[],
        updateProperty: (
          data?: {
            type?: Meta<string>;
            authorizationNumber?: Meta<string>;
          }[],
        ) => void,
      ) => {
        // Handler to add a new authorization entry
        const handleAddAuthorization = () => {
          const newAuthorization = {
            type: { value: '' },
            authorizationNumber: { value: '' },
          };
          const updatedAuthorizations = [...(value || []), newAuthorization];
          updateProperty(updatedAuthorizations);
        };

        // Handler to remove an authorization entry
        const handleRemoveAuthorization = (index: number) => {
          const updatedAuthorizations = value.filter((_, i) => i !== index);
          updateProperty(updatedAuthorizations);
        };

        // Handler to update the type of an authorization
        const handleTypeChange = (index: number, newType: string) => {
          const updatedAuthorizations = value.map((e, i) =>
            i === index
              ? { ...e, type: { value: newType, input: newType } }
              : e,
          );
          updateProperty(updatedAuthorizations);
        };

        // Handler to update the authorization number
        const handleNumberChange = (index: number, newNumber: string) => {
          const updatedAuthorizations = value.map((e, i) =>
            i === index
              ? {
                  ...e,
                  authorizationNumber: {
                    value: newNumber,
                    input: newNumber,
                  },
                }
              : e,
          );
          updateProperty(updatedAuthorizations);
        };

        return (
          <div>
            {value?.length ? (
              value?.map?.((auth, index) => (
                <Row wrap={false}>
                  <Col flex="none">
                    <Select
                      variant="filled"
                      value={auth?.type?.value || undefined}
                      onChange={(value) => handleTypeChange(index, value)}
                      placeholder={t('Select Type')}
                      style={{ width: 100 }}
                      popupMatchSelectWidth={false}
                      showSearch
                      optionFilterProp="children"
                    >
                      {[
                        { code: 'C019', description: 'Passive Veredelung' },
                        {
                          code: 'C512',
                          description: 'Vereinfachtes Verfahren',
                        },
                        {
                          code: 'C513',
                          description: 'Zentrale Zollabwicklung',
                        },
                        {
                          code: 'C514',
                          description:
                            'Anschreibung in der Buchführung des Anmelders',
                        },
                        { code: 'C517', description: 'Privates Zolllager' },
                        {
                          code: 'C518',
                          description: 'Öffentliches Zolllager (Typ I)',
                        },
                        {
                          code: 'C519',
                          description: 'Öffentliches Zolllager (Typ II)',
                        },
                      ].map((item) => (
                        <Select.Option key={item.code} value={item.code}>
                          {item.code} - {item.description}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                  <Col flex="auto">
                    <DebouncedInput
                      placeholder={t('Authorization Number')}
                      value={auth?.authorizationNumber?.value}
                      onChange={(e) => handleNumberChange(index, e || '')}
                    />
                  </Col>
                  <Col flex="none">
                    <Button
                      type="text"
                      icon={<MinusCircleOutlined />}
                      onClick={() => handleRemoveAuthorization(index)}
                    />
                  </Col>
                  {index === (value?.length ? value?.length - 1 : 0) && (
                    <Col flex="none">
                      <Button
                        type="text"
                        onClick={handleAddAuthorization}
                        icon={<PlusOutlined />}
                        title={t('Add Authorization')}
                      ></Button>
                    </Col>
                  )}
                </Row>
              ))
            ) : (
              <Button type="link" onClick={handleAddAuthorization}>
                {t('Add Authorization')}
              </Button>
            )}
          </div>
        );
      },
    },
    {
      title: `${t('Additional Information')} Export DE`,
      getValue: () =>
        caseData?.countrySpecific?.DE?.export?.additionalInformation,
      updateProperty: (
        data: {
          code?: Meta<string>;
          text?: Meta<string>;
        }[],
      ) => {
        updateItem({
          ...(caseData || {}),
          countrySpecific: {
            ...(caseData?.countrySpecific || {}),
            DE: {
              ...(caseData?.countrySpecific?.DE || {}),
              export: {
                ...(caseData?.countrySpecific?.DE?.export || {}),
                additionalInformation: data,
              },
            },
          },
        });
      },
      clearProperty: () => {
        updateItem({
          ...(caseData || {}),
          countrySpecific: {
            ...(caseData?.countrySpecific || {}),
            DE: {
              ...(caseData?.countrySpecific?.DE || {}),
              export: {
                ...(caseData?.countrySpecific?.DE?.export || {}),
                additionalInformation: [],
              },
            },
          },
        });
      },
      renderComponent: (
        value: {
          code?: Meta<string>;
          text?: Meta<string>;
        }[],
        updateProperty: (
          data?: {
            code?: Meta<string>;
            text?: Meta<string>;
          }[],
        ) => void,
      ) => {
        // Handler to add a new additional information entry
        const handleAddAdditionalInfo = () => {
          const newAdditionalInfo = {
            code: { value: '' },
            text: { value: '' },
          };
          const updatedAdditionalInfo = [...(value || []), newAdditionalInfo];
          updateProperty(updatedAdditionalInfo);
        };

        // Handler to remove an additional information entry
        const handleRemoveAdditionalInfo = (index: number) => {
          const updatedAdditionalInfo = value.filter((_, i) => i !== index);
          updateProperty(updatedAdditionalInfo);
        };

        // Handler to update the code of an additional information entry
        const handleCodeChange = (index: number, newCode: string) => {
          const updatedAdditionalInfo = value.map((e, i) =>
            i === index
              ? { ...e, code: { value: newCode, input: newCode } }
              : e,
          );
          updateProperty(updatedAdditionalInfo);
        };

        // Handler to update the text of an additional information entry
        const handleTextChange = (index: number, newText: string) => {
          const updatedAdditionalInfo = value.map((e, i) =>
            i === index
              ? {
                  ...e,
                  text: {
                    value: newText,
                    input: newText,
                  },
                }
              : e,
          );
          updateProperty(updatedAdditionalInfo);
        };

        return (
          <Space direction="vertical" style={{ width: '100%' }}>
            {value?.length ? (
              value?.map?.((info, index) => (
                <Flex align="center" gap={5} style={{ width: '100%' }}>
                  <Space.Compact style={{ flex: 1 }}>
                    <Select
                      variant="filled"
                      value={info?.code?.value || undefined}
                      onChange={(value) => handleCodeChange(index, value)}
                      placeholder={t('Select Code')}
                      style={{ width: 200 }}
                      popupMatchSelectWidth={false}
                      showSearch
                      optionFilterProp="children"
                    >
                      {[
                        { code: '01000', description: 'Diplomatengut' },
                        {
                          code: 'X0000',
                          description: 'Mitteilung an die Ausfuhrzollstelle',
                        },
                        {
                          code: 'X0001',
                          description:
                            'Empfänger eines Nachforschungsersuchens ist der Anmelder',
                        },
                        {
                          code: 'X0002',
                          description: 'Keine Bereitstellung des INF2',
                        },
                        {
                          code: 'X0003',
                          description:
                            'Keine statistische Meldung zur nachträglichen Ausfuhranmeldung aus dem Notfallverfahren',
                        },
                        {
                          code: 'X0004',
                          description:
                            'Keine Bedarfslieferung in einen anderen Mitgliedstaat',
                        },
                        {
                          code: 'X0005',
                          description:
                            'Zusätzlicher Ausgangsvermerk an den Ausführer',
                        },
                      ].map((item) => (
                        <Select.Option key={item.code} value={item.code}>
                          {item.code} - {item.description}
                        </Select.Option>
                      ))}
                    </Select>

                    <DebouncedInput
                      placeholder={t('Text')}
                      value={info?.text?.value}
                      onChange={(e) => handleTextChange(index, e || '')}
                    />
                  </Space.Compact>

                  <Space.Compact>
                    <Button
                      type="text"
                      size="small"
                      title={t('remove this information')}
                      icon={<MinusOutlined />}
                      onClick={() => handleRemoveAdditionalInfo(index)}
                    />

                    {index === (value?.length ? value?.length - 1 : 0) && (
                      <Button
                        type="text"
                        size="small"
                        onClick={handleAddAdditionalInfo}
                        icon={<PlusOutlined />}
                        title={t('Add Additional Information')}
                      ></Button>
                    )}
                  </Space.Compact>
                </Flex>
              ))
            ) : (
              <Button type="link" onClick={handleAddAdditionalInfo}>
                {t('Add Additional Information')}
              </Button>
            )}
          </Space>
        );
      },
    },
    {
      title: `${t('ABD Email Address')} DE`,
      getValue: () => caseData?.countrySpecific?.DE?.ABDEmailAddress,
      updateProperty: (data: Meta<string>) => {
        updateItem({
          ...(caseData || {}),
          countrySpecific: {
            ...(caseData?.countrySpecific || {}),
            DE: {
              ...(caseData?.countrySpecific?.DE || {}),
              ABDEmailAddress: data,
            },
          },
        });
      },
      clearProperty: () => {
        updateItem({
          ...(caseData || {}),
          countrySpecific: {
            ...(caseData?.countrySpecific || {}),
            DE: {
              ...(caseData?.countrySpecific?.DE || {}),
              ABDEmailAddress: undefined,
            },
          },
        });
      },
      renderComponent: (
        value: Meta<string>,
        updateProperty: (data?: Meta<string>) => void,
      ) => (
        <DebouncedInput
          placeholder={t('max@mustermann.de')}
          value={value?.value}
          onChange={(val) => {
            updateProperty({ value: val });
          }}
        />
      ),
    },
    {
      title: `${t('AVM Email Address')} DE`,
      getValue: () => caseData?.countrySpecific?.DE?.AVMEmailAddress,
      updateProperty: (data: Meta<string>) => {
        updateItem({
          ...(caseData || {}),
          countrySpecific: {
            ...(caseData?.countrySpecific || {}),
            DE: {
              ...(caseData?.countrySpecific?.DE || {}),
              AVMEmailAddress: data,
            },
          },
        });
      },
      clearProperty: () => {
        updateItem({
          ...(caseData || {}),
          countrySpecific: {
            ...(caseData?.countrySpecific || {}),
            DE: {
              ...(caseData?.countrySpecific?.DE || {}),
              AVMEmailAddress: undefined,
            },
          },
        });
      },
      suggestions: [
        {
          title: t('Whoever uploads'),
          value: {
            value:
              '{{$contains(createdBy.displayName,"@")?createdBy.displayName:documents[documentType="email"].from}}',
            input:
              '{{$contains(createdBy.displayName,"@")?createdBy.displayName:documents[documentType="email"].from}}',
          },
        },
      ],
      renderComponent: (
        value: Meta<string>,
        updateProperty: (data?: Meta<string>) => void,
      ) => (
        <DebouncedInput
          placeholder={t('max@mustermann.de')}
          value={value?.value}
          onChange={(val) => {
            updateProperty({ value: val });
          }}
        />
      ),
    },
    {
      title: `${t('Transit Declaration Type')} DE`,
      getValue: () => caseData?.countrySpecific?.DE?.transitDeclarationType,
      updateProperty: (data: Meta<string>) => {
        updateItem({
          ...(caseData || {}),
          countrySpecific: {
            ...(caseData?.countrySpecific || {}),
            DE: {
              ...(caseData?.countrySpecific?.DE || {}),
              transitDeclarationType: data,
            },
          },
        });
      },
      clearProperty: () => {
        updateItem({
          ...(caseData || {}),
          countrySpecific: {
            ...(caseData?.countrySpecific || {}),
            DE: {
              ...(caseData?.countrySpecific?.DE || {}),
              transitDeclarationType: undefined,
            },
          },
        });
      },
      renderComponent: (
        value: Meta<string>,
        updateProperty: (data?: Meta<string>) => void,
      ) => (
        <DebouncedInput
          placeholder={t('e.g. 11, 10')}
          value={value?.value}
          onChange={(val) => {
            updateProperty({ value: val });
          }}
        />
      ),
    },
  ];

  const fields: CaseFieldMapper[] = [
    tariffSystem === 'XK'
      ? {
          title: `${t('Procedure')} XK`,
          getValue: () => caseData?.countrySpecific?.XK as XKSpecificLineItem,
          updateProperty: (data: XKSpecificLineItem) => {
            updateItem({
              ...(caseData || {}),
              countrySpecific: {
                ...(caseData?.countrySpecific || {}),
                XK: {
                  ...(caseData?.countrySpecific?.XK || {}),
                  ...data,
                },
              },
            });
            Modal.confirm({
              title: t(
                'Do you want to apply this procedure to all line items?',
              ),
              cancelText: t('NO'),
              okText: t('YES'),
              onOk() {
                console.log('OK');
                if (caseData) {
                  onLineItemsChange &&
                    onLineItemsChange(
                      caseData?.items?.map?.((item) => {
                        const newItem = {
                          ...item,
                          countrySpecific: {
                            ...(item?.countrySpecific || {}),
                            XK: {
                              ...(item?.countrySpecific?.XK || {}),
                              ...data,
                            },
                          },
                        };
                        return newItem;
                      }) || [],
                    );
                }
              },
            });
          },
          clearProperty: () => {
            updateItem({
              ...(caseData || {}),
              countrySpecific: {
                ...(caseData?.countrySpecific || {}),
                XK: undefined,
              },
            });
          },
          renderComponent: (
            value: XKSpecificLineItem,
            updateProperty: (data?: XKSpecificLineItem) => void,
          ) => (
            <XKProcedureSelect
              value={value}
              onChange={(procedure) => {
                updateProperty(procedure);
              }}
            />
          ),
        }
      : {
          title: t('Procedure'),
          getValue: () => caseData?.procedure,
          updateProperty: (data: Procedure) => {
            updateItem({
              ...(caseData || {}),
              procedure: data.input || data.value ? data : undefined,
            });
          },
          clearProperty: () => {
            updateItem({
              ...(caseData || {}),
              procedure: undefined,
            });
          },
          renderComponent: (
            value: Procedure,
            updateProperty: (data?: Procedure) => void,
          ) => (
            <ProcedureSelect
              value={value}
              onChange={(procedure) => {
                updateProperty(procedure);
              }}
            />
          ),
          suggestions: [
            {
              title: t('Import'),
              value: {
                input: '4000',
                requestedProcedure: '40',
                previousProcedure: '00',
                value: ProcedureMode.import,
                procedureCodeCombination: '4000',
              },
            },
            {
              title: t('Export'),
              value: {
                input: '1000',
                requestedProcedure: '10',
                previousProcedure: '00',
                value: ProcedureMode.export,
                procedureCodeCombination: '1000',
              },
            },
          ],
        },
    {
      title: t('Declaration Type'),
      getValue: () => caseData?.declarationType,
      updateProperty: (data: Meta<DeclarationType>) => {
        updateItem({ ...(caseData || {}), declarationType: data });
      },
      clearProperty: () => {
        updateItem({ ...(caseData || {}), declarationType: undefined });
      },
      renderComponent: (
        value: Meta<DeclarationType>,
        updateProperty: (data?: Meta<DeclarationType>) => void,
      ) => (
        <DeclarationTypeSelect
          value={value}
          onChange={(declarationType) => {
            updateProperty(declarationType);
          }}
        />
      ),
      suggestions: [
        {
          title: t('Pre-Declaration'),
          value: {
            value: DeclarationType.preDeclaration,
            input: DeclarationType.preDeclaration,
          },
        },
        {
          title: t('Declaration'),
          value: {
            value: DeclarationType.declaration,
            input: DeclarationType.declaration,
          },
        },
      ],
    },
    ...(tariffSystem === 'DE' ? DeFields : []),
    {
      title: t('Responsible Person'),
      getValue: () => caseData?.responsiblePerson,
      updateProperty: (data: PersonModel) => {
        updateItem({ ...(caseData || {}), responsiblePerson: data });
      },
      clearProperty: () => {
        updateItem({ ...(caseData || {}), responsiblePerson: undefined });
      },
      renderComponent: (
        value: PersonModel,
        updateProperty: (data?: PersonModel) => void,
      ) => (
        <DebouncedInput
          placeholder={t('Responsible Person')}
          value={value?.value}
          onChange={(val) => {
            updateProperty({ value: val });
          }}
        />
      ),
    },
    {
      title: t('Export Declaration Type (Export only)'),
      getValue: () => caseData?.exportDeclarationType,
      updateProperty: (data: TypeOfExportDeclarationModel) => {
        updateItem({ ...(caseData || {}), exportDeclarationType: data });
      },
      clearProperty: () => {
        updateItem({ ...(caseData || {}), exportDeclarationType: undefined });
      },
      renderComponent: (
        value: TypeOfExportDeclarationModel,
        updateProperty: (data?: TypeOfExportDeclarationModel) => void,
      ) => (
        <TypeOfExportDeclarationSelect
          value={value}
          onChange={(exportDeclarationType) => {
            updateProperty(exportDeclarationType);
          }}
        />
      ),
      suggestions: [
        {
          title: t('Default Declaration'),
          value: {
            input: '00000100',
            value: '00000100',
            DE_A0121: '00000100',
            label: t(
              'Standard export declaration for the two-step normal procedure.',
            ),
          },
        },
      ],
    },

    {
      title: t('Representation'),
      getValue: () => caseData?.constellationOfParticipants,
      updateProperty: (data: ConstellationOfParticipantsModel) => {
        updateItem({ ...(caseData || {}), constellationOfParticipants: data });
      },
      clearProperty: () => {
        updateItem({
          ...(caseData || {}),
          constellationOfParticipants: undefined,
        });
      },
      renderComponent: (
        value: ConstellationOfParticipantsModel,
        updateProperty: (data?: ConstellationOfParticipantsModel) => void,
      ) => (
        <ConstellationOfParticipantsSelect
          value={value}
          onChange={(constellationOfParticipants) => {
            updateProperty(constellationOfParticipants);
          }}
        />
      ),
      suggestions: [
        {
          title: t('None'),
          value: {
            DE_A0127: '0000',
            DE_A1770: '0',
            input: RepresentativeConstellation.None,
            value: RepresentativeConstellation.None,
          },
        },
        {
          title: t('Direct'),
          value: {
            DE_A0127: '0010',
            DE_A1770: '1',
            input: RepresentativeConstellation.Direct,
            value: RepresentativeConstellation.Direct,
          },
        },
        {
          title: t('Indirect'),
          value: {
            DE_A0127: '1000',
            DE_A1770: '2',
            input: RepresentativeConstellation.Indirect,
            value: RepresentativeConstellation.Indirect,
          },
        },
      ],
    },
    {
      title: t('Buyer-Seller Relationship'),
      getValue: () => caseData?.buyerSellerRelation,
      updateProperty: (data: BuyerSellerRelationship) => {
        updateItem({ ...(caseData || {}), buyerSellerRelation: data });
      },
      clearProperty: () => {
        updateItem({ ...(caseData || {}), buyerSellerRelation: undefined });
      },
      renderComponent: (
        value: BuyerSellerRelationship,
        updateProperty: (data?: BuyerSellerRelationship) => void,
      ) => (
        <BuyerSellerRelationSelect
          value={value}
          onChange={(buyerSellerRelation) => {
            updateProperty(buyerSellerRelation);
          }}
        />
      ),
      suggestions: [
        {
          title: t('Not connected'),
          value: {
            DE_A1760: '0',
            value: BuyerSellerRelationshipMode.NotConnected,
            input: BuyerSellerRelationshipMode.NotConnected,
          },
        },
      ],
    },
    {
      title: t('Type of Business'),
      getValue: () => caseData?.typeofBusiness,
      updateProperty: (data: TypeOfBusiness) => {
        updateItem({ ...(caseData || {}), typeofBusiness: data });
      },
      clearProperty: () => {
        updateItem({ ...(caseData || {}), typeofBusiness: undefined });
      },
      renderComponent: (
        value: TypeOfBusiness,
        updateProperty: (data?: TypeOfBusiness) => void,
      ) => (
        <TypeOfBusinessSelect
          value={value}
          onChange={(typeofBusiness) => {
            updateProperty(typeofBusiness);
          }}
        />
      ),
      suggestions: [
        {
          title: t('Purchase'),
          value: {
            value: TypeOfBusinessType.Purchase,
            input: TypeOfBusinessType.Purchase,
            NC_25000: '11',
            DE_A1150: '11',
          },
        },
      ],
    },
    {
      title: t('Trade Preference'),
      getValue: () => caseData?.tradePreference,
      updateProperty: (data: TradePreference) => {
        updateItem({ ...(caseData || {}), tradePreference: data });
      },
      clearProperty: () => {
        updateItem({ ...(caseData || {}), tradePreference: undefined });
      },
      renderComponent: (
        value: TradePreference,
        updateProperty: (data?: TradePreference) => void,
      ) => (
        <TradePreferenceInput
          preference={value}
          onChange={(tradePreference) => {
            updateProperty(tradePreference);
          }}
        />
      ),
      suggestions: [
        {
          title: t('Valid'),
          value: {
            valid: true,
          },
        },
        {
          title: t('Invalid'),
          value: {
            valid: false,
          },
        },
      ],
    },
    {
      title: t('Additional Information'),
      getValue: () => caseData?.additionalInformation,
      updateProperty: (data: Meta<string>) => {
        updateItem({ ...(caseData || {}), additionalInformation: data });
      },
      clearProperty: () => {
        updateItem({ ...(caseData || {}), additionalInformation: undefined });
      },
      renderComponent: (
        value: Meta<string>,
        updateProperty: (data?: Meta<string>) => void,
      ) => (
        <DebouncedInput
          placeholder={t('Additional Information')}
          value={value?.value}
          onChange={(person) => {
            updateProperty({ value: person });
          }}
        />
      ),
    },
    {
      title: t('Import Sales Tax'),
      getValue: () => caseData?.importSalesTax,
      updateProperty: (data: Money) => {
        updateItem({ ...(caseData || {}), importSalesTax: data });
      },
      clearProperty: () => {
        updateItem({ ...(caseData || {}), importSalesTax: undefined });
      },
      renderComponent: (
        value: Money,
        updateProperty: (data?: Money) => void,
      ) => (
        <AmountOfMoneyInput
          keyValue={'importSalesTax'}
          warningValue={errors?.actual}
          value={value}
          onChange={(importSalesTax) => {
            updateProperty(importSalesTax);
          }}
        />
      ),
    },
    {
      title: t('Total Value'),
      getValue: () => caseData?.totalValue,
      updateProperty: (data: Money) => {
        updateItem({ ...(caseData || {}), totalValue: data });
      },
      clearProperty: () => {
        updateItem({ ...(caseData || {}), totalValue: undefined });
      },
      renderComponent: (
        value: Money,
        updateProperty: (data?: Money) => void,
      ) => (
        <AmountOfMoneyInput
          keyValue={'totalValue'}
          warningValue={errors?.actual}
          value={value}
          onChange={(totalValue) => {
            updateProperty(totalValue);
          }}
        />
      ),
    },
    {
      title: t('Statistical Value'),
      getValue: () => caseData?.statisticalValue,
      updateProperty: (data: Money) => {
        updateItem({ ...(caseData || {}), statisticalValue: data });
      },
      clearProperty: () => {
        updateItem({ ...(caseData || {}), statisticalValue: undefined });
      },
      renderComponent: (
        value: Money,
        updateProperty: (data?: Money) => void,
      ) => (
        <AmountOfMoneyInput
          keyValue={'statisticalValue'}
          warningValue={errors?.actual}
          value={value}
          onChange={(statisticalValue) => {
            updateProperty(statisticalValue);
          }}
        />
      ),
    },
    {
      title: t('Guarantee Value'),
      getValue: () => caseData?.guaranteeValue,
      updateProperty: (data: Money) => {
        updateItem({ ...(caseData || {}), guaranteeValue: data });
      },
      clearProperty: () => {
        updateItem({ ...(caseData || {}), guaranteeValue: undefined });
      },
      renderComponent: (
        value: Money,
        updateProperty: (data?: Money) => void,
      ) => (
        <AmountOfMoneyInput
          keyValue={'guaranteeValue'}
          warningValue={errors?.actual}
          value={value}
          onChange={(guaranteeValue) => {
            updateProperty(guaranteeValue);
          }}
        />
      ),
    },
    {
      title: t('Payment Method'),
      getValue: () => caseData?.paymentMethod,
      updateProperty: (data: PaymentMethod) => {
        updateItem({ ...(caseData || {}), paymentMethod: data });
      },
      clearProperty: () => {
        updateItem({ ...(caseData || {}), paymentMethod: undefined });
      },
      renderComponent: (
        value: PaymentMethod,
        updateProperty: (data?: PaymentMethod) => void,
      ) => (
        <PaymentMethodSelect
          value={value}
          onChange={(paymentMethod) => {
            updateProperty(paymentMethod);
          }}
        />
      ),
      suggestions: [
        {
          title: t('Electronic payment'),
          value: {
            value: 'H',
            label: 'Electronic payment',
            C0116: 'H',
          },
        },
        {
          title: t('Cash'),
          value: {
            value: 'A',
            label: 'Cash payment',
            C0116: 'A',
          },
        },
      ],
    },
    {
      title: 'GRN',
      getValue: () => caseData?.GRN,
      updateProperty: (data: Meta<string>) => {
        updateItem({ ...(caseData || {}), GRN: data });
      },
      clearProperty: () => {
        updateItem({ ...(caseData || {}), GRN: undefined });
      },
      renderComponent: (
        value: Meta<string>,
        updateProperty: (data?: Meta<string>) => void,
      ) => (
        <DebouncedInput
          placeholder="GRN"
          value={value?.value}
          onChange={(e) => {
            updateProperty({ value: e });
          }}
        />
      ),
    },
    // TODO @kg34 @thomas
    // {
    //   title: t('GRN Access Code'),
    //   getValue: () => caseData?.GRNAccessCode,
    //   updateProperty: (data: Meta<string>) => {
    //     updateItem({ ...(caseData || {}), GRNAccessCode: data });
    //   },
    //   clearProperty: () => {
    //     updateItem({ ...(caseData || {}), GRNAccessCode: undefined });
    //   },
    //   renderComponent: (
    //     value: Meta<string>,
    //     updateProperty: (data?: Meta<string>) => void,
    //   ) => (
    //     <DebouncedInput
    //       placeholder={t('GRN Access Code')}
    //       value={value?.value}
    //       onChange={(val) => {
    //         updateProperty({ value: val });
    //       }}
    //     />
    //   ),
    // },
    {
      title: 'LRN',
      getValue: () => caseData?.LRN,
      updateProperty: (data: Meta<string>) => {
        updateItem({ ...(caseData || {}), LRN: data });
      },
      clearProperty: () => {
        updateItem({ ...(caseData || {}), LRN: undefined });
      },
      renderComponent: (
        value: Meta<string>,
        updateProperty: (data?: Meta<string>) => void,
      ) => (
        <DebouncedInput
          placeholder="LRN"
          value={value?.value}
          onChange={(LRN) => {
            updateProperty({ value: LRN });
          }}
        />
      ),
    },
    {
      title: t('Reference number UCR'),
      getValue: () => caseData?.referenceNumberUCR,
      updateProperty: (data: Meta<string>) => {
        updateItem({ ...(caseData || {}), referenceNumberUCR: data });
      },
      clearProperty: () => {
        updateItem({ ...(caseData || {}), referenceNumberUCR: undefined });
      },
      renderComponent: (
        value: Meta<string>,
        updateProperty: (data?: Meta<string>) => void,
      ) => (
        <DebouncedInput
          placeholder={t('Reference number UCR')}
          value={value?.value}
          onChange={(val) => {
            updateProperty({ value: val });
          }}
        />
      ),
    },
    {
      title: t('Registration number external'),
      getValue: () => caseData?.registrationNumberExternal,
      updateProperty: (data: Meta<string>) => {
        updateItem({ ...(caseData || {}), registrationNumberExternal: data });
      },
      clearProperty: () => {
        updateItem({
          ...(caseData || {}),
          registrationNumberExternal: undefined,
        });
      },
      renderComponent: (
        value: Meta<string>,
        updateProperty: (data?: Meta<string>) => void,
      ) => (
        <DebouncedInput
          placeholder={t('Registration number external')}
          value={value?.value}
          onChange={(val) => {
            updateProperty({ value: val });
          }}
        />
      ),
    },
    {
      title: t('Presentation Date'),
      getValue: () =>
        caseData?.presentationDate
          ? {
              presentationDate: caseData?.presentationDate,
              presentationTimeStart: caseData?.presentationTimeStart,
              presentationTimeEnd: caseData?.presentationTimeEnd,
            }
          : null,
      updateProperty: (data: {
        presentationDate?: Meta<string>;
        presentationTimeStart?: Meta<string>;
        presentationTimeEnd?: Meta<string>;
      }) => {
        updateItem({
          ...(caseData || {}),
          presentationDate: data?.presentationDate,
          presentationTimeStart: data?.presentationTimeStart,
          presentationTimeEnd: data?.presentationTimeEnd,
        });
      },
      clearProperty: () => {
        updateItem({
          ...(caseData || {}),
          presentationDate: undefined,
          presentationTimeStart: undefined,
          presentationTimeEnd: undefined,
        });
      },
      renderComponent: (
        value: {
          presentationDate?: Meta<string>;
          presentationTimeStart?: Meta<string>;
          presentationTimeEnd?: Meta<string>;
        },
        updateProperty: (data?: {
          presentationDate?: Meta<string>;
          presentationTimeStart?: Meta<string>;
          presentationTimeEnd?: Meta<string>;
        }) => void,
      ) => (
        <>
          <Space.Compact style={{ width: '100%' }}>
            <DatePicker
              variant="filled"
              value={
                caseData?.presentationDate?.value
                  ? dayjs(caseData?.presentationDate?.value)
                  : null
              }
              onChange={(val) => {
                updateProperty({
                  ...(caseData || {}),
                  presentationDate: {
                    value: val?.format('YYYY-MM-DD'),
                    input: val?.format('YYYY-MM-DD'),
                  },
                });
              }}
            />

            <TimePicker.RangePicker
              variant="filled"
              value={[
                caseData?.presentationTimeStart?.value
                  ? dayjs(caseData?.presentationTimeStart?.value, 'HH:mm:ss')
                  : null,
                caseData?.presentationTimeEnd?.value
                  ? dayjs(caseData?.presentationTimeEnd?.value, 'HH:mm:ss')
                  : null,
              ]}
              onChange={(val) => {
                updateProperty({
                  ...(caseData || {}),
                  presentationTimeStart: {
                    input: val?.[0]?.format?.('HH:mm:ss'),
                    value: val?.[0]?.format?.('HH:mm:ss'),
                  },
                  presentationTimeEnd: {
                    input: val?.[1]?.format?.('HH:mm:ss'),
                    value: val?.[1]?.format?.('HH:mm:ss'),
                  },
                });
              }}
            />
          </Space.Compact>
        </>
      ),
    },
  ];

  return { fields };
};

const GeneralDetailsInput = ({
  caseData,
  onChange,
  onLineItemsChange,
}: {
  caseData?: AggregatedCaseDataModel;
  onChange: (caseData: AggregatedCaseDataModel) => void;
  onLineItemsChange: (caseData: LineItemModel[]) => void;
}) => {
  const updateItem = (item: AggregatedCaseDataModel) => {
    onChange(item);
  };

  const { projectDetails } = useAppDataContext();

  const storData = useSelector((state: RootState) => state?.caseSlices);
  const errors = storData?.errors;

  const { fields } = useGeneralDetailsFields({
    caseData,
    updateItem,
    onLineItemsChange,
    tariffSystem: projectDetails?.tariffNumberTreeSystem,
    errors,
  });

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      {fields.map((field) => (
        <Flex justify="space-between" align="center" gap={5}>
          <Typography.Paragraph>
            <Popover placement="right" content={field.help}>
              {field.title}
            </Popover>{' '}
            <Popover
              placement="right"
              content={
                <ul>
                  {Object.entries(field?.getValue?.() || {})
                    ?.filter(
                      ([key]) =>
                        ![
                          'errors',
                          'confidence',
                          'options',
                          'bbox',
                          'modifiedAt',
                        ].includes(key),
                    )
                    ?.map(([key, value]) => (
                      <li>
                        {key}: {value?.toString?.()}
                      </li>
                    ))}
                </ul>
              }
            >
              <QuestionCircleOutlined style={{ margin: '4px' }} />
            </Popover>
          </Typography.Paragraph>

          <div style={{ flex: 1, textAlign: 'end' }}>
            {!field.getValue() && (
              <Space.Compact direction="horizontal" style={{ height: '100%' }}>
                {field.suggestions?.map((suggestion) => (
                  <Button
                    type="dashed"
                    size="small"
                    tabIndex={-1}
                    onClick={() => {
                      field.updateProperty(suggestion?.value);
                    }}
                  >
                    {suggestion?.title}
                  </Button>
                ))}
              </Space.Compact>
            )}
          </div>
          <div style={{ width: '40%' }}>
            {field.renderComponent(field.getValue(), field.updateProperty)}
          </div>
        </Flex>
      ))}
    </Space>
  );
};

export default GeneralDetailsInput;
