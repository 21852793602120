import { Checkbox } from 'antd';
import { ExecutionStrategy } from 'digicust_types';
import { useTranslation } from 'react-i18next';
import { DCTooltip } from '../../../../../common';
import { CaseFieldMapper } from '../../../../CustomsCaseDetails/types';
import FromCaseFieldMapper from './from-case-field-mapper';

interface IProps {
  execStrategy: ExecutionStrategy;
  onValueChange: (val: ExecutionStrategy) => void;
}

export function useStakeholderSettingsFields(props: IProps) {
  const { execStrategy, onValueChange } = props;
  const { t } = useTranslation();
  const fields: CaseFieldMapper[] = [
    {
      title: t('Create stakeholder master data'),
      help: t(
        'Enable creation of stakeholder master data that does not exist yet',
      ),
      isCheckbox: true,
      getValue: () =>
        execStrategy.dataIntegration?.masterDataIntegrationAutomation
          ?.createStakeholderMasterData,
      updateProperty: (checked) => {
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...(execStrategy.dataIntegration || {}),
            masterDataIntegrationAutomation: {
              ...(execStrategy?.dataIntegration
                ?.masterDataIntegrationAutomation || {}),
              createStakeholderMasterData: checked,
            },
          },
        });
      },
      clearProperty: () => {
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...(execStrategy.dataIntegration || {}),
            masterDataIntegrationAutomation: {
              ...(execStrategy?.dataIntegration
                ?.masterDataIntegrationAutomation || {}),
              createStakeholderMasterData: undefined,
            },
          },
        });
      },
      renderComponent: (value, updateProperty, title, help) => {
        return (
          <Checkbox
            className="hoverable"
            checked={value}
            onChange={(e) => updateProperty(e.target.checked)}
          >
            {title}
            {help && <DCTooltip text={help} />}
          </Checkbox>
        );
      },
    },
    {
      title: t('Update automatically created stakeholders'),
      help: t('Enable updates of fields in automatically created stakeholders'),
      isCheckbox: true,
      getValue: () =>
        execStrategy.dataIntegration?.masterDataIntegrationAutomation
          ?.updateAutomaticallyCreatedStakeholders,
      updateProperty: (checked) => {
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...(execStrategy.dataIntegration || {}),
            masterDataIntegrationAutomation: {
              ...(execStrategy?.dataIntegration
                ?.masterDataIntegrationAutomation || {}),
              updateAutomaticallyCreatedStakeholders: checked,
            },
          },
        });
      },
      clearProperty: () => {
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...(execStrategy.dataIntegration || {}),
            masterDataIntegrationAutomation: {
              ...(execStrategy?.dataIntegration
                ?.masterDataIntegrationAutomation || {}),
              updateAutomaticallyCreatedStakeholders: undefined,
            },
          },
        });
      },
      renderComponent: (value, updateProperty, title, help) => {
        return (
          <Checkbox
            className="hoverable"
            checked={value}
            onChange={(e) => updateProperty(e.target.checked)}
          >
            {title}
            {help && <DCTooltip text={help} />}
          </Checkbox>
        );
      },
    },
    {
      title: t('Update manually created stakeholders'),
      help: t('Enable updates of fields in manually created stakeholders'),
      isCheckbox: true,
      getValue: () =>
        execStrategy.dataIntegration?.masterDataIntegrationAutomation
          ?.updateManuallyCreatedStakeholders,
      updateProperty: (checked) => {
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...(execStrategy.dataIntegration || {}),
            masterDataIntegrationAutomation: {
              ...(execStrategy?.dataIntegration
                ?.masterDataIntegrationAutomation || {}),
              updateManuallyCreatedStakeholders: checked,
            },
          },
        });
      },
      clearProperty: () => {
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...(execStrategy.dataIntegration || {}),
            masterDataIntegrationAutomation: {
              ...(execStrategy?.dataIntegration
                ?.masterDataIntegrationAutomation || {}),
              updateManuallyCreatedStakeholders: undefined,
            },
          },
        });
      },
      renderComponent: (value, updateProperty, title, help) => {
        return (
          <Checkbox
            className="hoverable"
            checked={value}
            onChange={(e) => updateProperty(e.target.checked)}
          >
            {title}
            {help && <DCTooltip text={help} />}
          </Checkbox>
        );
      },
    },
  ];

  return { fields };
}

function StakeholderSettings(props: IProps) {
  const { fields } = useStakeholderSettingsFields(props);

  return <FromCaseFieldMapper fields={fields} />;
}

export default StakeholderSettings;
