import { Select, Space, Typography } from 'antd';
import { Meta, ProcedureMode } from 'digicust_types';
import { BaseSelectRef } from 'rc-select';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import extendedProcedureCodes from './extendedProcedureCodes.json';
import nationalProcedureCodes from './nationalProcedureCodes.json';

type ProcedureValue = {
  Extended_customs_procedure?: Meta<string>;
  National_customs_procedure?: Meta<string>;
};

const XKProcedureSelect = ({
  value,
  onChange = () => null,
  isCopied,
}: {
  value?: ProcedureValue;
  onChange?: (value?: ProcedureValue) => void;
  onCopy?: () => void;
  isCopied?: boolean;
}) => {
  const { t } = useTranslation();
  const selectRef = useRef<BaseSelectRef>(null);

  const extendedProcedureOptions = extendedProcedureCodes.map((procedure) => ({
    input: procedure.extendedProcedureCode,
    requestedProcedure: procedure.extendedProcedureCode
      ?.toString()
      .substring(0, 2),
    previousProcedure: procedure.extendedProcedureCode
      ?.toString()
      .substring(2, 4),
    value:
      procedure.extendedProcedureCode?.toString().substring(0, 1) === '1'
        ? ProcedureMode.export
        : ProcedureMode.import,
    procedureCodeCombination: procedure.extendedProcedureCode
      ?.toString()
      .substring(0, 4),
    description: procedure.description,
  }));

  const nationalProcedureOptions = nationalProcedureCodes.map((procedure) => ({
    input: procedure.nationalProcedureCode,
    nationalProcedureCode: procedure.nationalProcedureCode
      ?.toString()
      .substring(0, 4),
    description: procedure.description,
  }));

  return (
    <Space.Compact
      style={{
        maxWidth: '100%',
        width: '100%',
        outline: isCopied ? '2px blue solid' : '',
        borderRadius: 6,
      }}
    >
      <Select
        variant="filled"
        ref={selectRef}
        style={{ width: '50%' }}
        showSearch
        popupMatchSelectWidth={300}
        placeholder={t('Procedure')}
        value={value?.Extended_customs_procedure?.value}
        onChange={(e) => {
          const option = extendedProcedureOptions.find(
            (option) => e === `${option?.procedureCodeCombination}`,
          );
          onChange({
            ...(value || {}),
            Extended_customs_procedure: {
              input: option?.procedureCodeCombination,
              value: option?.procedureCodeCombination,
            },
          });
        }}
        allowClear
      >
        {extendedProcedureOptions?.map((item, key) => (
          <Select.Option
            title={`${item?.procedureCodeCombination}`}
            key={key}
            value={`${item?.procedureCodeCombination}`}
            label={item?.description}
          >
            <Space key={key}>
              <Typography.Text strong>
                {item?.procedureCodeCombination}
              </Typography.Text>
              <Typography.Text
                style={{ maxWidth: '500px', display: 'flex' }}
                ellipsis
                type="secondary"
              >
                {item.description}
              </Typography.Text>
            </Space>
          </Select.Option>
        ))}
      </Select>
      <Select
        ref={selectRef}
        variant="filled"
        style={{ width: '50%' }}
        showSearch
        popupMatchSelectWidth={300}
        placeholder={t('National Procedure')}
        value={value?.National_customs_procedure?.value}
        onChange={(e) => {
          const option = nationalProcedureOptions.find(
            (option) => e === `${option?.nationalProcedureCode}`,
          );
          onChange({
            ...(value || {}),
            National_customs_procedure: {
              input: option?.nationalProcedureCode,
              value: option?.nationalProcedureCode,
            },
          });
        }}
        allowClear
      >
        {nationalProcedureOptions?.map((item, key) => (
          <Select.Option
            title={`${item?.nationalProcedureCode}`}
            key={key}
            value={`${item?.nationalProcedureCode}`}
            label={item?.description}
          >
            <Space key={key}>
              <Typography.Text strong>
                {item?.nationalProcedureCode}
              </Typography.Text>
              <Typography.Text
                style={{ maxWidth: '500px', display: 'flex' }}
                ellipsis
                type="secondary"
              >
                {item.description}
              </Typography.Text>
            </Space>
          </Select.Option>
        ))}
      </Select>
    </Space.Compact>
  );
};

export default XKProcedureSelect;
