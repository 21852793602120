import aeb from '../../resources/aeb.png';
import dakosy from '../../resources/dakosy.png';
import dbh from '../../resources/dbh.png';
import ldv from '../../resources/ldv.png';
import dhf from '../../resources/dhf.png';
import asycuda from '../../resources/asycuda.png';
import beo from '../../resources/beo.png';
import cargosoft from '../../resources/cargosoft.png';
import mercurio from '../../resources/mercurio.png';
import format from '../../resources/format.png';
import scope from '../../resources/scope.png';

import { SubmissionVendor } from 'digicust_types';

export function getCustomsSoftwareLogoFromString(
  vendor?: SubmissionVendor | string,
) {
  switch (vendor) {
    case 'ldv':
      return ldv;
    case 'dhf':
      return dhf;
    case 'aeb':
      return aeb;
    case 'dbh':
      return dbh;
    case 'asycuda':
      return asycuda;
    case 'dakosy':
      return dakosy;
    case 'cargosoft':
      return cargosoft;
    case 'mercurio':
      return mercurio;
    case 'beo':
      return beo;
    case 'format':
      return format;
    case 'scope':
      return scope;
    default:
      return '';
  }
}
