import { Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import { DCTooltip } from '../../../../../common';
import { ExecutionStrategy } from 'digicust_types';
import { CaseFieldMapper } from '../../../../CustomsCaseDetails/types';
import FromCaseFieldMapper from './from-case-field-mapper';

interface IProps {
  execStrategy: ExecutionStrategy;
  onValueChange: (val: ExecutionStrategy) => void;
}

export function useMaterialSettingsFields(props: IProps) {
  const { execStrategy, onValueChange } = props;
  const { t } = useTranslation();

  const fields: CaseFieldMapper[] = [
    {
      title: t('Create material master data'),
      help: t(
        'Enable creation of material master data that does not exist yet',
      ),
      isCheckbox: true,
      getValue: () =>
        execStrategy.dataIntegration?.masterDataIntegrationAutomation
          ?.createMaterialMasterData,
      updateProperty: (checked) => {
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...(execStrategy.dataIntegration || {}),
            masterDataIntegrationAutomation: {
              ...(execStrategy?.dataIntegration
                ?.masterDataIntegrationAutomation || {}),
              createMaterialMasterData: checked,
            },
          },
        });
      },
      clearProperty: () => {
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...(execStrategy.dataIntegration || {}),
            masterDataIntegrationAutomation: {
              ...(execStrategy?.dataIntegration
                ?.masterDataIntegrationAutomation || {}),
              createMaterialMasterData: undefined,
            },
          },
        });
      },
      renderComponent: (value, updateProperty, title, help) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          {title}
          {help && <DCTooltip text={help} />}
        </Checkbox>
      ),
    },
    {
      title: t('Update automatically created materials'),
      help: t('Enable updates of fields in automatically created materials'),
      isCheckbox: true,
      getValue: () =>
        execStrategy.dataIntegration?.masterDataIntegrationAutomation
          ?.updateAutomaticallyCreatedMaterials,
      updateProperty: (checked) => {
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...(execStrategy.dataIntegration || {}),
            masterDataIntegrationAutomation: {
              ...(execStrategy?.dataIntegration
                ?.masterDataIntegrationAutomation || {}),
              updateAutomaticallyCreatedMaterials: checked,
            },
          },
        });
      },
      clearProperty: () => {
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...(execStrategy.dataIntegration || {}),
            masterDataIntegrationAutomation: {
              ...(execStrategy?.dataIntegration
                ?.masterDataIntegrationAutomation || {}),
              updateAutomaticallyCreatedMaterials: undefined,
            },
          },
        });
      },
      renderComponent: (value, updateProperty, title, help) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          {title}
          {help && <DCTooltip text={help} />}
        </Checkbox>
      ),
    },
    {
      title: t('Update manually created materials'),
      help: t('Enable updates of fields in manually created materials'),
      isCheckbox: true,
      getValue: () =>
        execStrategy.dataIntegration?.masterDataIntegrationAutomation
          ?.updateManuallyCreatedMaterials,
      updateProperty: (checked) => {
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...(execStrategy.dataIntegration || {}),
            masterDataIntegrationAutomation: {
              ...(execStrategy?.dataIntegration
                ?.masterDataIntegrationAutomation || {}),
              updateManuallyCreatedMaterials: checked,
            },
          },
        });
      },
      clearProperty: () => {
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...(execStrategy.dataIntegration || {}),
            masterDataIntegrationAutomation: {
              ...(execStrategy?.dataIntegration
                ?.masterDataIntegrationAutomation || {}),
              updateManuallyCreatedMaterials: undefined,
            },
          },
        });
      },
      renderComponent: (value, updateProperty, title, help) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          {title}
          {help && <DCTooltip text={help} />}
        </Checkbox>
      ),
    },
  ];

  return { fields };
}

function MaterialSettings(props: IProps) {
  const { fields } = useMaterialSettingsFields(props);

  return <FromCaseFieldMapper fields={fields} />;
}

export default MaterialSettings;
