import { InfoCircleOutlined } from '@ant-design/icons';
import { Popover, PopoverProps, Typography } from 'antd';

import { Flex } from 'antd';

type Reason = string | Record<any, string>;

export function normaliseReasons(reason?: Reason) {
  if (!reason) return undefined;

  const normaliseString = (source: string) =>
    Array.from(new Set(source.split(';').map((r) => r.trim()))).filter(Boolean);

  if (typeof reason === 'string') {
    return { ___: normaliseString(reason) };
  }

  return Object.fromEntries(
    Object.entries(reason)
      .filter((o) => o[1])
      .map(([key, value]) => [key, normaliseString(value)]),
  );
}

export function reasonToHTML(reason?: Reason) {
  const reasons = normaliseReasons(reason);
  if (!reasons) return null;
  const segments = Object.entries(reasons);

  const reasonsSegment = (reasons: string[]) => {
    if (reasons.length === 1) return reasons[0];

    return (
      <ul
        style={{
          maxWidth: '40vw',
          margin: 0,
          padding: 20,
        }}
      >
        {reasons.map((r) => (
          <li key={r}>{r}</li>
        ))}
      </ul>
    );
  };

  if (segments.length === 1 && segments[0][0] === '___')
    return reasonsSegment(segments[0][1]);

  return (
    <div>
      <Typography.Text strong>Reasons</Typography.Text>

      <div>
        {segments.map(([key, value]) => (
          <div key={key}>
            <strong>{key}: </strong>
            {reasonsSegment(value)}
          </div>
        ))}
      </div>
    </div>
  );
}

export function ReasonTooltip({
  reason,
  children,
  withIcon = true,
  trigger,
  fullWidth = true,
}: {
  reason?: Reason;
  children: React.ReactNode;
  withIcon?: boolean;
  trigger?: PopoverProps['trigger'];
  fullWidth?: boolean;
}) {
  const reasonElm = reasonToHTML(reason);

  if (!reasonElm) return <>{children}</>;

  if (withIcon)
    return (
      <Flex align="center" gap={5}>
        <Popover arrow content={reasonElm} trigger={trigger}>
          <InfoCircleOutlined />
        </Popover>
        <div style={{ width: fullWidth ? '100%' : undefined }}>{children}</div>
      </Flex>
    );

  return (
    <Popover arrow content={reasonElm}>
      <span
        style={{
          width: fullWidth ? '100%' : undefined,
          textDecoration: 'underline dotted',
          cursor: 'help',
        }}
      >
        {children}
      </span>
    </Popover>
  );
}
