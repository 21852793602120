import { Select, Space, Typography } from 'antd';
import { Meta, PreferenceState } from 'digicust_types';
import type { BaseSelectRef } from 'rc-select';
import { useEffect, useRef, useState } from 'react';
import { usePreferenceCodes } from '../../../utils/useCodes';
import { useTranslation } from 'react-i18next';
import useScrollHandler from '../../Inputs/useScrollHandler';
import preferenceCodes from './preferenceCodes.json';

const XKPreferenceSelect = ({
  value,
  onChange = () => null,
  onCopy = () => null,
  isCopied,
}: {
  value?: Meta<string> | null;
  onChange?: (value: Meta<string> | null) => void;
  onCopy?: () => void;
  isCopied?: boolean;
}) => {
  const { t } = useTranslation();
  const selectRef = useRef<BaseSelectRef>(null);

  const handleClick = (e: React.MouseEvent) => {
    if (e.ctrlKey || e.metaKey) {
      onCopy();
      selectRef.current?.blur();
    }
  };

  const handleScroll = () => {
    if (selectRef.current) {
      selectRef.current.blur();
    }
  };

  useScrollHandler(handleScroll);

  return (
    <Select
      ref={selectRef}
      showSearch
      allowClear
      style={{
        width: '100%',
        outline: isCopied ? '2px solid blue' : '',
        borderRadius: 6,
      }}
      popupMatchSelectWidth={200}
      placeholder={t('Preference')}
      value={value?.value === '' ? null : value?.value}
      onClick={handleClick}
      onChange={(e) =>
        onChange({
          input: e,
          value: e,
        })
      }
    >
      {preferenceCodes.map((item) => (
        <Select.Option
          title={item.code}
          key={item.code}
          value={item.code}
          label={item.code}
        >
          <Space>
            <Typography.Text strong>{item.code}</Typography.Text>
            <Typography.Text
              style={{ maxWidth: '530px', display: 'flex' }}
              ellipsis
              type="secondary"
            >
              {item.alpha2code}
            </Typography.Text>
          </Space>
        </Select.Option>
      ))}
    </Select>
  );
};

export default XKPreferenceSelect;
