import { AppstoreAddOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Modal } from 'antd';
import { Rule, RuleCatalogItem } from 'digicust_types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AdminRepository } from '../../../../../utils/repositories/admin.repository';
import { useAppDataContext } from '../../../../AppDataProvider';

const AddToCatalogButton = ({ rule }: { rule: Rule }) => {
  const { isAdmin } = useAppDataContext();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    form.submit();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleFinish = async (values: any) => {
    const ruleCatalogItem: RuleCatalogItem = {
      name: values.name,
      description: values.description,
      rule: rule,
      keywords: (values.keywords as string)
        .split(',')
        .map((keyword) => keyword.trim()),
    };

    try {
      await AdminRepository.addRuleToRuleCatalog(ruleCatalogItem);
      message.success('Rule added to catalog successfully');
      setIsModalVisible(false);
    } catch (error) {
      console.error(error);
      message.error('Failed to add rule to catalog');
    }
  };

  const { t } = useTranslation();
  return isAdmin ? (
    <>
      <Button
        size="small"
        type="link"
        onClick={showModal}
        icon={<AppstoreAddOutlined />}
      ></Button>
      <Modal
        title={t('Add to Catalog')}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form onFinish={handleFinish} form={form}>
          <Form.Item name="name" label={t('Name')} rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label={t('Description')}
            rules={[{ required: true }]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            name="keywords"
            label={t('Keywords')}
            rules={[{ required: true }]}
          >
            <Input placeholder={t('Enter keywords separated by comma')} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  ) : null;
};

export default AddToCatalogButton;
