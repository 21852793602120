import { Card, Checkbox, Flex, Form, Select } from 'antd';
import { ExecutionStrategy } from 'digicust_types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DCTooltip } from '../../../../../common';
import AmountOfMoneyInput from '../../../../../Components/Inputs/AmountOfMoneyInput';
import NumberInput from '../../../../../Components/Inputs/Common/NumberInput';
import { RootState } from '../../../../../Components/reduxToolkit/store';
import { CaseFieldMapper } from '../../../../CustomsCaseDetails/types';
import { cardBorderCss } from '../../../Common/CardWrapper';

export const useAdditionalCostsSettingsMapper = ({
  execStrategy,
  onValueChange,
}: {
  execStrategy: ExecutionStrategy;
  onValueChange: (val: ExecutionStrategy) => void;
}) => {
  const { t } = useTranslation();

  const storeData = useSelector((state: RootState) => state?.statisticsSlice);
  const createCompany = storeData?.CreateCompany;

  const fields: CaseFieldMapper[] = [
    {
      title: t('Fixed foreign freight cost rate'),
      help: t(
        'Digicust usually deducts the foreign freight cost and the domestic freight cost by measuring the distance between the sender and the recipient. If you activate this, Digicust will use a fixed rate instead.',
      ),
      getValue: () =>
        execStrategy?.dataNormalization?.foreignFreightCostRate !== undefined,
      updateProperty: (checked) => {
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            foreignFreightCostRate: checked ? 10 : undefined,
          },
        });
      },
      clearProperty: () => {
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            foreignFreightCostRate: undefined,
          },
        });
      },
      renderComponent: (value, updateProperty, title, help) => (
        <>
          <Checkbox
            className="hoverable"
            checked={value}
            onChange={(e) => updateProperty(e.target.checked)}
          >
            {title}
            {help && <DCTooltip text={help} />}
          </Checkbox>

          {!!execStrategy?.dataNormalization?.foreignFreightCostRate && (
            <NumberInput
              suffix="%"
              style={{ width: '100px' }}
              max={100}
              value={Math.round(
                execStrategy.dataNormalization?.foreignFreightCostRate,
              )}
              onChange={(val) =>
                onValueChange({
                  ...execStrategy,
                  dataNormalization: {
                    ...(execStrategy.dataNormalization || {}),
                    foreignFreightCostRate: val ? Math.round(val) : undefined,
                  },
                })
              }
            />
          )}
        </>
      ),
      isCheckbox: true,
    },
    {
      title: t('Calculate Freight Costs'),
      help: t(
        'Specify how Digicust calculates freight costs. For example estimate the freight cost based on weight information.',
      ),
      getValue: () =>
        execStrategy?.dataNormalization?.freightCostCalculation?.active,
      updateProperty: (checked) => {
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            freightCostCalculation: {
              ...(execStrategy.dataNormalization?.freightCostCalculation || {}),
              active: checked,
            },
          },
        });
      },
      clearProperty: () => {
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            freightCostCalculation: undefined,
          },
        });
      },
      renderComponent: (value, updateProperty, title, help) => (
        <>
          <Checkbox
            className="hoverable"
            checked={value}
            onChange={(e) => updateProperty(e.target.checked)}
          >
            {title}
            {help && <DCTooltip text={help} />}
          </Checkbox>

          {value && (
            <Card style={cardBorderCss}>
              <Form>
                <Form.Item label={t('Strategy')}>
                  <Select
                    style={{ width: '260px' }}
                    size="middle"
                    popupMatchSelectWidth={false}
                    placeholder={t('Freight Cost Calculation Strategy')}
                    value={
                      execStrategy.dataNormalization?.freightCostCalculation
                        ?.strategy || 'default'
                    }
                    onChange={(e) =>
                      onValueChange({
                        ...execStrategy,
                        dataNormalization: {
                          ...(execStrategy.dataNormalization || {}),
                          freightCostCalculation: {
                            ...(execStrategy.dataNormalization
                              ?.freightCostCalculation || {}),
                            strategy: e,
                          },
                        },
                      })
                    }
                  >
                    <Select.Option
                      title={t(
                        'Digicust will try to find freight costs in the uploaded documents only.',
                      )}
                      value="default"
                    >
                      {t('Default')}
                    </Select.Option>
                    <Select.Option
                      title={t(
                        'Digicust will assume freight costs per package.',
                      )}
                      value="packageDependent"
                    >
                      {t('Dependent on Packages')}
                    </Select.Option>
                    <Select.Option
                      title={t(
                        'Calculate freight cost based on weight information using the following logic: if weight is less than or equal to 10 kg, the cost is 22; if weight is less than or equal to 100 kg, the cost is weight multiplied by 2.5; if weight is less than or equal to 250 kg, the cost is weight multiplied by 2; if weight is less than or equal to 2000 kg, the cost is weight multiplied by 1.5; otherwise, the cost is 3000.',
                      )}
                      value="austriaWeightDependent"
                    >
                      {t('Weight Dependent (Austria/E-Zoll compatible)')}
                    </Select.Option>
                    <Select.Option
                      title={t(
                        'Calculate freight cost based on the following formula: (Package count * weight) + (km*0,20€)',
                      )}
                      value="distanceDependent"
                    >
                      {t('Distance dependent')}
                    </Select.Option>
                  </Select>
                </Form.Item>

                {execStrategy?.dataNormalization?.freightCostCalculation
                  ?.strategy === 'packageDependent' && (
                  <Form.Item
                    style={{ width: '300px' }}
                    label={t('Package Price')}
                  >
                    <AmountOfMoneyInput
                      value={
                        execStrategy?.dataNormalization?.freightCostCalculation
                          ?.packagePrice
                      }
                      onChange={(e) =>
                        onValueChange({
                          ...execStrategy,
                          dataNormalization: {
                            ...(execStrategy.dataNormalization || {}),
                            freightCostCalculation: {
                              ...(execStrategy.dataNormalization
                                ?.freightCostCalculation || {}),
                              packagePrice: e || undefined,
                            },
                          },
                        })
                      }
                    />
                  </Form.Item>
                )}

                <Select
                  size="middle"
                  style={{
                    width: '260px',
                    marginLeft:
                      execStrategy?.dataNormalization?.freightCostCalculation
                        ?.strategy === 'packageDependent' &&
                      createCompany === false
                        ? '14px'
                        : '0',
                  }}
                  popupMatchSelectWidth={false}
                  placeholder={t('Freight Cost Calculation Priority')}
                  value={
                    execStrategy.dataNormalization?.freightCostCalculation
                      ?.priority || 'fallback'
                  }
                  onChange={(e) =>
                    onValueChange({
                      ...execStrategy,
                      dataNormalization: {
                        ...(execStrategy.dataNormalization || {}),
                        freightCostCalculation: {
                          ...(execStrategy.dataNormalization
                            ?.freightCostCalculation || {}),
                          priority: e,
                        },
                      },
                    })
                  }
                >
                  <Select.Option value="fallback">
                    {t('Fallback (Apply only if not found otherwise)')}
                  </Select.Option>
                  <Select.Option value="override">
                    {t('Override')}
                  </Select.Option>
                </Select>
              </Form>
            </Card>
          )}
        </>
      ),
      isCheckbox: true,
    },
    {
      title: t('Additional cost strategy'),
      help: t(
        'Additional costs (e.g. transportation or packaging) can be added to the first line item or split to all line items equally. Customs offices usually accept both, but adding the additional costs to the first line item makes it easier to check.',
      ),
      getValue: () => execStrategy.dataNormalization?.additionalCostsStrategy,
      updateProperty: (value) => {
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            additionalCostsStrategy: value,
          },
        });
      },
      clearProperty: () => {
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            additionalCostsStrategy: undefined,
          },
        });
      },
      renderComponent: (value, updateProperty) => (
        <Select
          popupMatchSelectWidth={false}
          size="small"
          placeholder={t('Additional cost strategy')}
          value={value}
          onChange={(e) => updateProperty(e)}
        >
          <Select.Option value="equalDistribution">
            {t('Equal distribution')}
          </Select.Option>
          <Select.Option value="ignore">
            {t('Ignore additional costs')}
          </Select.Option>
          <Select.Option value="addToFirstItem">
            {t('Add to first line item')}
          </Select.Option>
          <Select.Option value="splitToAllItems">
            {t('Split to all line items equally')}
          </Select.Option>
          <Select.Option value="splitToAllItemsByNetWeight">
            {t('Split to all line items by net weight')}
          </Select.Option>
          <Select.Option value="splitToAllItemsByTotalValue">
            {t('Split to all line items by total value')}
          </Select.Option>
        </Select>
      ),
    },
  ];

  return { fields };
};

export default function AdditionalCostsSettings({
  execStrategy,
  onValueChange,
}: {
  execStrategy: ExecutionStrategy;
  onValueChange: (val: ExecutionStrategy) => void;
}) {
  const { fields } = useAdditionalCostsSettingsMapper({
    execStrategy,
    onValueChange,
  });

  return (
    <Flex wrap gap={6}>
      {fields.map((field) => {
        if (field.isCheckbox) {
          return field.renderComponent(
            field.getValue(),
            field.updateProperty,
            field.title,
            field.help as string,
          );
        }

        return (
          <div className="hoverable" style={{ display: 'inline-block' }}>
            {field.title}
            <DCTooltip text={field.help as string} />
            {field.renderComponent(field.getValue(), field.updateProperty)}
          </div>
        );
      })}
    </Flex>
  );
}
