import { Checkbox, Flex, Select } from 'antd';
import { ExecutionStrategy } from 'digicust_types';
import { useTranslation } from 'react-i18next';
import { DCTooltip } from '../../../../../common';
import { CaseFieldMapper } from '../../../../CustomsCaseDetails/types';

export const useNormalizationPreferencesMapperES = ({
  execStrategy,
  onValueChange,
}: {
  execStrategy: ExecutionStrategy;
  onValueChange: (val: ExecutionStrategy) => void;
}) => {
  const { t } = useTranslation();

  const fields: CaseFieldMapper[] = [
    {
      title: t('Check trade preference for wording'),
      help: t(
        'If you activate this, Digicust will check the wording of the trade preference and will not use the trade preference if the wording is not correct. If you deactivate this, Digicust will use the trade preference even if the wording is not correct. Many clients choose to deactivate this to make use of the trade preference even if the wording is not correct. Others might choose to activate this to make sure that the trade preference is only used if the wording is correct.',
      ),
      getValue: () =>
        execStrategy?.dataNormalization?.checkTradePreferenceForWording,
      updateProperty: (checked) => {
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            checkTradePreferenceForWording: checked,
          },
        });
      },
      clearProperty: () => {
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            checkTradePreferenceForWording: undefined,
          },
        });
      },
      renderComponent: (value, updateProperty, title, help) => {
        return (
          <Checkbox
            className="hoverable"
            checked={value}
            onChange={(e) => updateProperty(e.target.checked)}
          >
            {title}
            {help && <DCTooltip text={help} />}
          </Checkbox>
        );
      },
      isCheckbox: true,
    },
    {
      title: t('Assume origin from preference'),
      help: t(
        `If the country of origin is not explicitly stated on documents
          (e.g. invoice), Digicust will assume the country of origin
          from the trade preference. You can disable this option here.`,
      ),
      getValue: () =>
        execStrategy.dataNormalization?.doNotAssumeOriginFromPreference ||
        false,
      updateProperty: (doNotAssumeOriginFromPreference) => {
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            doNotAssumeOriginFromPreference,
          },
        });
      },
      clearProperty: () => {
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            doNotAssumeOriginFromPreference: undefined,
          },
        });
      },
      renderComponent: (value, updateProperty) => {
        return (
          <Select
            popupMatchSelectWidth={false}
            size="small"
            showSearch
            placeholder={t('Assume Origin from Preference')}
            optionFilterProp="children"
            value={value}
            onChange={(doNotAssumeOriginFromPreference) =>
              updateProperty(doNotAssumeOriginFromPreference)
            }
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            <Select.Option value={true}>
              {t('Do not assume origin')}
            </Select.Option>
            <Select.Option value={false}>{t('Assume origin')}</Select.Option>
          </Select>
        );
      },
    },
    {
      title: t('Assume origin from head'),
      help: t(
        `If the country of origin is not explicitly stated on line item
          level (e.g. invoice), Digicust can assume the country of
          origin from the aggregated head.`,
      ),
      getValue: () =>
        execStrategy.dataNormalization?.assumeOriginFromHead || false,
      updateProperty: (assumeOriginFromHead) => {
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            assumeOriginFromHead,
          },
        });
      },
      clearProperty: () => {
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            assumeOriginFromHead: undefined,
          },
        });
      },
      renderComponent: (value, updateProperty) => {
        return (
          <Select
            popupMatchSelectWidth={false}
            size="small"
            showSearch
            placeholder={t('Assume Origin from Head')}
            optionFilterProp="children"
            value={value}
            onChange={(assumeOriginFromHead) =>
              updateProperty(assumeOriginFromHead)
            }
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            <Select.Option value={true}>
              {t('Assume origin from head')}
            </Select.Option>
            <Select.Option value={false}>
              {t('Do not assume origin from head')}
            </Select.Option>
          </Select>
        );
      },
    },
    {
      title: t('Assume origin from departure country'),
      help: t(
        `If the country of origin is not explicitly stated otherwise, if activated, Digicust will assume the departure country as origin.`,
      ),
      getValue: () =>
        execStrategy.dataNormalization?.assumeOriginFromDepartureCountry ||
        false,
      updateProperty: (assumeOriginFromDepartureCountry) => {
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            assumeOriginFromDepartureCountry,
          },
        });
      },
      clearProperty: () => {
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            assumeOriginFromDepartureCountry: undefined,
          },
        });
      },
      renderComponent: (value, updateProperty) => {
        return (
          <Select
            popupMatchSelectWidth={false}
            size="small"
            showSearch
            placeholder={t('Origin from Departure Country')}
            optionFilterProp="children"
            value={value}
            onChange={(assumeOriginFromDepartureCountry) =>
              updateProperty(assumeOriginFromDepartureCountry)
            }
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            <Select.Option value={true}>
              {t('Assume origin from departure country')}
            </Select.Option>
            <Select.Option value={false}>
              {t('Do not assume origin from departure country')}
            </Select.Option>
          </Select>
        );
      },
    },
  ];

  return { fields };
};

export default function PreferenceSettings({
  execStrategy,
  onValueChange,
}: {
  execStrategy: ExecutionStrategy;
  onValueChange: (val: ExecutionStrategy) => void;
}) {
  const { fields } = useNormalizationPreferencesMapperES({
    execStrategy,
    onValueChange,
  });

  return (
    <Flex wrap gap={6}>
      {fields.map((field) => {
        if (field.isCheckbox) {
          return field.renderComponent(
            field.getValue(),
            field.updateProperty,
            field.title,
            field.help as string,
          );
        }

        return (
          <div className="hoverable" style={{ display: 'inline-block' }}>
            {field.title}
            <DCTooltip text={field.help as string} />
            {field.renderComponent(field.getValue(), field.updateProperty)}
          </div>
        );
      })}
    </Flex>
  );
}
