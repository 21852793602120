import { Button, Flex, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import DCSpinner from '../../common/FullPageLoader';
import { OnboardingRepository } from '../../utils/repositories/onboarding.repository';
import NewCustomerModal from '../SideMenu/components/NewCustomerModal';

const ChooseCustomer: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [newCustomerModalOpen, setNewCustomerModalOpen] = useState(false);

  const initialize = async () => {
    {
      const customerId = localStorage.getItem('customerId');
      const projectId = localStorage.getItem('projectId');
      if (customerId && projectId) {
        navigate(`/${customerId}/${projectId}`);
        return;
      }
    }
    setLoading(true);
    const inviteCode = searchParams.get('inviteCode');

    if (inviteCode) {
      const customerData =
        await OnboardingRepository.applyInviteCode(inviteCode);
      const { result } = customerData;
      if (result?.data) {
        localStorage.setItem('customerId', result?.data?.customerData?.groupId);
        localStorage.setItem('projectId', result?.data?.projectData?.groupId);
      }
    } else {
      const res = await OnboardingRepository.check();
      if (res?.id && res?.projects) {
        localStorage.setItem('customerId', res?.id);
        localStorage.setItem('projectId', res?.projects?.[0]?.id);
      }
    }

    const customerId = localStorage.getItem('customerId');
    const projectId = localStorage.getItem('projectId');

    if (customerId && projectId) {
      navigate(`/${customerId}/${projectId}`);
    } else {
      setNewCustomerModalOpen(true);
    }

    setLoading(false);
  };

  useEffect(() => {
    initialize();
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      {loading ? (
        <DCSpinner fullPage />
      ) : (
        <Flex justify="center" align="center" vertical gap={5}>
          <Typography.Text>
            Your onboarding is not yet complete.
          </Typography.Text>
          <Button type="primary" onClick={() => setNewCustomerModalOpen(true)}>
            Complete now
          </Button>
        </Flex>
      )}

      <NewCustomerModal
        open={newCustomerModalOpen}
        onOpenChange={setNewCustomerModalOpen}
      />
    </div>
  );
};

export default ChooseCustomer;
