import { Checkbox, Flex, Select } from 'antd';
import { ExecutionStrategy } from 'digicust_types';
import { useTranslation } from 'react-i18next';
import { DCTooltip } from '../../../../../common';
import { CaseFieldMapper } from '../../../../CustomsCaseDetails/types';

export const useMasterDataSettingsMapper = ({
  execStrategy,
  onValueChange,
}: {
  execStrategy: ExecutionStrategy;
  onValueChange: (val: ExecutionStrategy) => void;
}) => {
  const { t } = useTranslation();

  const fields: CaseFieldMapper[] = [
    {
      title: t('Disable matching'),
      getValue: () => execStrategy.matchingEngine?.materialMatching?.disabled,
      updateProperty: (checked) => {
        onValueChange({
          ...execStrategy,
          matchingEngine: {
            ...(execStrategy.matchingEngine || {}),
            materialMatching: {
              ...(execStrategy.matchingEngine?.materialMatching || {}),
              disabled: checked,
            },
          },
        });
      },
      clearProperty: () => {
        onValueChange({
          ...execStrategy,
          matchingEngine: {
            ...(execStrategy.matchingEngine || {}),
            materialMatching: {
              ...(execStrategy.matchingEngine?.materialMatching || {}),
              disabled: undefined,
            },
          },
        });
      },
      renderComponent: (value, updateProperty, title) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          {title}
        </Checkbox>
      ),
      isCheckbox: true,
    },
    {
      title: t('Disable tariff number matching'),
      getValue: () =>
        execStrategy.matchingEngine?.materialMatching
          ?.disableTariffNumberMatching,
      updateProperty: (checked) => {
        onValueChange({
          ...execStrategy,
          matchingEngine: {
            ...(execStrategy.matchingEngine || {}),
            materialMatching: {
              ...(execStrategy.matchingEngine?.materialMatching || {}),
              disableTariffNumberMatching: checked,
            },
          },
        });
      },
      clearProperty: () => {
        onValueChange({
          ...execStrategy,
          matchingEngine: {
            ...(execStrategy.matchingEngine || {}),
            materialMatching: {
              ...(execStrategy.matchingEngine?.materialMatching || {}),
              disableTariffNumberMatching: undefined,
            },
          },
        });
      },
      renderComponent: (value, updateProperty, title) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          {title}
        </Checkbox>
      ),
      isCheckbox: true,
    },
    {
      title: t('Disable material number matching'),
      getValue: () =>
        execStrategy.matchingEngine?.materialMatching
          ?.disableMaterialNumberMatching,
      updateProperty: (checked) => {
        onValueChange({
          ...execStrategy,
          matchingEngine: {
            ...(execStrategy.matchingEngine || {}),
            materialMatching: {
              ...(execStrategy.matchingEngine?.materialMatching || {}),
              disableMaterialNumberMatching: checked,
            },
          },
        });
      },
      clearProperty: () => {
        onValueChange({
          ...execStrategy,
          matchingEngine: {
            ...(execStrategy.matchingEngine || {}),
            materialMatching: {
              ...(execStrategy.matchingEngine?.materialMatching || {}),
              disableMaterialNumberMatching: undefined,
            },
          },
        });
      },
      renderComponent: (value, updateProperty, title) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          {title}
        </Checkbox>
      ),
      isCheckbox: true,
    },
    {
      title: t('Send only master data tariff number'),
      help: t(
        'Send only user-edited master data Tariff Number to the subsequent customs software.',
      ),
      getValue: () =>
        execStrategy.matchingEngine?.materialMatching
          ?.sendMasterDataTariffNumberOnly,
      updateProperty: (checked) => {
        onValueChange({
          ...execStrategy,
          matchingEngine: {
            ...(execStrategy.matchingEngine || {}),
            materialMatching: {
              ...(execStrategy.matchingEngine?.materialMatching || {}),
              sendMasterDataTariffNumberOnly: checked,
            },
          },
        });
      },
      clearProperty: () => {
        onValueChange({
          ...execStrategy,
          matchingEngine: {
            ...(execStrategy.matchingEngine || {}),
            materialMatching: {
              ...(execStrategy.matchingEngine?.materialMatching || {}),
              sendMasterDataTariffNumberOnly: undefined,
            },
          },
        });
      },
      renderComponent: (value, updateProperty, title, help) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          {title}
          {help && <DCTooltip text={help} />}
        </Checkbox>
      ),
      isCheckbox: true,
    },
    {
      title: t('Send only master data description'),
      help: t(
        'Send only user-edited master data Description to the subsequent customs software.',
      ),
      getValue: () =>
        execStrategy.matchingEngine?.materialMatching
          ?.sendMasterDataDescriptionOnly,
      updateProperty: (checked) => {
        onValueChange({
          ...execStrategy,
          matchingEngine: {
            ...(execStrategy.matchingEngine || {}),
            materialMatching: {
              ...(execStrategy.matchingEngine?.materialMatching || {}),
              sendMasterDataDescriptionOnly: checked,
            },
          },
        });
      },
      clearProperty: () => {
        onValueChange({
          ...execStrategy,
          matchingEngine: {
            ...(execStrategy.matchingEngine || {}),
            materialMatching: {
              ...(execStrategy.matchingEngine?.materialMatching || {}),
              sendMasterDataDescriptionOnly: undefined,
            },
          },
        });
      },
      renderComponent: (value, updateProperty, title, help) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          {title}
          {help && <DCTooltip text={help} />}
        </Checkbox>
      ),
      isCheckbox: true,
    },
    {
      title: t('Tariff number master data matching by stakeholders'),
      getValue: () =>
        !!execStrategy.matchingEngine?.materialMatching
          ?.tariffNumberMatchingConfiguration?.stakeholderFilters,
      updateProperty: (checked) => {
        onValueChange({
          ...execStrategy,
          matchingEngine: {
            ...(execStrategy.matchingEngine || {}),
            materialMatching: {
              ...(execStrategy.matchingEngine?.materialMatching || {}),
              tariffNumberMatchingConfiguration: {
                ...(execStrategy.matchingEngine?.materialMatching
                  ?.tariffNumberMatchingConfiguration || {}),
                stakeholderFilters: checked ? [] : undefined,
              },
            },
          },
        });
      },
      clearProperty: () => {
        onValueChange({
          ...execStrategy,
          matchingEngine: {
            ...(execStrategy.matchingEngine || {}),
            materialMatching: {
              ...(execStrategy.matchingEngine?.materialMatching || {}),
              tariffNumberMatchingConfiguration: {
                ...(execStrategy.matchingEngine?.materialMatching
                  ?.tariffNumberMatchingConfiguration || {}),
                stakeholderFilters: undefined,
              },
            },
          },
        });
      },
      renderComponent: (value, updateProperty, title) => (
        <>
          <Checkbox
            className="hoverable"
            checked={value}
            onChange={(e) => updateProperty(e.target.checked)}
          >
            {title}
          </Checkbox>

          {value && (
            <Select
              popupMatchSelectWidth={false}
              mode="multiple"
              style={{ width: '150px' }}
              placeholder={t('Stakeholder')}
              value={
                execStrategy.matchingEngine?.materialMatching
                  ?.tariffNumberMatchingConfiguration?.stakeholderFilters
              }
              options={[
                'shipper',
                'consignee',
                'recipient',
                'beneficiary',
                'applicant',
                'declarant',
                'importer',
                'exporter',
                'buyer',
                'agent',
                'broker',
                'carrier',
                'warehouse',
                'obligater',
              ].map((stakeholder) => ({
                value: stakeholder,
                label:
                  stakeholder.charAt(0).toUpperCase() + stakeholder.slice(1),
              }))}
              onChange={(value) =>
                onValueChange({
                  ...execStrategy,
                  matchingEngine: {
                    ...(execStrategy.matchingEngine || {}),
                    materialMatching: {
                      ...(execStrategy.matchingEngine?.materialMatching || {}),
                      tariffNumberMatchingConfiguration: {
                        ...(execStrategy.matchingEngine?.materialMatching
                          ?.tariffNumberMatchingConfiguration || {}),
                        stakeholderFilters: value,
                      },
                    },
                  },
                })
              }
            />
          )}
        </>
      ),
      isCheckbox: true,
    },
    {
      title: t('Match by shipper country'),
      getValue: () =>
        !!execStrategy.matchingEngine?.materialMatching
          ?.tariffNumberMatchingConfiguration?.matchShipperCountry,
      updateProperty: (checked) => {
        onValueChange({
          ...execStrategy,
          matchingEngine: {
            ...(execStrategy.matchingEngine || {}),
            materialMatching: {
              ...(execStrategy.matchingEngine?.materialMatching || {}),
              tariffNumberMatchingConfiguration: {
                ...(execStrategy.matchingEngine?.materialMatching
                  ?.tariffNumberMatchingConfiguration || {}),
                matchShipperCountry: checked,
              },
            },
          },
        });
      },
      clearProperty: () => {
        onValueChange({
          ...execStrategy,
          matchingEngine: {
            ...(execStrategy.matchingEngine || {}),
            materialMatching: {
              ...(execStrategy.matchingEngine?.materialMatching || {}),
              tariffNumberMatchingConfiguration: {
                ...(execStrategy.matchingEngine?.materialMatching
                  ?.tariffNumberMatchingConfiguration || {}),
                matchShipperCountry: undefined,
              },
            },
          },
        });
      },
      renderComponent: (value, updateProperty, title) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          {title}
        </Checkbox>
      ),
      isCheckbox: true,
    },
    {
      title: t('Disable automatic stakeholder creation'),
      getValue: () =>
        !!execStrategy.dataNormalization?.disableAutomaticStakeholderCreation,
      updateProperty: (checked) => {
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            disableAutomaticStakeholderCreation: checked,
          },
        });
      },
      clearProperty: () =>
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            disableAutomaticStakeholderCreation: undefined,
          },
        }),
      renderComponent: (value, updateProperty, title) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          {title}
        </Checkbox>
      ),
      isCheckbox: true,
    },
    {
      title: t('Disable automatic material creation'),
      getValue: () =>
        !!execStrategy.dataNormalization?.disableAutomaticMaterialCreation,
      updateProperty: (checked) => {
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            disableAutomaticMaterialCreation: checked,
          },
        });
      },
      clearProperty: () => {
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            disableAutomaticMaterialCreation: undefined,
          },
        });
      },
      renderComponent: (value, updateProperty, title) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          {title}
        </Checkbox>
      ),
      isCheckbox: true,
    },
  ];

  return { fields };
};

export default function MasterDataSettings({
  execStrategy,
  onValueChange,
}: {
  execStrategy: ExecutionStrategy;
  onValueChange: (val: ExecutionStrategy) => void;
}) {
  const { fields } = useMasterDataSettingsMapper({
    execStrategy,
    onValueChange,
  });

  return (
    <Flex wrap gap={6}>
      {fields.map((field) => {
        if (field.isCheckbox) {
          return field.renderComponent(
            field.getValue(),
            field.updateProperty,
            field.title,
            field.help as string,
          );
        }

        return (
          <div className="hoverable" style={{ display: 'inline-block' }}>
            {field.title}
            <DCTooltip text={field.help as string} />
            {field.renderComponent(field.getValue(), field.updateProperty)}
          </div>
        );
      })}
    </Flex>
  );
}
