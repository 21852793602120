import { CloseOutlined } from '@ant-design/icons';
import { Button, Card, Flex, Space, TreeSelect, Typography } from 'antd';
import { ExecutionStrategy } from 'digicust_types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DCTooltip } from '../../../../common';
import { useExtractionServiceMapperES } from './DataExtractionCard';
import { useIntegrationServiceMapperES } from './DataIntegrationCard';
import { useUploadServiceMapperES } from './UploadCard';
import { useAdditionalCostsSettingsMapper } from './data-normalization/additional-costs-settings';
import { useDocumentCodesSettingsMapper } from './data-normalization/document-codes-settings';
import { useNormalizationExportMapperES } from './data-normalization/export-settings';
import { useGoodsDescriptionSettingsMapper } from './data-normalization/goods-description-settings';
import { useMasterDataSettingsMapper } from './data-normalization/master-data-settings';
import { useOtherOptionsSettingsMapper } from './data-normalization/other-options-settings';
import { usePackagesMapperES } from './data-normalization/packages-settings';
import { useNormalizationPreferencesMapperES } from './data-normalization/preference-settings';
import { useTariffNumberSettingsMapper } from './data-normalization/tariff-number-settings';
import { useTransitSettingsMapper } from './data-normalization/transit-settings';
import { useWeightSettingsMapper } from './data-normalization/weight-settings';
import {
  useMaterialIntegrationAdditionalPropertiesSettings,
  useMaterialIntegrationGeneralSettings,
  useMaterialIntegrationHistoryManagementSettings,
} from './data-integration/material-integration-settings';
import { useMaterialSettingsFields } from './data-integration/material-settings';
import { useStakeholderSettingsFields } from './data-integration/stakeholder-settings';
import {
  useTariffNumberMatchingOptions,
  useTariffNumberSettingsFields,
} from './data-integration/tariff-number-settings';

type TreeNode = {
  title: string;
  value: string;
  children?: TreeNode[];
};

const useExecutionStrategyMapper = ({
  execStrategy,
  onValueChange,
}: {
  execStrategy: ExecutionStrategy;
  onValueChange: (val: ExecutionStrategy) => void;
}) => {
  const { fields: uploadFields } = useUploadServiceMapperES({
    execStrategy,
    onValueChange,
  });

  const { fields: integrationFields } = useIntegrationServiceMapperES({
    execStrategy,
    onValueChange,
  });

  const { fields: extractionFields } = useExtractionServiceMapperES({
    execStrategy,
    onValueChange,
  });

  const { fields: normalizationTariffNumberFields } =
    useTariffNumberSettingsMapper({
      execStrategy,
      onValueChange,
    });

  const { fields: normalizationMasterDataFields } = useMasterDataSettingsMapper(
    {
      execStrategy,
      onValueChange,
    },
  );
  const { fields: normalizationWeightFields } = useWeightSettingsMapper({
    execStrategy,
    onValueChange,
  });

  const { fields: normalizationGoodsDescriptionFields } =
    useGoodsDescriptionSettingsMapper({
      execStrategy,
      onValueChange,
    });

  const { fields: normalizationDocumentCodesFields } =
    useDocumentCodesSettingsMapper({
      execStrategy,
      onValueChange,
    });

  const { fields: normalizationAdditionalCostsFields } =
    useAdditionalCostsSettingsMapper({
      execStrategy,
      onValueChange,
    });

  const { fields: normalizationPackagesFields } = usePackagesMapperES({
    execStrategy,
    onValueChange,
  });

  const { fields: normalizationExportFields } = useNormalizationExportMapperES({
    execStrategy,
    onValueChange,
  });

  const { fields: normalizationTransitFields } = useTransitSettingsMapper({
    execStrategy,
    onValueChange,
  });

  const { fields: normalizationPreferencesFields } =
    useNormalizationPreferencesMapperES({
      execStrategy,
      onValueChange,
    });

  const { fields: normalizationOtherOptionsFields } =
    useOtherOptionsSettingsMapper({
      execStrategy,
      onValueChange,
    });

  const { fields: materialIntegrationGeneralFields } =
    useMaterialIntegrationGeneralSettings({
      execStrategy,
      onValueChange,
    });

  const { fields: materialIntegrationHistoryManagementFields } =
    useMaterialIntegrationHistoryManagementSettings({
      execStrategy,
      onValueChange,
    });

  const { fields: materialIntegrationAdditionalPropertiesFields } =
    useMaterialIntegrationAdditionalPropertiesSettings({
      execStrategy,
      onValueChange,
    });

  const { fields: materialIntegrationFields } = useMaterialSettingsFields({
    execStrategy,
    onValueChange,
  });

  const { fields: stakeholderFields } = useStakeholderSettingsFields({
    execStrategy,
    onValueChange,
  });

  const { fields: tariffNumberMatchingOptionsFields } =
    useTariffNumberMatchingOptions({
      execStrategy,
      onValueChange,
    });

  const { fields: tariffNumberFields } = useTariffNumberSettingsFields({
    execStrategy,
    onValueChange,
  });

  const treeData: TreeNode[] = [
    {
      title: 'Upload Service',
      value: 'Upload',
      children: uploadFields.map((cur) => ({ ...cur, value: cur.title })),
    },
    {
      title: 'Extraction Service',
      value: 'Extraction',
      children: extractionFields.map((cur) => ({ ...cur, value: cur.title })),
    },
    {
      title: 'Normalization Service',
      value: 'Normalization',
      children: [
        {
          title: 'Tariff Number',
          value: 'normalization-tariff-number',
          children: normalizationTariffNumberFields.map((cur) => ({
            ...cur,
            value: cur.title,
          })),
        },
        {
          title: 'Master Data',
          value: 'normalization-master-data',
          children: normalizationMasterDataFields.map((cur) => ({
            ...cur,
            value: cur.title,
          })),
        },
        {
          title: 'Weight',
          value: 'normalization-weight',
          children: normalizationWeightFields.map((cur) => ({
            ...cur,
            value: cur.title,
          })),
        },
        {
          title: 'Goods Description',
          value: 'normalization-goods-description',
          children: normalizationGoodsDescriptionFields.map((cur) => ({
            ...cur,
            value: cur.title,
          })),
        },
        {
          title: 'Document Type Code',
          value: 'normalization-type-code',
          children: normalizationDocumentCodesFields.map((cur) => ({
            ...cur,
            value: cur.title,
          })),
        },
        {
          title: 'Additional Costs',
          value: 'normalization-additional-costs',
          children: normalizationAdditionalCostsFields.map((cur) => ({
            ...cur,
            value: cur.title,
          })),
        },
        {
          title: 'Packages',
          value: 'normalization-packages',
          children: normalizationPackagesFields.map((cur) => ({
            ...cur,
            value: cur.title,
          })),
        },
        {
          title: 'Export',
          value: 'normalization-export',
          children: normalizationExportFields.map((cur) => ({
            ...cur,
            value: cur.title,
          })),
        },
        {
          title: 'Transit',
          value: 'normalization-transit',
          children: normalizationTransitFields.map((cur) => ({
            ...cur,
            value: cur.title,
          })),
        },
        {
          title: 'Preference',
          value: 'normalization-Preference',
          children: normalizationPreferencesFields.map((cur) => ({
            ...cur,
            value: cur.title,
          })),
        },
        {
          title: 'Other Options',
          value: 'normalization-other-options',
          children: normalizationOtherOptionsFields.map((cur) => ({
            ...cur,
            value: cur.title,
          })),
        },
      ],
    },
    {
      title: 'Integration Service',
      value: 'Integration',
      children: [
        {
          title: 'Material',
          value: 'integration-material',
          children: materialIntegrationFields.map((cur) => ({
            ...cur,
            value: cur.title,
          })),
        },
        {
          title: 'Stakeholder',
          value: 'integration-stakeholder',
          children: stakeholderFields.map((cur) => ({
            ...cur,
            value: cur.title,
          })),
        },
        {
          title: 'Tariff Number',
          value: 'integration-tariff-number',
          children: tariffNumberFields.map((cur) => ({
            ...cur,
            value: cur.title,
          })),
        },
        {
          title: 'Tariff Number Matching Options',
          value: 'integration-tariff-number-matching-options',
          children: tariffNumberMatchingOptionsFields.map((cur) => ({
            ...cur,
            value: cur.title,
          })),
        },
        {
          title: 'Material Integration General',
          value: 'integration-material-integration-general',
          children: materialIntegrationGeneralFields.map((cur) => ({
            ...cur,
            value: cur.title,
          })),
        },
        {
          title: 'Material Integration History Management',
          value: 'integration-material-integration-history-management',
          children: materialIntegrationHistoryManagementFields.map((cur) => ({
            ...cur,
            value: cur.title,
          })),
        },
        {
          title: 'Material Integration Additional Properties',
          value: 'integration-material-integration-additional-properties',
          children: materialIntegrationAdditionalPropertiesFields.map(
            (cur) => ({
              ...cur,
              value: cur.title,
            }),
          ),
        },
        {
          title: 'other options',
          value: 'integration-other-options',
          children: integrationFields.map((cur) => ({
            ...cur,
            value: cur.title,
          })),
        },
      ],
    },
  ];

  return { treeData };
};

export const SearchableExecutionStrategy = ({
  execStrategy,
  onValueChange,
  showValues,
}: {
  execStrategy: ExecutionStrategy;
  onValueChange: (val: ExecutionStrategy) => void;
  showValues?: boolean;
}) => {
  const { t } = useTranslation();

  const [search, setSearch] = useState<string>();

  const { treeData } = useExecutionStrategyMapper({
    execStrategy,
    onValueChange,
  });

  return (
    <Space direction="vertical" style={{ width: '100%', marginBottom: 16 }}>
      <Space direction="vertical" style={{ width: '100%' }} size={0}>
        <Typography.Text>{t('Search Execution Strategy')}</Typography.Text>

        <TreeSelect
          placeholder={t('Select Execution Strategy')}
          style={{ width: '100%' }}
          treeData={treeData}
          showSearch
          treeDefaultExpandAll
          value={search}
          onChange={(val) => setSearch(val)}
          filterTreeNode={(inputValue, treeNode) =>
            JSON.stringify(treeNode.title)
              ?.toLowerCase()
              .includes(inputValue?.toLowerCase())
          }
        />

        {search && (
          <Button type="link" size="small" onClick={() => setSearch(undefined)}>
            Clear search
          </Button>
        )}
      </Space>

      <Space
        direction="vertical"
        style={{ maxHeight: '400px', width: '100%', overflow: 'auto' }}
        size="middle"
      >
        {treeData
          .flatMap((cur) =>
            cur.children?.flatMap((child) => child.children || child),
          )
          .filter((cur: any) =>
            showValues
              ? cur.getValue() ||
                cur.title
                  ?.toLowerCase()
                  .includes((search || 'placeholder')?.toLowerCase())
              : cur.title
                  ?.toLowerCase()
                  .includes((search || 'placeholder')?.toLowerCase()),
          )
          .map((cur: any) => (
            <Card
              bordered={false}
              styles={{
                body: {
                  padding: 4,
                },
              }}
            >
              <Flex align="center" justify="space-between">
                {cur.isCheckbox ? (
                  <Space size={0} style={{ width: '100%' }}>
                    {cur.clearProperty && (
                      <Button
                        type="link"
                        size="small"
                        onClick={cur.clearProperty}
                        icon={<CloseOutlined />}
                      />
                    )}
                    {cur.renderComponent(
                      cur.getValue(),
                      cur.updateProperty,
                      cur.title,
                      cur.help as string,
                    )}
                  </Space>
                ) : (
                  <div style={{ width: '100%' }}>
                    <Space size={0}>
                      {cur.clearProperty && (
                        <Button
                          type="link"
                          size="small"
                          onClick={cur.clearProperty}
                          icon={<CloseOutlined />}
                        />
                      )}
                      <Typography.Text>{cur?.title}</Typography.Text>
                      <DCTooltip text={cur.help as string} />
                    </Space>

                    {cur.renderComponent(cur.getValue(), cur.updateProperty)}
                  </div>
                )}
              </Flex>
            </Card>
          ))}
      </Space>
    </Space>
  );
};
