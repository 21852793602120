import { Badge, Tabs } from 'antd';
import { TariffSystem, UserInput } from 'digicust_types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CustomValidationMessagesComponent from '../../../Components/Inputs/CustomValidationMessagesComponent ';
import { useAppDataContext } from '../../AppDataProvider';
import { SearchableExecutionStrategy } from '../../Settings/ExecutionStrategies/Processing/execution-strategy-field-mapper';
import SearchableCaseFields from '../aggregated-field-mapper';
import { SearchableLineItems } from '../line-items-tab/tableViews/useSearchableLineItems';

const countValidKeys = (obj: any) => {
  if (!obj || typeof obj !== 'object') return 0;

  return Object.entries(obj).reduce((count, [_, value]) => {
    if (!value) return count;
    if (typeof value === 'object' && !Object.keys(value).length) return count;
    return count + 1;
  }, 0);
};

interface TabConfig {
  show?: boolean;
}

interface TabItem {
  key: string;
  label: React.ReactNode;
  children: React.ReactNode;
}

export default function CaseDetailsSearchable({
  userInput,
  onChange,
  tabConfig = {},
  hideSearch,
}: {
  userInput: UserInput;
  onChange: (caseData: UserInput) => void;
  tabConfig?: {
    caseDetails?: TabConfig;
    lineItems?: TabConfig;
    executionStrategy?: TabConfig;
    warnings?: TabConfig;
  };
  hideSearch?: boolean;
}) {
  const { projectDetails } = useAppDataContext();
  const { t } = useTranslation();

  const aggregatedCount = useMemo(
    () => countValidKeys(userInput.annotatedAggregated),
    [userInput.annotatedAggregated],
  );

  const lineItemCountCount = useMemo(
    () => countValidKeys(userInput.annotatedLineItem),
    [userInput.annotatedLineItem],
  );

  const executionCount = useMemo(
    () => countValidKeys(userInput.annotatedExecutionStrategy),
    [userInput.annotatedExecutionStrategy],
  );

  const tabs = useMemo(() => {
    const availableTabs: TabItem[] = [];

    // Case Details Tab
    if (tabConfig.caseDetails?.show !== false) {
      availableTabs.push({
        key: 'CaseDetails',
        label: (
          <Badge count={aggregatedCount} size="small" color="blue">
            {t('Case Detail')}
          </Badge>
        ),
        children: (
          <SearchableCaseFields
            hideSearch={hideSearch}
            caseData={userInput.annotatedAggregated || {}}
            updateItem={(annotatedAggregated) => {
              onChange({
                ...(userInput || {}),
                annotatedAggregated,
              });
            }}
          />
        ),
      });
    }

    // Line Items Tab
    if (tabConfig.lineItems?.show !== false) {
      availableTabs.push({
        key: 'LineItems',
        label: (
          <Badge count={lineItemCountCount} size="small" color="blue">
            {t('Line Items')}
          </Badge>
        ),
        children: (
          <SearchableLineItems
            item={userInput.annotatedLineItem || {}}
            updateItem={(annotatedLineItem) =>
              onChange({
                ...(userInput || {}),
                annotatedLineItem,
              })
            }
            tariffSystem={
              projectDetails?.tariffNumberTreeSystem || TariffSystem.DE
            }
          />
        ),
      });
    }

    // Execution Strategy Tab
    if (tabConfig.executionStrategy?.show !== false) {
      availableTabs.push({
        key: 'ExecutionStrategy',
        label: (
          <Badge count={executionCount} size="small" color="blue">
            {t('Execution Strategy')}
          </Badge>
        ),
        children: (
          <SearchableExecutionStrategy
            execStrategy={userInput.annotatedExecutionStrategy || {}}
            onValueChange={(annotatedExecutionStrategy) =>
              onChange({
                ...(userInput || {}),
                annotatedExecutionStrategy,
              })
            }
            showValues
          />
        ),
      });
    }

    // Warnings Tab
    if (tabConfig.warnings?.show !== false) {
      availableTabs.push({
        key: 'Warnings',
        label: (
          <Badge
            count={userInput?.customValidationMessages?.length}
            size="small"
            color="blue"
          >
            {t('Warnings')}
          </Badge>
        ),
        children: (
          <CustomValidationMessagesComponent
            value={userInput?.customValidationMessages || []}
            onValueChange={(customValidationMessages) => {
              onChange({
                ...(userInput || {}),
                customValidationMessages,
              });
            }}
          />
        ),
      });
    }

    return availableTabs;
  }, [
    tabConfig,
    aggregatedCount,
    lineItemCountCount,
    executionCount,
    userInput,
    onChange,
    projectDetails?.tariffNumberTreeSystem,
    t,
  ]);

  if (tabs.length === 0) {
    return null;
  }

  // If only one tab is active, render its content directly without the tabs UI
  if (tabs.length === 1) {
    return <div className="p-4">{tabs[0].children}</div>;
  }

  // Render tabs UI when multiple tabs are active
  return <Tabs items={tabs} />;
}
