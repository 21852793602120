import { ExecutionStrategy } from 'digicust_types';
import { useTranslation } from 'react-i18next';
import { CaseFieldMapper } from '../../../../CustomsCaseDetails/types';
import FromCaseFieldMapper from './from-case-field-mapper';
import { Checkbox, Typography, Space } from 'antd';
import DCTooltip from '../../../../../common/Tooltip';

interface IProps {
  execStrategy: ExecutionStrategy;
  onValueChange: (val: ExecutionStrategy) => void;
}

export function useTariffNumberSettingsFields(props: IProps) {
  const { execStrategy, onValueChange } = props;
  const { t } = useTranslation();

  const fields: CaseFieldMapper[] = [
    {
      title: t('Create tariff number master data'),
      help: t(
        'Enable creation of tariff number master data that does not exist yet',
      ),
      isCheckbox: true,
      getValue: () =>
        execStrategy.dataIntegration?.masterDataIntegrationAutomation
          ?.createTariffNumberMasterData,
      updateProperty: (checked) =>
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...(execStrategy.dataIntegration || {}),
            masterDataIntegrationAutomation: {
              ...(execStrategy?.dataIntegration
                ?.masterDataIntegrationAutomation || {}),
              createTariffNumberMasterData: checked,
            },
          },
        }),
      clearProperty: () => {
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...(execStrategy.dataIntegration || {}),
            masterDataIntegrationAutomation: {
              ...(execStrategy?.dataIntegration
                ?.masterDataIntegrationAutomation || {}),
              createTariffNumberMasterData: undefined,
            },
          },
        });
      },
      renderComponent: (value, updateProperty, title, help) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          {title}
          {help && <DCTooltip text={help} />}
        </Checkbox>
      ),
    },
    {
      title: t('Update automatically created tariff numbers'),
      help: t(
        'Enable updates of fields in automatically created tariff numbers',
      ),
      isCheckbox: true,
      getValue: () =>
        execStrategy.dataIntegration?.masterDataIntegrationAutomation
          ?.updateAutomaticallyCreatedTariffNumbers,
      updateProperty: (checked) =>
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...(execStrategy.dataIntegration || {}),
            masterDataIntegrationAutomation: {
              ...(execStrategy?.dataIntegration
                ?.masterDataIntegrationAutomation || {}),
              updateAutomaticallyCreatedTariffNumbers: checked,
            },
          },
        }),
      clearProperty: () => {
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...(execStrategy.dataIntegration || {}),
            masterDataIntegrationAutomation: {
              ...(execStrategy?.dataIntegration
                ?.masterDataIntegrationAutomation || {}),
              updateAutomaticallyCreatedTariffNumbers: undefined,
            },
          },
        });
      },
      renderComponent: (value, updateProperty, title, help) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          {title}
          {help && <DCTooltip text={help} />}
        </Checkbox>
      ),
    },
    {
      title: t('Update manually created tariff numbers'),
      help: t('Enable updates of fields in manually created tariff numbers'),
      isCheckbox: true,
      getValue: () =>
        execStrategy.dataIntegration?.masterDataIntegrationAutomation
          ?.updateManuallyCreatedTariffNumbers,
      updateProperty: (checked) =>
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...(execStrategy.dataIntegration || {}),
            masterDataIntegrationAutomation: {
              ...(execStrategy?.dataIntegration
                ?.masterDataIntegrationAutomation || {}),
              updateManuallyCreatedTariffNumbers: checked,
            },
          },
        }),
      clearProperty: () => {
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...(execStrategy.dataIntegration || {}),
            masterDataIntegrationAutomation: {
              ...(execStrategy?.dataIntegration
                ?.masterDataIntegrationAutomation || {}),
              updateManuallyCreatedTariffNumbers: undefined,
            },
          },
        });
      },
      renderComponent: (value, updateProperty, title, help) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          {title}
          {help && <DCTooltip text={help} />}
        </Checkbox>
      ),
    },
  ];

  return { fields };
}

export function useTariffNumberMatchingOptions(props: IProps) {
  const { execStrategy, onValueChange } = props;
  const { t } = useTranslation();

  const fields: CaseFieldMapper[] = [
    {
      title: t('Use country of origin'),
      help: t('Use country of origin for matching'),
      isCheckbox: true,
      getValue: () =>
        execStrategy.dataIntegration?.masterDataIntegrationAutomation
          ?.tariffNumberMatchingOptions?.useCountryOfOrigin,
      updateProperty: (checked) =>
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...(execStrategy.dataIntegration || {}),
            masterDataIntegrationAutomation: {
              ...(execStrategy?.dataIntegration
                ?.masterDataIntegrationAutomation || {}),
              tariffNumberMatchingOptions: {
                ...(execStrategy?.dataIntegration
                  ?.masterDataIntegrationAutomation
                  ?.tariffNumberMatchingOptions || {}),
                useCountryOfOrigin: checked,
              },
            },
          },
        }),
      clearProperty: () => {
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...(execStrategy.dataIntegration || {}),
            masterDataIntegrationAutomation: {
              ...(execStrategy?.dataIntegration
                ?.masterDataIntegrationAutomation || {}),
              tariffNumberMatchingOptions: {
                ...(execStrategy?.dataIntegration
                  ?.masterDataIntegrationAutomation
                  ?.tariffNumberMatchingOptions || {}),
                useCountryOfOrigin: undefined,
              },
            },
          },
        });
      },
      renderComponent: (value, updateProperty, title, help) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          {title}
          {help && <DCTooltip text={help} />}
        </Checkbox>
      ),
    },
    {
      title: t('Use destination country'),
      help: t('Use destination country for matching'),
      isCheckbox: true,
      getValue: () =>
        execStrategy.dataIntegration?.masterDataIntegrationAutomation
          ?.tariffNumberMatchingOptions?.useDestinationCountry,
      updateProperty: (checked) =>
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...(execStrategy.dataIntegration || {}),
            masterDataIntegrationAutomation: {
              ...(execStrategy?.dataIntegration
                ?.masterDataIntegrationAutomation || {}),
              tariffNumberMatchingOptions: {
                ...(execStrategy?.dataIntegration
                  ?.masterDataIntegrationAutomation
                  ?.tariffNumberMatchingOptions || {}),
                useDestinationCountry: checked,
              },
            },
          },
        }),
      clearProperty: () => {
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...(execStrategy.dataIntegration || {}),
            masterDataIntegrationAutomation: {
              ...(execStrategy?.dataIntegration
                ?.masterDataIntegrationAutomation || {}),
              tariffNumberMatchingOptions: {
                ...(execStrategy?.dataIntegration
                  ?.masterDataIntegrationAutomation
                  ?.tariffNumberMatchingOptions || {}),
                useDestinationCountry: undefined,
              },
            },
          },
        });
      },
      renderComponent: (value, updateProperty, title, help) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          {title}
          {help && <DCTooltip text={help} />}
        </Checkbox>
      ),
    },
    {
      title: t('Use shipper'),
      help: t('Use shipper for matching'),
      isCheckbox: true,
      getValue: () =>
        execStrategy.dataIntegration?.masterDataIntegrationAutomation
          ?.tariffNumberMatchingOptions?.useShipper,
      updateProperty: (checked) =>
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...(execStrategy.dataIntegration || {}),
            masterDataIntegrationAutomation: {
              ...(execStrategy?.dataIntegration
                ?.masterDataIntegrationAutomation || {}),
              tariffNumberMatchingOptions: {
                ...(execStrategy?.dataIntegration
                  ?.masterDataIntegrationAutomation
                  ?.tariffNumberMatchingOptions || {}),
                useShipper: checked,
              },
            },
          },
        }),
      clearProperty: () => {
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...(execStrategy.dataIntegration || {}),
            masterDataIntegrationAutomation: {
              ...(execStrategy?.dataIntegration
                ?.masterDataIntegrationAutomation || {}),
              tariffNumberMatchingOptions: {
                ...(execStrategy?.dataIntegration
                  ?.masterDataIntegrationAutomation
                  ?.tariffNumberMatchingOptions || {}),
                useShipper: undefined,
              },
            },
          },
        });
      },
      renderComponent: (value, updateProperty, title, help) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          {title}
          {help && <DCTooltip text={help} />}
        </Checkbox>
      ),
    },
    {
      title: t('Use recipient/importer'),
      help: t('Use recipient/importer for matching'),
      isCheckbox: true,
      getValue: () =>
        execStrategy.dataIntegration?.masterDataIntegrationAutomation
          ?.tariffNumberMatchingOptions?.useRecipientOrImporter,
      updateProperty: (checked) => {
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...(execStrategy.dataIntegration || {}),
            masterDataIntegrationAutomation: {
              ...(execStrategy?.dataIntegration
                ?.masterDataIntegrationAutomation || {}),
              tariffNumberMatchingOptions: {
                ...(execStrategy?.dataIntegration
                  ?.masterDataIntegrationAutomation
                  ?.tariffNumberMatchingOptions || {}),
                useRecipientOrImporter: checked,
              },
            },
          },
        });
      },
      clearProperty: () => {
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...(execStrategy.dataIntegration || {}),
            masterDataIntegrationAutomation: {
              ...(execStrategy?.dataIntegration
                ?.masterDataIntegrationAutomation || {}),
              tariffNumberMatchingOptions: {
                ...(execStrategy?.dataIntegration
                  ?.masterDataIntegrationAutomation
                  ?.tariffNumberMatchingOptions || {}),
                useRecipientOrImporter: undefined,
              },
            },
          },
        });
      },
      renderComponent: (value, updateProperty, title, help) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          {title}
          {help && <DCTooltip text={help} />}
        </Checkbox>
      ),
    },
  ];

  return { fields };
}

function TariffNumberSettings(props: IProps) {
  const { t } = useTranslation();
  const { fields } = useTariffNumberSettingsFields(props);
  const { fields: matchingOptionsFields } =
    useTariffNumberMatchingOptions(props);

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <FromCaseFieldMapper fields={fields} />
      <Typography.Text
        style={{ fontWeight: 'bold', fontSize: 12, marginLeft: 10 }}
      >
        {t('* Matching Options')}
        <DCTooltip
          text={t(
            'Matching options for tariff number master data, effectively which properties from the shipment will be used to match the tariff number These properties from the shipment (and item) will also be automatically persisted in the master data, each combination of these properties will be a separate master data entry',
          )}
        />
      </Typography.Text>
      <FromCaseFieldMapper fields={matchingOptionsFields} />
    </Space>
  );
}

export default TariffNumberSettings;
